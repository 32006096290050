import { Component } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { UserRight } from '../../WebApiClient';
import { TrainingVideoService } from '../../services/trainingVideo.service';
import { ComponentBase } from '../../models/ComponentBase';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent extends ComponentBase {

    readonly showProjects: boolean;
    readonly showUsers: boolean;
    readonly showCustomers: boolean;
    showTraining = false;

    constructor(
        private readonly loginService: LoginService,
        private readonly router: Router,
        trainingVideoService: TrainingVideoService
    ) {
        super();
        this.showProjects = loginService.hasRights(UserRight.ListCustomerProjects);
        this.showUsers = loginService.hasRights(UserRight.ListUsers);
        this.showCustomers = loginService.hasRights(UserRight.ListCustomers);
        trainingVideoService.visibleVideoGroups.subscribe(vgs => this.showTraining = vgs.length > 0);
    }


    logout() {
        this.loginService.logout();
        this.router.navigate(["/login"]);
    }


}
