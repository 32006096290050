<nav class="navbar-dark bg-dark">


    <ul class="nav flex-column navbar-nav">

        <li *ngIf="showOverview">
        <a class="nav-link" routerLinkActive="active" routerLink="../overview">Overview</a>
        </li>
        <li *ngIf="showEdit">
            <a class="nav-link" routerLinkActive="active" routerLink="../edit">Edit</a>
        </li>
        <li *ngIf="showTimeline">
            <a class="nav-link" routerLinkActive="active" routerLink="../timeline">Timeline</a>
        </li>
        <li *ngIf="showComponents">
            <a class="nav-link" routerLinkActive="active" routerLink="../components">Components</a>
        </li>
        <li *ngIf="showGangs">
            <a class="nav-link" router-link-active="active" routerLink="../gangs">Gangs</a>
        </li>
        <li *ngIf="showReports">
            <a class="nav-link" routerLinkActive="active" routerLink="../reports">Reports</a>
        </li>
        <li *ngIf="showFiles">
            <a class="nav-link" routerLinkActive="active" routerLink="../files">Files</a>
        </li>
        <li *ngIf="showMail">
            <a class="nav-link" routerLinkActive="active" routerLink="../mail">Mail Recipients</a>
        </li>
        <li *ngIf="showMail">
            <a class="nav-link" routerLinkActive="active" routerLink="../maillog">Mail Log</a>
        </li>
        <li *ngIf="showLinks">
            <a class="nav-link" routerLinkActive="active" routerLink="../links">Link to project</a>
        </li>
        <li *ngIf="showFiles">
            <a class="nav-link" routerLinkActive="active" routerLink="../projectDocuments">Project Documents</a>
        </li>
        <li *ngIf="showReportAndStatistics">
            <a class="nav-link" routerLinkActive="active" routerLink="../projectreport">Project Report / Statistics</a>
        </li>
    </ul>

    <ng-content></ng-content>

    <div style="margin-top:10px;"></div>
    <ul class="nav flex-column navbar-nav">
        <li>
            <button class="btn btn-outline-primary btn-block" type="button" (click)="openSupport()">Support</button>
        </li>
    </ul>

</nav>
