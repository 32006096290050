import { Component } from '@angular/core';
import { ProjectFileService } from '../../services/project-file.service';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { ProjectFile_GetProjectDocumentsResponse_File, UserRight } from '../../WebApiClient';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { of } from 'rxjs';
import { LoginService } from '../../services/login.service';
import { ComponentBase } from '../../models/ComponentBase';

@Component({
    selector: 'app-download-project-documents',
    templateUrl: './download-project-documents.html',
    styleUrls: ['./download-project-documents.scss', '../../style/sidebarLayout.scss']
})
export class DonwloadProjectDocumentsComponent extends ComponentBase {

    private readonly currentProjectFileDownloads: string[] = [];
    private projectId: string | undefined;
    projectFiles: ProjectFile_GetProjectDocumentsResponse_File[] | undefined;
    readonly ShowDownloadDocumentsButton: boolean;

    constructor(
        private readonly projectFileService: ProjectFileService,
        private readonly loginService: LoginService,
        route: ActivatedRoute
    ) {
        super();

        this.ShowDownloadDocumentsButton = this.loginService.hasRights(UserRight.DownloadProjectFilesOnOwnProject);

        const projectIdObservable = route.params.pipe(map(p => p["id"] as string | undefined));

        const routeSub = projectIdObservable.subscribe(projectId => {
            this.projectId = projectId;
        })
        this.subscriptions.push(routeSub);

        const fileListSub = projectIdObservable.pipe(
            switchMap(projectId => projectId ? this.projectFileService.getDocumentList(projectId) : of(undefined))
        ).subscribe(response => {
            if (response) {
                this.projectFiles = response.projectDocuments?.filter(pf => pf.isProjectDocumentShared == true);
            }
        });
        this.subscriptions.push(fileListSub);
    }

    isDownloadingProjectDocument(templateId: string) {
        return this.currentProjectFileDownloads.indexOf(templateId) >= 0;
    }

    async downloadDocument(fileId: string) {
        if (!fileId) {
            throw new Error("No fileId");
        }

        if (!this.projectId) {
            throw new Error("No projectId set");
        }

        if (this.isDownloadingProjectDocument(fileId)) {
            return;
        }

        this.currentProjectFileDownloads.push(fileId, this.projectId);

        try {
            await this.projectFileService.downloadDocument(fileId, this.projectId);
        }
        finally {
            let i = this.currentProjectFileDownloads.indexOf(fileId);
            if (i >= 0) {
                this.currentProjectFileDownloads.splice(i, 1);
            }
        }
    }

}

