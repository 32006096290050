import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { timer } from 'rxjs';
import { Location } from '@angular/common';
import { LoginService } from '../../../services/login.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '../../../services/toast.service';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'app-links',
    templateUrl: './links.component.html',
    styleUrls: ['./links.component.scss', '../../../style/sidebarLayout.scss']
})
export class LinksComponent implements OnInit {



    constructor(
        private readonly location: Location,
        private readonly loginService: LoginService,
        private readonly route: ActivatedRoute,
        private readonly toastService: ToastService,
    ) { }

    ngOnInit(): void {
    }

    private _status: "initial" | "generating" | "generated" = "initial";
    private _linkInput: ElementRef<HTMLInputElement> | undefined;

    readonly clipboardIcon = faClipboard;

    @ViewChild("linkInput")
    get linkInput() {
        return this._linkInput;
    }
    set linkInput(val: ElementRef<HTMLInputElement> | undefined) {
        this._linkInput = val;
        if (val) {
            timer(0).toPromise().then(_ => {

                val.nativeElement.focus();
                val.nativeElement.select();
                this.copyToClipboard();
            });
        }
    }

    get status() { return this._status; }

    linkUrl = "";

    async generate() {
        this._status = "generating";
        const projectId = this.route.snapshot.paramMap.get("id");
        if (!projectId)
            return;
        //await timer(999).toPromise();
        const linkId = await this.loginService.createProjectLink(projectId);
        this._status = "generated";

        this.linkUrl = this.getUrl(linkId);

    }

    async copyToClipboard() {
        const li = this.linkInput;
        if (!li)
            return;
        li.nativeElement.focus();
        li.nativeElement.select();
        document.execCommand("copy");
        this.toastService.add("Link copied", "Link copied to clipboard", "success");
    }

    private getUrl(linkId: string): string {
        let result = document.location.origin + this.location.prepareExternalUrl("/link/" + linkId);
        return result;
    }

}
