import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-toast-list',
    templateUrl: './toast-list.component.html',
    styleUrls: ['./toast-list.component.scss']
})
export class ToastListComponent implements OnInit {

    constructor(private readonly toastService: ToastService) { }

    ngOnInit(): void {
    }

    get toasts() {
        return this.toastService.toasts;
    }
    remove(toast: ToastService.Toast) { this.toastService.remove(toast); }
}
