import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TypedModalRef } from '../../models/TypedModalRef';
import { EventTypeService } from '../../services/event-type.service';
import { ComponentBase } from '../../models/ComponentBase';
import { LoginService } from '../../services/login.service';
import { UserRight } from '../../WebApiClient';

@Component({
    selector: 'app-edit-project-event-types-modal',
    templateUrl: './edit-project-event-types-modal.component.html',
    styleUrls: ['./edit-project-event-types-modal.component.scss']
})
export class EditProjectEventTypesModalComponent extends ComponentBase {

    private _eventTypes: {
        readonly id: string,
        readonly name: string,
        selected: boolean
    }[] = [];

    public loading = true;
    public showDelete = false;

    constructor(
        private readonly activeModal: NgbActiveModal,
        private readonly eventTypeService: EventTypeService,
        loginService: LoginService
    ) {
        super();
        this.showDelete = loginService.hasRights(UserRight.DeleteEventType);
    }

    ngOnInit(): void {
    }

    private init(currentIds: string[]) {
        this._eventTypes = currentIds.map(id => ({
            id,
            name: "?",
            selected: true,
        }));
        let sub = this.eventTypeService.eventTypes.subscribe(ets => {
            this.loading = ets.loading;
            let oldEventTypes = this._eventTypes;

            //update existing
            this._eventTypes = ets.eventTypes.map(et => ({
                id: et.id,
                name: et.name,
                selected: oldEventTypes.some(oet => oet.id === et.id && oet.selected)
            }));
            //add new
            this._eventTypes.push(...oldEventTypes.filter(oet => !ets.eventTypes.some(et => et.id === oet.id)));

            //remove deleted
            if (!ets.loading) {
                for (let i = this._eventTypes.length - 1; i >= 0; i--) {
                    let oet = this._eventTypes[i];
                    if (!ets.eventTypes.some(et => et.id === oet.id)) {
                        this.eventTypes.splice(i, 1);
                    }
                }
            }
        });
        this.subscriptions.push(sub);
    }

    public get eventTypes() { return this._eventTypes ?? []; }

    public newEventType() {
        let newName = window.prompt("New event type:");
        if (!newName)
            return;
        this.eventTypeService.addEventType(newName);
    }

    public deleteEventType(eventType: { id: string, name: string }, evt: Event) {
        evt.stopPropagation();
        let sure = window.confirm(`Are you sure you want to delete the event type "${eventType.name}"?`);
        if (!sure)
            return;
        this.eventTypeService.deleteEventType(eventType.id);
    }


    ok() {
        if (!this._eventTypes)
            return;
        let ids = this._eventTypes.filter(et => et.selected).map(et => et.id);
        this.activeModal.close(ids);
    }

    cancel() {
        this.activeModal.dismiss("cancelled");
    }

    static open(currentIds: string[], modalService: NgbModal): TypedModalRef<EditProjectEventTypesModalComponent, string[]> {
        let modal = modalService.open(EditProjectEventTypesModalComponent, { size: 'md', windowClass: 'full-height-modal', scrollable: true });
        let modalComponent = <EditProjectEventTypesModalComponent>modal.componentInstance;
        modalComponent.init(currentIds);
        return new TypedModalRef(modal);
    }
}
