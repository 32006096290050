<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">

    <h3>Timeline</h3>


    <div *ngIf="loadStatus(events) === 'loading'">
        Loading...
    </div>
    <div *ngIf="loadStatus(events) === 'empty'">
        No timeline events yet.
    </div>

    <div *ngIf="loadStatus(events) === 'loaded'">
        <table class="table table-striped">
            <colgroup>
                <col style="width: 70px" />
                <col style="width: 70px" />
                <col style="width: 70px" />
                <col style="width: auto" />
                <col style="width: auto" />
                <col style="width: 100px" />
            </colgroup>
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Start</th>
                    <th scope="col">End</th>
                    <th scope="col">Action</th>
                    <th scope="col">Component</th>
                    <th scope="col">Comments</th>
                </tr>
            </thead>


            <tbody>
                <tr *ngFor="let event of events">

                    <td>
                        <span *ngIf="!event.startTime">--/--</span>
                        <span *ngIf="event.startTime" [title]="event.startTime | date : 'medium'">
                            {{event.startTime | date : 'dd/MM'}}
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!event.startTime">--:--</span>
                        <span *ngIf="event.startTime" [title]="event.startTime | date : 'medium'">
                            {{event.startTime | date : 'HH:mm'}}
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!event.endTime">--:--</span>
                        <span *ngIf="event.endTime" [title]="event.endTime | date : 'medium'">
                            {{event.endTime | date : 'HH:mm'}}
                        </span>
                    </td>
                    <td>{{event.name}}</td>
                    <td>
                        <a *ngIf="event.component"
                           [routerLink]="['../components']"
                           [fragment]="event.componentId">
                            <span>{{event.component.description}}</span>
                            <span *ngIf="event.component.setNumber"> {{event.component.setNumber}}</span>
                            <span *ngIf="event.component.serialNumber"> {{event.component.serialNumber}}</span>
                            <span>
                                (
                                {{event.component.batchLoaded  == undefined ? '?' : event.component.batchLoaded}}
                                /
                                {{event.component.batchSize}}
                                )
                            </span>
                        </a>
                    </td>
                    <td>
                        <a *ngIf="event.numComments"
                           class="badge badge-info"
                           style="cursor:pointer;"
                           (click)="openComments(event)"
                           [fragment]="event.id">
                            {{event.numComments}} comments
                        </a>
                        <span class="badge badge-light"
                              *ngIf="!event.numComments"
                              style="cursor:not-allowed">
                            No comments
                        </span>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>

</div>
