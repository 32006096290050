<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">

    <h4>Generated Reports</h4>

    <div *ngIf="isLoading(templates)">
        Loading...
    </div>

    <div *ngIf="isEmpty(templates)">
        No templates
    </div>

    <div *ngIf="isLoaded(templates)">
        <div class="list-group" style="max-width:850px">
            <div class="list-group-item split-list-item report-item"
                 *ngFor="let template of templates"
                 [ngClass]="{'downloading': isDownloadingTemplate(template.id)}">
                <div class="split-list-group-item-big">
                    {{template.name}}
                </div>
                <div class="list-group-item-action download-button"
                     (click)="downloadTemplate(template.id)">
                    <span class="download-text">Generate</span>
                    <div class="download-spinner">
                        <div class="spinner-border text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
