import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { ProjectService } from '../../../services/project.service';
import { Project_GetProjectDetailsResponse, Project_GetProjectDetailsResponse_Event as ProjectEvent, Project_GetProjectDetailsResponse_Component as ProjectComponent, IProject_GetProjectDetailsResponse_Event } from '../../../WebApiClient';
import { Subscription } from 'rxjs';
import { Enumerable } from '../../../../tools/linq/linq';
import { ComponentBase } from '../../../models/ComponentBase';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommentsModalComponent } from '../../../components/comments-modal/comments-modal.component';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss', '../../../style/sidebarLayout.scss']
})
export class TimelineComponent extends ComponentBase {


    public projectDetails: Project_GetProjectDetailsResponse | undefined;

    public events: EventViewModel[] | undefined;
    public components: { [id: string]: ProjectComponent | undefined } | undefined;
    private projectId = "";

    constructor(
        route: ActivatedRoute,
        projectService: ProjectService,
        private readonly modalService: NgbModal
    ) {
        super();
        let detailsSub = route.params.pipe(
            map(params => params["id"]),
            switchMap(id => {
                this.projectId = id;
                return projectService.getProjectDetails(id);
            })
        )
            .subscribe(pd => this.updateDetails(pd));
        this.subscriptions.push(detailsSub);
    }


    private updateDetails(response: Project_GetProjectDetailsResponse | undefined) {
        this.projectDetails = response;
        if (!response) {
            this.events = undefined;
            return;
        }
        let components: { [id: string]: ProjectComponent | undefined } = {};
        for (let comp of response.components) {
            components[comp.id] = comp;
        }
        this.components = components;

        let es = Enumerable.from(response.events)
            .orderBy(e => e.startTime ?? new Date(0))
            .thenBy(e => e.endTime ?? new Date(0))
            .thenBy(e => e.id)
            .select(e => {
                let result: EventViewModel = { ...e };
                let c = e.componentId ? components[e.componentId] : undefined;
                if (c) {
                    result.component = {
                        description: c.description,
                        setNumber: c.setNumber,
                        serialNumber: c.serialNumber,
                        batchLoaded: e.batchLoaded,
                        batchSize: c.batchSize
                    };
                }
                return result;
            })
            ;
        this.events = es.toArray();

    }

    public openComments(event: EventViewModel) {
        if (!this.projectDetails)
            return;
        CommentsModalComponent.open(this.projectDetails, event, this.modalService);
    }
}

interface EventViewModel extends IProject_GetProjectDetailsResponse_Event {
    component?: {
        description?: string;
        setNumber?: string;
        serialNumber?: string;
        batchLoaded?: number ;
        batchSize?: number;
    };
}
