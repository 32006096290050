<div class="modal-header">
    <h4>Edit event types</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>




<div class="modal-body">
    <ul class="list-group">
        <li class="list-group-item list-group-item-action"
            *ngFor="let eventType of eventTypes"
            (click)="eventType.selected = !eventType.selected">

            <input type="checkbox" [(ngModel)]="eventType.selected" style="cursor:pointer;" (click)="$event.stopPropagation()" />
            {{eventType.name}}
            <button type="button" class="btn btn-sm btn-outline-danger float-right"
                    *ngIf="showDelete"
                    (click)="deleteEventType(eventType, $event)">
                Delete
            </button>

        </li>
        <li class="list-group-item"
            *ngIf="loading">
            Loading...
        </li>
        <li class="list-group-item list-group-item-action"
            *ngIf="!loading"
            (click)="newEventType()">
            New...
        </li>
    </ul>
</div>






<div class="modal-footer">
    <div class="btn-group">
        <button class="btn btn-primary"
                type="button"
                ngbAutofocus
                (click)="ok()">
            Update
        </button>
        <button class="btn btn-secondary"
                type="button"
                (click)="cancel()">
            Cancel
        </button>
    </div>
</div>
