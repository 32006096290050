import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Project_ProjectOverview, Customer_Customer, Project_UpsertProjectRequest, Project_PostalAddress as PostalAddress, IProject_PostalAddress as IPostalAddress, Project_DateTimeTba, IProject_GetProjectDetailsResponse, UserRight, ProjectStatus } from '../../WebApiClient';
import { ComponentStatus } from '../../models/ComponentStatus';
import { ProjectService } from '../../services/project.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditComponentModalComponent } from '../../components/edit-component-modal/edit-component-modal.component';
import { CustomerService } from '../../services/customer.service';
import { ToastService } from '../../services/toast.service';
import { ComponentBase } from '../../models/ComponentBase';
import { EventTypeService } from '../../services/event-type.service';
import { EditProjectEventTypesModalComponent } from '../../components/edit-project-event-types-modal/edit-project-event-types-modal.component';
import { Guid } from '../../models/Guid';
import { EditMailingListComponent } from '../../components/edit-mailing-list/edit-mailing-list.component';
import { map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
    selector: 'app-edit-project',
    templateUrl: './edit-project.component.html',
    styleUrls: ['./edit-project.component.scss', '../../style/sidebarLayout.scss']
})
export class EditProjectComponent extends ComponentBase {

    
    public customers: Customer_Customer[] | undefined;
    public project: Project_UpsertProjectRequest;
    

    //public readonly arrival = new DateTimeAdapter(() => this.project.expectedArrival, d => this.project.expectedArrival = d);
    //public readonly departure = new DateTimeAdapter(() => this.project.expectedDeparture, d => this.project.expectedDeparture = d);

    constructor(
        private readonly router: Router,
        route: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly modalService: NgbModal,
        private readonly toastService: ToastService,
        private readonly eventTypeService: EventTypeService,
        customerService: CustomerService,
    ) {
        super()
        this.customers = customerService.customers;
        let cusSub = customerService.customersObservable.subscribe(cs => {
            this.customers = cs;
        });
        this.subscriptions.push(cusSub);

        this.project = new Project_UpsertProjectRequest();
        let projectSubscription = route.params.pipe(
            map(params => params["id"]),
            switchMap(id => projectService.getProjectDetails(id))
        ).subscribe(proj => this.setProject(proj));

        this.subscriptions.push(projectSubscription);


    }

    @Output()
    updated = new EventEmitter<void>();

    get inputValid(): boolean {
        return this.projectService.isValid(this.project);
    }

    async manageComponents() {

        let components = await this.projectService.getComponents(this.project.projectId);
        let modal = EditComponentModalComponent.open(components, this.project.projectId, this.modalService);

        try {
            let newComponents: ComponentStatus[] = await modal.result;

            await this.saveComponents(newComponents);
        }
        catch (e) {
            //user cancelled
        }
    }

    private async saveComponents(components: ComponentStatus[]) {
        try {
            await this.projectService.upsertComponents(components);
            this.toastService.add("Update success", "Components updated", "success");
        }
        catch (e) {
            this.toastService.reportFailure("Could not update components", e);
        }
    }

    async manageEventTypes() {
        let projectEventTypes = await this.eventTypeService.getProjectEventTypeIds(this.project.projectId);
        let modal = EditProjectEventTypesModalComponent.open(projectEventTypes, this.modalService);

        try {
            let newProjectEventTypes = await modal.result;
            await this.saveProjectEventTypes(newProjectEventTypes);
        }
        catch (e) {
            //user cancelled
        }
    }

    private async saveProjectEventTypes(newProjectEventTypes: string[]): Promise<void> {
        try {
            await this.eventTypeService.setProjectEventTypeIds(this.project.projectId, newProjectEventTypes);
            this.toastService.add("Update success", "Event types updated", "success");
        }
        catch (e) {
            this.toastService.reportFailure("Could not update event types", e);
        }
    }

    async accept() {
        if (!this.projectService.isValid(this.project)) {
            this.toastService.add("Invalid project", "Project is not valid", "warning");
            return;
        }
        try {
            const projectId = this.project.projectId;
            this.project.weight = 0;
            this.project.quantity = 0;
            let componentArray = await this.projectService.getComponents(this.project.projectId);

            for (let i = 0; i < componentArray.length; i++) {
                this.project.weight += componentArray[i].request.batchSize * componentArray[i].request.weightKg;
                this.project.quantity += componentArray[i].request.batchSize;
            }

            await this.projectService.upsertProject(this.project);
            this.updated.emit();
            this.router.navigate(["/projects/" + this.project.projectId.toString() + "/overview"]);
            this.toastService.add("Update success", "The project was updated", "success");  
        } catch (e) {
            this.toastService.reportFailure("Could not update project", e);
        }
    }

    async delete() {
        let ans = window.confirm("Are you sure you want to delete this project?");

        if (!ans)
            return;

        try {
            await this.projectService.deleteProject(this.project.projectId);
            this.toastService.add("Project deleted", `The project ${this.project.name} was deleted`, "success")
        }
        catch (e) {
            this.toastService.reportFailure("Could not delete project", e);
        }
    }

    async manageMailingList() {
        let mlid = this.project.mailingListId;
        if (!mlid) {
            mlid = Guid.newGuid();
        }
        let modal = EditMailingListComponent.open(this.modalService, mlid);

        try {
            await modal.result;
            if (this.project.mailingListId) {
                this.toastService.add("Mailing list updated", "The mailing list was updated", "success");
            }
            this.project.mailingListId = mlid;
        }
        catch (e) {
            //User cancelled
            //mailingList was not created, do not set mailing list id
        }
    }

    private setProject(val?: IProject_GetProjectDetailsResponse): Project_UpsertProjectRequest | undefined {
        if (!val)
            return;
        let result = new Project_UpsertProjectRequest({
            ...val,
            projectId: val.id,
            customerIds: val.projectCustomers.map(pc => pc.customerId!)
                .filter(cid => !!cid)
        });
        const emptyAddress: IPostalAddress = {
            name: "",
            address: "",
            postalCode: "",
            city: "",
            country: ""
        };
        this.project.shipper = new PostalAddress(val.shipper ?? emptyAddress);
        this.project.consignee = new PostalAddress(val.consignee ?? emptyAddress);
        this.project.notifyAddress = new PostalAddress(val.notifyAddress ?? emptyAddress);

        this.project = result;

    }
}
