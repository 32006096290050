<div *ngIf="!project">Loading...</div>
<div *ngIf="project" class="all">

    <div class="page-col">
        <div class="info-group main-info-group s-info-group">
            <h4>General</h4>
            <table>
                <tr class="required">
                    <td class="col-label">Project name</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.name"
                               id="name"
                               title="Project name"
                               placeholder="Project name"
                               name="name" />
                    </td>
                </tr>

                <tr *ngFor="let cust of selectedCustomers; first as isFirst ">
                    <td class="col-label">
                        <div *ngIf="isFirst">Customers</div>
                    </td>
                    <td class="col-value readonly">
                        <div style="display:flex; align-items:center;">

                            {{cust.name}}
                            <div style="flex-grow:1;"></div>
                            <button type="button"
                                    style="align-self:flex-start;"
                                    class="btn btn-sm btn-outline-danger"
                                    (click)="removeCustomer(cust)">
                                <fa-icon [icon]="faDelete"></fa-icon>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr [class]="{'required':!isLoaded(selectedCustomers)}">
                    <td class="col-label">
                        <span *ngIf="!isLoaded(selectedCustomers)">Customers</span>
                    </td>
                    <td class="col-value">
                        <input id="customer"
                               class="form-control"
                               [(ngModel)]="selectedCustomer"
                               [ngbTypeahead]="searchCustomer"
                               [inputFormatter]="customerFormatter"
                               [resultFormatter]="customerFormatter"
                               [editable]="false"
                               container="body"
                               #customerSearch
                               (selectItem)="onCustomerSelect($event, customerSearch)"
                               placeholder="Search..." />
                    </td>
                </tr>

                <tr class="required">
                    <td class="col-label">Case number</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.caseNumber"
                               id="caseNumber"
                               name="caseNumber"
                               placeholder="Case Number"
                               title="Case Number" />
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Status</td>
                    <td class="col-value">
                        <select [(ngModel)]="project.projectStatus" class="custom-select">
                            <option [ngValue]="ProjectStatus.Initial">Not Started</option>
                            <option [ngValue]="ProjectStatus.Started">In Progress</option>
                            <option [ngValue]="ProjectStatus.Completed">Completed</option>
                        </select>
                    </td>
                </tr>

            </table>
        </div>

        <app-edit-postal-address-inner [postalAddress]="project.shipper"
                                       displayName="Shipper"></app-edit-postal-address-inner>

        <app-edit-postal-address-inner [postalAddress]="project.consignee"
                                       displayName="Consignee"></app-edit-postal-address-inner>

        <app-edit-postal-address-inner [postalAddress]="project.notifyAddress"
                                       displayName="Notify Address"></app-edit-postal-address-inner>

        <div class="info-group s-info-group">
            <h4>Internal Note</h4>
            <textarea class="form-control" style="height:160px" placeholder="Write internal information here" [(ngModel)]="project.internalNote">
            </textarea>

        </div>

    </div>


    <div class="page-col">

        <div class="info-group l-info-group">
            <h4>Vessel</h4>
            <table>
                <tr class="required">
                    <td class="col-label">Vessel name</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.shipName"
                               id="shipname"
                               name="shipname"
                               placeholder="Ship name"
                               title="shipName" />
                    </td>
                </tr>
                <tr class="required">
                    <td class="col-label">Voyage number</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.voyageNumber"
                               id="voyageNumber"
                               name="voyageNumber"
                               placeholder="Voyage number"
                               title="Voyage Number" />
                    </td>
                </tr>



                <tr>
                    <td class="col-label">IMO Number</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.imoNumber"
                               id="imoNumber"
                               name="imoNumber"
                               placeholder="IMO Number"
                               title="IMO Number" />
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Flag</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.flag"
                               id="flag"
                               name="flag"
                               placeholder="Flag"
                               title="Flag" />

                    </td>
                </tr>
                <tr>
                    <td class="col-label">Owners</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.owners"
                               id="owners"
                               name="owners"
                               placeholder="Owners"
                               title="Owners" />
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Port</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.port"
                               id="port"
                               name="port"
                               placeholder="Port"
                               title="Port" />
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Reference</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.reference"
                               id="reference"
                               name="reference"
                               placeholder="Reference"
                               title="Reference" />

                    </td>
                </tr>




                <tr class="required">
                    <td class="col-label">Coming from</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.from"
                               id="from"
                               name="from"
                               placeholder="From"
                               title="From" />
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Loading in</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.loadingIn"
                               id="loadingIn"
                               title="Loading in"
                               placeholder="Loading in"
                               name="loadingIn" />
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Discharging in</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.dischargeIn"
                               id="dischargeIn"
                               title="Discharging in"
                               placeholder="Discharging in"
                               name="dischargeIn" />
                    </td>
                </tr>
                <tr class="required">
                    <td class="col-label">Going to</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.to"
                               id="to"
                               name="to"
                               placeholder="To"
                               title="To" />
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Tug(s) booked </td>
                    <td class="col-value">
                        <select [(ngModel)]="project.isTugBooked" class="custom-select">
                            <option [ngValue]="false">No</option>
                            <option [ngValue]="true">Yes</option>
                        </select>
                    </td>
                </tr>
                <tr [hidden]="!project.isTugBooked">

                    <td class="col-label">Tug(s) booked<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <app-tba-date-time [(date)]="project.tugBooked"></app-tba-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Pilot(s) booked </td>
                    <td class="col-value">
                        <select [(ngModel)]="project.isPilotBooked" class="custom-select">
                            <option [ngValue]="false">No</option>
                            <option [ngValue]="true">Yes</option>
                        </select>
                    </td>
                </tr>
                <tr [hidden]="!project.isPilotBooked">
                    <td class="col-label">Pilot booked<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <app-tba-date-time [(date)]="project.pilotBooked"></app-tba-date-time>
                    </td>
                </tr>
                <tr class="required">
                    <td class="col-label">Expected arrival<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <app-tba-date-time [(date)]="project.expectedArrival"
                                           id="expectedArrival"></app-tba-date-time>
                    </td>
                </tr>
                <tr class="required">
                    <td class="col-label">Expected departure<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <app-tba-date-time [(date)]="project.expectedDeparture"
                                           id="expectedDeparture"></app-tba-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Actual departure<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <app-date-time [(date)]="project.actualDeparture"
                                       id="actualDeparture"></app-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Expected arrival next port</td>
                    <td class="col-value">
                        <app-tba-date-time [(date)]="project.expectedArrivalNextPort" id="expectedArrivalNextPort"></app-tba-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Loading/discharge berth</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.berth"
                               id="berth"
                               title="Berth"
                               placeholder="Berth"
                               name="berth" />
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Eosp/pilot station</td>
                    <td class="col-value">
                        <app-date-time [(date)]="project.esop"
                                       id="esop"></app-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">First line</td>
                    <td class="col-value">
                        <app-date-time [(date)]="project.firstLine"
                                       id="firstLine"></app-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">All fast<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <app-date-time [(date)]="project.allFast"
                                       id="allFast"></app-date-time>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">
                        Nor tendered
                    </td>
                    <td class="col-value">
                        <table>
                            <tr class="checkbox-row ">
                                <td>
                                    <label>
                                        <input [(ngModel)]="project.tenderedType"
                                               type="radio"
                                               name="NorTendered"
                                               [value]="tenderedTypes[0].value" />
                                        <span>Unknown</span>
                                    </label>
                                </td>

                            </tr>
                            <tr class="checkbox-row ">
                                <td>
                                    <label>
                                        <input [(ngModel)]="project.tenderedType"
                                               type="radio"
                                               name="NorTendered"
                                               [value]="tenderedTypes[1].value" />
                                        <span>None</span>
                                    </label>
                                </td>

                            </tr>
                            <tr class="checkbox-row ">
                                <td>
                                    <input [(ngModel)]="project.tenderedType"
                                           type="radio"
                                           name="NorTendered"
                                           [value]="tenderedTypes[2].value" />
                                    <app-date-time *ngIf="project.tenderedType === TenderedType.Date"
                                                   [(date)]="project.norTendered" id="norTendered">

                                    </app-date-time>
                                    <app-date-time *ngIf="project.tenderedType !== TenderedType.Date"></app-date-time>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">
                        Nor Received
                    </td>
                    <td class="col-value">
                        <table>
                            <tr class="checkbox-row ">
                                <td>
                                    <label>
                                        <input [(ngModel)]="project.receivedType" type="radio" name="NorReceived" [value]="receivedTypes[0].value" />
                                        <span>Unknown</span>
                                    </label>
                                </td>

                            </tr>
                            <tr class="checkbox-row ">
                                <td>
                                    <label>
                                        <input [(ngModel)]="project.receivedType" type="radio" name="NorReceived" [value]="receivedTypes[1].value" />
                                        <span>None</span>
                                    </label>
                                </td>

                            </tr>
                            <tr class="checkbox-row ">
                                <td>
                                    <input [(ngModel)]="project.receivedType"
                                           type="radio"
                                           name="NorReceived"
                                           [value]="receivedTypes[2].value" />
                                    <app-date-time *ngIf="project.receivedType === ReceivedType.Date"
                                                   [(date)]="project.norReceived" id="norReceived">

                                    </app-date-time>
                                    <app-date-time *ngIf="project.receivedType !== ReceivedType.Date"></app-date-time>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>



                <tr>
                    <td class="col-label">Operation expected to commence<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <app-tba-date-time [(date)]="project.opsCommenced"
                                           id="opsCommenced"></app-tba-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Operation commenced<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <app-date-time [(date)]="project.operationsActuallyCommenced"
                                       id="opsActuallyCommenced"></app-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Operation expected to complete</td>
                    <td class="col-value">
                        <app-tba-date-time [(date)]="project.opsCompleted"
                                           id="opsCompleted"></app-tba-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Operation completed<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <app-date-time [(date)]="project.operationsActuallyCompleted"
                                       id="opsActuallyCompleted"></app-date-time>
                    </td>
                </tr>
                <tr>
                    <td class="col-label">Important Information<fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <textarea style="height:160px;" class="form-control" [(ngModel)]="project.importantInformation"></textarea>
                    </td>
                </tr>
            </table>
        </div>


    </div>

    <div class="page-col">
        <div class="info-group m-info-group">
            <h4>Cargo</h4>
            <table>
                <tr class="required">
                    <td class="col-label">Cargo description</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.cargoDescription"
                               id="cargoDescription"
                               title="Cargo description"
                               placeholder="Cargo description"
                               name="cargoDescription" />
                    </td>
                </tr>


            </table>
        </div>

        <div class="info-group m-info-group">
            <h4>Project roles</h4>
            <table>
                <tr class="required">
                    <td class="col-label">Customer rep</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.customerRep"
                               id="customerRep"
                               title="Customer rep"
                               placeholder="Customer rep"
                               name="customerRep" />
                    </td>
                </tr>

                <tr class="required">
                    <td class="col-label">Stevedore foreman</td>
                    <td class="col-value">
                        <app-project-member-selector [(memberId)]="project.stevedoreForemanId"
                                                     [memberRole]="stevedoreForemanRole"></app-project-member-selector>
                    </td>
                </tr>

                <tr class="required">
                    <td class="col-label">Agent (Opc)</td>
                    <td class="col-value">
                        <app-project-member-selector [(memberId)]="project.opcAgentId"
                                                     [memberRole]="opcAgentRole"></app-project-member-selector>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Surveyor</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.surveyor"
                               id="surveyor"
                               title="Surveyor"
                               placeholder="Surveyor"
                               name="surveyor" />
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Lifting supervisor</td>
                    <td class="col-value">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="project.liftingSupervisor"
                               id="liftingSupervisor"
                               title="Lifting supervisor"
                               placeholder="Lifting supervisor"
                               name="liftingSupervisor" />
                    </td>
                </tr>
            </table>
        </div>


        <div class="info-group m-info-group">
            <h4 class="required">Cranes</h4>
            <table>
                <tr class="checkbox-row " *ngFor="let ct of craneTypes">
                    <td class="col-label">
                        <label for="ct{{ct.value}}">{{ct.name}}</label><fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon>
                    </td>
                    <td class="col-value">
                        <input type="radio"
                               [(ngModel)]="project.craneType"
                               [value]="ct.value"
                               id="ct{{ct.value}}" />
                    </td>
                </tr>

                <tr class="required">
                    <td class="col-label">Crane(s) booked <fa-icon class="mailIcon" [icon]="faEnvelope"></fa-icon></td>
                    <td class="col-value">
                        <select [(ngModel)]="project.shoreCraneBooked" class="custom-select">
                            <option [ngValue]="false">No</option>
                            <option [ngValue]="true">Yes</option>
                        </select>
                    </td>
                </tr>
            </table>
        </div>

        <div class="info-group m-info-group">
            <h4>Kajmand bestilling</h4>
            <table>
                <tr>
                    <td class="col-label">Dato</td>
                    <td class="col-value">
                        <app-tba-date-time [(date)]="project.informantOrderDate" id="informantOrderDate"></app-tba-date-time>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Skib</td>
                    <td class="col-value">
                        <!--<input class="form-control"
           type="text"
           [(ngModel)]="project.informantOrderShip"
           id="informantOrderShip"
           name="informantOrderShip" />-->
                        <textarea class="form-control"
                                  [(ngModel)]="project.informantOrderShip"
                                  id="informantOrderShip"
                                  name="informantOrderShip"
                                  style="height:40px;"></textarea>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Kaj</td>
                    <td class="col-value">
                        <!--<input class="form-control"
           type="text"
           [(ngModel)]="project.informantOrderPort"
           id="informantOrderPort"
           name="informantOrderPort" />-->
                        <textarea class="form-control"
                                  [(ngModel)]="project.informantOrderPort"
                                  id="informantOrderPort"
                                  name="informantOrderPort"
                                  style="height:40px;"></textarea>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Sag</td>
                    <td class="col-value">
                        <!--<input class="form-control"
           type="text"
           [(ngModel)]="project.informantOrderCase"
           id="informantOrderCase"
           name="informantOrderCase" />-->
                        <textarea class="form-control"
                                  [(ngModel)]="project.informantOrderCase"
                                  id="informantOrderCase"
                                  name="informantOrderCase"
                                  style="height:40px;"></textarea>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Job</td>
                    <td class="col-value">
                        <!--<input class="form-control"
           type="text"
           [(ngModel)]="project.informantOrderJob"
           id="informantOrderJob"
           name="informantOrderJob" />-->
                        <textarea class="form-control"
                                  [(ngModel)]="project.informantOrderJob"
                                  id="informantOrderJob"
                                  name="informantOrderJob"
                                  style="height:40px;"></textarea>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Maskine</td>
                    <td class="col-value">
                        <!--<input class="form-control"
           type="text"
           [(ngModel)]="project.informantOrderMachine"
           id="informantOrderMachine"
           name="informantOrderMachine" />-->
                        <textarea class="form-control"
                                  [(ngModel)]="project.informantOrderMachine"
                                  id="informantOrderMachine"
                                  name="informantOrderMachine"
                                  style="height:40px;"></textarea>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Klar v. skib</td>
                    <td class="col-value">
                        <!--<input class="form-control"
           type="text"
           [(ngModel)]="project.informantOrderReady"
           id="informantOrderReady"
           name="informantOrderReady" />-->
                        <textarea class="form-control"
                                  [(ngModel)]="project.informantOrderReady"
                                  id="informantOrderReady"
                                  name="informantOrderReady"
                                  style="height:40px;"></textarea>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Notat</td>
                    <td class="col-value">
                        <!--<input class="form-control"
           type="text"
           [(ngModel)]="project.informantOrderNote"
           id="informantOrderNote"
           name="informantOrderNote" />-->
                        <textarea class="form-control"
                                  [(ngModel)]="project.informantOrderNote"
                                  id="informantOrderNote"
                                  name="informantOrderNote"
                                  style="height:40px;"></textarea>
                    </td>
                </tr>
            </table>
        </div>

        <div class="info-group m-info-group">
            <h4>Terminal opgørelse</h4>
            <table>
                <tr>
                    <td class="col-label">Dato</td>
                    <td class="col-value">
                        <app-tba-date-time [(date)]="project.terminalStatementDate" id="terminalStatementDate"></app-tba-date-time>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Løn</td>
                    <td class="col-value">
                        <!--<input class="form-control"
           type="text"
           [(ngModel)]="project.terminalStatementSalery"
           id="terminalStatementSalery"
           name="terminalStatementSalery" />-->
                        <textarea class="form-control"
                                  [(ngModel)]="project.terminalStatementSalery"
                                  id="terminalStatementSalery"
                                  name="terminalStatementSalery"
                                  style="height:40px;"></textarea>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Forbrug v. flere maskiner</td>
                    <td class="col-value">
                        <!--<input class="form-control"
                               type="text"
                               [(ngModel)]="project.terminalStatementMultipleMachines"
                               id="terminalStatementMultipleMachines"
                               name="terminalStatementMultipleMachines" />-->
                        <textarea class="form-control"
                               [(ngModel)]="project.terminalStatementMultipleMachines"
                               id="terminalStatementMultipleMachines"
                               name="terminalStatementMultipleMachines"
                               style="height:40px;"></textarea>
                    </td>
                </tr>

                <tr>
                    <td class="col-label">Notat</td>
                    <td class="col-value">
                        <!--
    <input class="form-control"
           type="text"
           [(ngModel)]="project.terminalStatementNote"
           id="terminalStatementNote"
           name="terminalStatementNote" />-->
                        <textarea class="form-control"
                                  [(ngModel)]="project.terminalStatementNote"
                                  id="terminalStatementNote"
                                  name="terminalStatementNote"
                                  style="height:40px;"></textarea>
                    
                    </td>
                </tr>
            </table>
        </div>

    </div>

</div>

