<app-topbar></app-topbar>


<app-sidebar>
    <div style="height:45px"></div>

    <button class="btn btn-outline-primary btn-block"
            type="button"
            (click)="manageComponents()">
        Components
    </button>
    <button class="btn btn-outline-primary btn-block"
            type="button"
            (click)="manageEventTypes()">
        Event Types
    </button>
    <button class="btn btn-outline-primary btn-block required"
            type="button"
            (click)="manageMailingList()">
        Mail Recipients
    </button>

    <div style="height:30px"></div>

    <form (ngSubmit)="accept()">
        <button class="btn btn-outline-danger btn-block"
                type="button"
                (click)="delete()">
            Delete
        </button>
        <button type="submit"
                class="btn btn-primary btn-block"
                [disabled]="!inputValid">
            Update
        </button>
    </form>
</app-sidebar>



<div class="main-content">

    <app-edit-project-inner [project]="project"></app-edit-project-inner>
</div>

