<app-topbar></app-topbar>
<app-sidebar></app-sidebar>


<div class="main-content">
    <h3>Project documents ready for download</h3>

    <div class="list-group">
        <table class="table" style="max-width:150ch;">
            <thead>
                <tr>
                    <th scope="col">Document Name</th>
                    <th scope="col">Download</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pf of projectFiles">
                    <td>{{pf.name}}</td>
                    <td><button class="btn btn-primary" (click)="downloadDocument(pf.fileId)">Download</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    
</div>
