<div class="modal-header">
    <h4>Support</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>To get techinal support please reach out to Techsoft.</p>

                <p>We suggest trying to reach out by mail to Casper <a href="mailto:cbj@techsoft.dk?subject=Jutlighthouse support neeed&cc=abd@techsoft.dk;jfl@techsoft.dk">cbj@techsoft.dk</a> with <a href="mailto:abd@techsoft.dk">abd@techsoft.dk</a> and <a href="mailto:jfl@techsoft.dk">jfl@techsoft.dk</a> as cc.</p>

                <p>If urget you can try reach out on the main line at: <a href="tel:+4579330050">+45 7933 0050</a></p>

                <p>You can also try reaching out to Asmus at: <a href="tel:+4524864762">+45 2486 4762</a> or on mail at <a href="mailto:abd@techsoft.dk">abd@techsoft.dk</a> or Jacob at <a href="tel:+4524864754">+45 2486 4754</a> or on mail at <a href="mailto:jfl@techsoft.dk">jfl@techsoft.dk</a></p>
                
                
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="btn-group">
        <button class="btn btn-primary" (click)="cancel()">Close</button>
    </div>
</div>
