<div class="bg">


    <div class="container">
        <img src="../../../assets/logo.svg" alt="Jutlandia Logo" id="mainlogo" />
        <form (ngSubmit)="login()" class="form-signin">
            <input type="text"
                   class="form-control"
                   autofocus
                   [(ngModel)]="username"
                   placeholder="username"
                   autocomplete="username"
                   name="username" />
            <input type="password"
                   class="form-control"
                   [(ngModel)]="password"
                   placeholder="password"
                   autocomplete="current-password"
                   name="password" />
            <div style="margin-top:25px">

                <button type="submit" class="btn btn-block btn-primary" [disabled]="!areCredentialsUsable">Login</button>
            </div>
        </form>
    </div>
</div>

