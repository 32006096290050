<app-topbar></app-topbar>


<nav class="sidebar navbar-dark bg-dark">
    <div class="sidebar-header">
        <h5>Search</h5>
    </div>

    <form (submit)="search()">
        <ul class="nav flex-column navbar-nav">
            <li class="form-group">
                <select [(ngModel)]="relativeDay" class="form-control bg-dark text-white"
                        id="relativeDay"
                        name="relativeDay">
                    <option *ngFor="let d of relativeDaySelections"
                            [ngValue]="d.days">
                        {{d.text}}
                    </option>
                </select>
            </li>
            <li class="form-group">
                <label for="filterSearch" style="display:flex;">
                    <input type="text" placeholder="Search..."
                           [(ngModel)]="searchTerm"
                           id="searchTerm"
                           name="searchTerm"
                           style="width:100%"
                           class="bg-dark text-white form-control" />
                </label>
            </li>
            <li>
                <a class="btn btn-outline-primary btn-block" style="display:flex;"
                   [routerLink]="['../completedProjects']"
                   routerLinkActive="active">
                    Completed Projects
                </a>
            </li>
            <div style="margin-top:10px;"></div>
            <li *ngIf="showReportAndStatistics">
                <a class="btn btn-outline-primary btn-block"
                   (click)="openSupport()">
                    Support
                </a>
            </li>
        </ul>
    </form>

</nav>


<div class="main-content">
    <h3 id="projects-header">Projects</h3>

    <div id="projects-table">
        <div *ngIf="isLoading(projects)" class="alert alert-light">Loading...</div>
        <div *ngIf="isEmpty(projects)" class="alert alert-info">No projects matching search terms.</div>

        <div *ngIf="isLoaded(projects)">
            <table class="table table-striped">
                <colgroup>
                    <col style="width:60px" />
                    <col style="width:auto;" />
                    <col style="width:auto;" />
                    <col style="width:auto;" />
                    <col style="width:auto;" />
                    <col style="width:165px;" />
                    <col style="width:165px;" />
                    <col style="width:100px;" *ngIf="showEditButtons" />
                </colgroup>

                <thead>
                    <tr>
                        <th scope="col" title="Project number">#</th>
                        <th scope="col">Vessel name</th>
                        <th scope="col">Voyage Number</th>
                        <th scope="col">Project Name</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Arrival</th>
                        <th scope="col">Departure</th>
                        <th scope="col" *ngIf="showEditButtons"><!--Edit--></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let project of workingProjects">
                        <td *ngIf="project.projectStatus != ProjectStatus.Completed && project.isProjectTemplate != true">
                            <a [routerLink]="['../projects/', project.id]"
                               routerLinkActive="active">
                                {{project.projectNumber}} 
                            </a>
                        </td>
                        <td *ngIf="project.projectStatus != ProjectStatus.Completed && project.isProjectTemplate != true">
                            <a [routerLink]="['../projects/', project.id]"
                               routerLinkActive="active">
                                {{project.shipName}}
                            </a>
                        </td>
                        <td *ngIf="project.projectStatus != ProjectStatus.Completed && project.isProjectTemplate != true">
                            <a [routerLink]="['../projects/', project.id]"
                               routerLinkActive="active">
                                {{project.voyageNumber}}
                            </a>
                        </td>
                        <td *ngIf="project.projectStatus != ProjectStatus.Completed && project.isProjectTemplate != true">
                            <a [routerLink]="['../projects/', project.id]"
                               routerLinkActive="active">
                                {{project.name}}
                            </a>
                        </td>
                        <td *ngIf="project.projectStatus != ProjectStatus.Completed && project.isProjectTemplate != true">{{project.customerName}}</td>
                        <td *ngIf="project.projectStatus != ProjectStatus.Completed && project.isProjectTemplate != true">{{project.expectedArrival | tbaDate}}</td>
                        <td *ngIf="project.projectStatus != ProjectStatus.Completed && project.isProjectTemplate != true">{{project.expectedDeparture | tbaDate}}</td>
                        <td *ngIf="showEditButtons && project.projectStatus != ProjectStatus.Completed && project.isProjectTemplate != true">
                            <a class="btn btn-secondary btn-sm"
                                [routerLink]="['../projects', project.id, 'edit']">
                                Edit...
                            </a>

                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div>

            <div class="btn-group btn-group-sm">
                <button class="btn btn-light" [disabled]="!isPreviousPagePossible" (click)="previousPage()">&laquo; Prev</button>
                <button class="btn btn-light" [disabled]="!isNextPagePossible" (click)="nextPage()">Next &raquo;</button>
            </div>
            <a class="btn btn-primary btn-sm"
               *ngIf="showNewProjectButton"
               [routerLink]="['./new']"
               routerLinkActive="active">
                Create new project...
            </a>
            

        </div>

    </div>

    <div id="project-editor">
        <app-create-project *ngIf="newProjectActive" (created)="newProjectActive = false"></app-create-project>
        <app-edit-project *ngIf="selectedProject" [project]="selectedProject" (updated)="selectedProject = undefined"></app-edit-project>
    </div>
</div>
