import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from '../../models/ComponentBase';
import { LoginService } from '../../services/login.service';
import { UserRight } from '../../WebApiClient';
import { TechnicalHelpModalComponent } from '../technical-help-modal/technical-help-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends ComponentBase {
    readonly showOverview: boolean;
    readonly showTimeline: boolean;
    readonly showComponents: boolean;
    readonly showReports: boolean;
    readonly showFiles: boolean;
    readonly showGangs: boolean;
    readonly showMail: boolean;
    readonly showLinks: boolean;
    readonly showEdit: boolean;
    readonly showReportAndStatistics: boolean;


    constructor(
        loginService: LoginService,
        private readonly modalService: NgbModal,
    ) {
        super();

        this.showReports = loginService.hasRights(UserRight.ListProjectTemplates);
        this.showFiles = loginService.hasRights(UserRight.DownloadProjectFilesOnOwnProject);
        this.showGangs = loginService.hasRights(UserRight.ListAllWorkers);
        this.showMail = loginService.hasRights(UserRight.GetMailingList);
        this.showLinks = loginService.hasRights(UserRight.CreateLinkForCustomerProject);
        this.showEdit = loginService.hasRights(UserRight.EditProject);
        this.showReportAndStatistics = loginService.hasRights(UserRight.ListAllProjects);
        this.showOverview = loginService.currentUser !== undefined;
        this.showTimeline = loginService.currentUser !== undefined;
        this.showComponents = loginService.currentUser !== undefined;
        

    }

    public async openSupport() {
        let modal = TechnicalHelpModalComponent.open(this.modalService);

        try {
            
        }
        catch (e) {
            //user cancelled
        }
    }


    @Input()
    projectId?: string;



}
