<app-topbar></app-topbar>

<div style="margin-left:auto; margin-right:auto;">

    <div style="max-width: 350px; margin-top:100px; margin-left: auto; margin-right:auto;"
         *ngIf="!isUnsubscribed">
        <p style="text-align:center">Are you sure you want to unsubscribe from status mails for this project?</p>

        <button type="button"
                class="btn btn-block btn-primary"
                (click)="confirm()">
            Unsubscribe
        </button>
    </div>

    <div style="max-width: 350px; margin-top:100px; margin-left: auto; margin-right:auto;"
         *ngIf="isUnsubscribed">

        <p style="text-align:center">You have been unsubscribed from status mails.</p>
    </div>

</div>
