import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly loginService: LoginService
    ) { }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get("id");
        if (!id) {
            this.router.navigate(["/"]);
            return;
        }
        this.loginService.LoginWithLink(id).then(projectId => {
            if (projectId) {
                this.router.navigate(["/projects", projectId]);
            }
        });
    }

}
