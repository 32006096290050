import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../../models/ComponentBase';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services/project.service';
import { map, switchMap } from 'rxjs/operators';
import { Project_GetProjectDetailsResponse, Project_GetProjectDetailsResponse_Gang } from '../../../WebApiClient';

@Component({
    selector: 'app-gangs',
    templateUrl: './gangs.component.html',
    styleUrls: ['../../../style/info-group.scss', './gangs.component.scss', '../../../style/sidebarLayout.scss']
})
export class GangsComponent extends ComponentBase {

    constructor(
        route: ActivatedRoute,
        projectService: ProjectService,
    ) {
        super();
        let detailsSub = route.params.pipe(
            map(params => params["id"]),
            switchMap(id => projectService.getProjectDetails(id))
        )
            .subscribe(pd => this.updateDetails(pd));
        this.subscriptions.push(detailsSub);
    }

    gangs: Project_GetProjectDetailsResponse_Gang[] | undefined;

    updateDetails(pd: Project_GetProjectDetailsResponse | undefined): void {
        this.gangs = pd?.gangs;

    }


}
