import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export class TypedModalRef<TComponent, TResult>{
    constructor(
        private readonly modal: NgbModalRef
    ) {

    }

    close() { return this.modal.close; }
    dismiss() { return this.modal.dismiss; }

    get result(): Promise<TResult> { return this.modal.result; }
    get componentInstance(): TComponent { return this.componentInstance; }
}
