import { Injectable } from "@angular/core";
@Injectable()
export class ToastService {

    private _toasts: ToastService.Toast[] = [];
    constructor(

    ) { }

    public get toasts() { return [...this._toasts] }

    add(header: string,
        text: string,
        type: ToastService.ToastType,
        delay?: number) {
        let toast = new ToastService.Toast(header, text, type, delay)
        this._toasts.push(toast);
        return toast;
    }

    remove(toast: ToastService.Toast) {
        this._toasts = this._toasts.filter(t => t !== toast);
    }

    reportFailure(text: string, e: any) {
        let errorText: any;

        if (typeof e != "string") {
            try {
                errorText = JSON.stringify(e);
            }
            catch (e) { errorText = e }
        }

        return this.add(text, "Error: " + errorText, "danger");
    }
}

export module ToastService {
    export class Toast {
        constructor(
            public readonly header: string,
            public readonly text: string,
            public readonly type: ToastType,
            public readonly delay?: number
        ) { }
    }
    export type ToastType = "success" | "danger" | "warning";
}
