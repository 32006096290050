import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Component_UpsertComponentRequest, ComponentClient, Component_NameSuggestionResponse, UserRight } from '../../WebApiClient';
import { ComponentStatus } from '../../models/ComponentStatus';
import { of, Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { LoginService } from '../../services/login.service';
import { ComponentBase } from '../../models/ComponentBase';
import { SuggestionService } from '../../services/suggestion.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-edit-component',
    templateUrl: './edit-component.component.html',
    styleUrls: ['./edit-component.component.scss', '../../style/crudFloat.scss']
})
export class EditComponentComponent extends ComponentBase {

    public allowNewDescription: boolean;
    public allDescriptionSuggestions: string[] = [];

    constructor(
        private readonly componentClient: ComponentClient,
        loginService: LoginService,
        private readonly suggestionService: SuggestionService,
        private readonly toastService: ToastService
    ) {
        super();
        this.allowNewDescription = loginService.hasRights(UserRight.CreateComponentNames);
        let sub = suggestionService.defaultSuggestionsObservable.subscribe(suggestions => this.allDescriptionSuggestions = suggestions);
        this.subscriptions.push(sub);
    }


    get component(): Component_UpsertComponentRequest {
        return this.componentStatus?.request ?? new Component_UpsertComponentRequest();
    }

    @Input("component")
    componentStatus?: ComponentStatus;

    @Output()
    delete = new EventEmitter<void>();


    //this must be written as a lambda, not a normal method. ngbTypeahead depends on it
    readonly getDescriptionSuggestions = (text: Observable<string>) => {
        let client = this.componentClient;
        return text.pipe(
            debounceTime(300), //ms
            distinctUntilChanged(),
            
            switchMap(term => (term && term.length >= 1)
                ? client.getNameSuggestions(term)
                : of(undefined)),
            catchError(_ => of(undefined)),
            map(response => response?.suggestions?.map(s => s.name ?? "") ?? [])
        );

    }

    async deleteComponentName(name: string) {
        setTimeout(() => this.component.description = "", 0);
        try {
            await this.suggestionService.deleteSuggestion(name);
            this.toastService.add("Component name removed", "Component name removed", "success");

        } catch (e) {
            this.toastService.reportFailure("Could not remove component name", e);
        }
    }


}
