import { Injectable } from "@angular/core";
import { ComponentClient } from "../WebApiClient";
import { Observable, Subscription, BehaviorSubject } from "rxjs";
import { LoginService } from "./login.service";
@Injectable()
export class SuggestionService {

    private readonly subscriptions: Subscription[] = [];
    private readonly defaultSuggestionsSubject = new BehaviorSubject<string[]>([]);

    constructor(
        private readonly componentClient: ComponentClient,
        loginService: LoginService,
    ) {
        this.subscriptions.push(loginService.currentUserObservable.subscribe(async _ => {
            try {
                let s = await componentClient.getDefaultNameSuggestions().toPromise();
                this.defaultSuggestionsSubject.next(s.suggestions.map(s => s.name))
            } catch (e) {
                this.defaultSuggestionsSubject.next([]);
            }
        }));
    }

    get defaultSuggestionsObservable() {
        return this.defaultSuggestionsSubject;
    }

    deleteSuggestion(name: string) {
        return this.componentClient.deleteComponentName(name).toPromise();
    }

}
