import { Component_UpsertComponentRequest } from '../WebApiClient';

export class ComponentStatus {
    copy(): ComponentStatus {
        let result = new ComponentStatus(new Component_UpsertComponentRequest(this.request), this.status);
        result.status = this.status;
        return result;
    }
    constructor(request: Component_UpsertComponentRequest, status: 'unchanged' | 'changedOrNew' | 'deleted') {
        this.request = request;
        this.status = status;
    }
    request: Component_UpsertComponentRequest;
    status: 'unchanged' | 'changedOrNew' | 'deleted';

    get name() { return this.request.description; }

    get isValid() { return this.status !== 'changedOrNew' || !!this.request.description; }
}
