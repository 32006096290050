import { Component, OnInit } from '@angular/core';
import { User_GetUserListResponse, User_User, UserRight } from '../../WebApiClient';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { LoginService } from '../../services/login.service';
import { ComponentBase } from '../../models/ComponentBase';


@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss', '../../style/sidebarLayout.scss']
})
export class UsersComponent extends ComponentBase {

    public response?: User_GetUserListResponse;
    private _selectedUserId?: string;
    public page: number = -1;
    public readonly showEditButtons: boolean;

    constructor(
        route: ActivatedRoute,
        userService: UserService,
        private readonly router: Router,
        private readonly loginService: LoginService,
    ) {
        super();
        this.showEditButtons = loginService.hasRights(UserRight.EditUser);
        let pageObservable = route.paramMap.pipe(map(params => {

            let newPage = Math.max(1, Number(params.get("page") ?? 1));
            this.page = newPage;
            return newPage;
        }));

        let responseObservable = userService.getUsersObservable(pageObservable);

        responseObservable.subscribe(response => this.response = response);

        route.paramMap.subscribe(params => {
            let userId = params.get("user") ?? undefined;
            if (userId === "new") {
                this.selectedUser = undefined;
                this.showNewUser = true;
            }
            else {
                this._selectedUserId = userId;
                this.showNewUser = false;
            }
        })
    }

    public roleName(user: User_User) {
        return user.userRole;
    }

    public get selectedUser(): User_User | undefined {
        if (!this._selectedUserId)
            return;
        return this.response?.users?.filter(u => u.id === this._selectedUserId)[0];
    }
    public set selectedUser(val: User_User | undefined) {
        let id = val?.id;
        this._selectedUserId = id;
        this.showNewUser = false;
        if (id)
            this.router.navigate([this.getUrlParams()])

    }

    public showNewUser = false;

    public get showNewUserButton() {
        return this.loginService.hasRights(UserRight.EditUser);
    }

    public startNewUser() {
        if (!this.loginService.hasRights(UserRight.EditUser))
            return;
        this.selectedUser = undefined;
        this.showNewUser = true;
        this.router.navigate([this.getUrlParams()]);
    }

    public get isNextPagePossible(): boolean {
        return this.response?.isFullPage ?? false;
    }
    public nextPage() {
        if (!this.isNextPagePossible)
            return;
        this.page++;
        this._selectedUserId = undefined;
        this.router.navigate([this.getUrlParams()]);
    }

    public get isPreviousPagePossible() {
        return this.page > 1;
    }
    public previousPage() {
        if (!this.isPreviousPagePossible)
            return;
        this.page--;
        this._selectedUserId = undefined;
        this.router.navigate([this.getUrlParams()])
    }

    private getUrlParams() {
        let result: any = {};
        if (this.page !== 1) {
            result.page = this.page + "";
        }
        if (this._selectedUserId) {
            result.user = this._selectedUserId;
        }
        else if (this.showNewUser) {
            result.user = "new";
        }
        return result;
    }
}
