<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content" *ngIf="!isQuestionnaireAnswered()">
    <h3>
        Operational feedback & Vessel Report
    </h3>
    <p>To access the Vessel report, please answer the below short survey. Your feedback is very useful to us and much appreciated!</p>


    <table class="table table-stripped" style="max-width:150ch;">
        <thead>
            <tr>
                <th scope="col">Rate your experience, 1 being dissatisfied & 10 being very satisfied</th>
                <th scope="col">1</th>
                <th scope="col">2</th>
                <th scope="col">3</th>
                <th scope="col">4</th>
                <th scope="col">5</th>
                <th scope="col">6</th>
                <th scope="col">7</th>
                <th scope="col">8</th>
                <th scope="col">9</th>
                <th scope="col">10</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>How was your experience with Lighthouse?</td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.Dissatisfied)"  /></td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.SomeWhatDissatisfied)"  /></td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.ALillteBitDissatisfied)"  /></td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.MinorInconvenience)"  /></td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.NeitherSatisfiedorDissatisfied)" /></td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.NotAnInconvenience)"  /></td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.ALillteBitSatisfied)"  /></td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.SomeWhatSatisfied)"  /></td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.Satisfied)"  /></td>
                <td><input type="radio" name="Q1"  (change)="setQuestion1Answer(QuestionAnswer.VerySatisfied)"  /></td>
            </tr>
            <tr>
                <td>How did you experience the quality and service delivered by Jutlandia Terminal A/S?</td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.Dissatisfied)"  /></td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.SomeWhatDissatisfied)"  /></td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.ALillteBitDissatisfied)"  /></td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.MinorInconvenience)"  /></td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.NeitherSatisfiedorDissatisfied)"  /></td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.NotAnInconvenience)"  /></td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.ALillteBitSatisfied)"  /></td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.SomeWhatSatisfied)" /></td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.Satisfied)"  /></td>
                <td><input type="radio" name="Q2"  (change)="setQuestion2Answer(QuestionAnswer.VerySatisfied)"  /></td>
            </tr>
            <tr>
                <td>How did you you experience the communication and response time?</td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.Dissatisfied)"  /></td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.SomeWhatDissatisfied)"  /></td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.ALillteBitDissatisfied)"  /></td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.MinorInconvenience)"  /></td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.NeitherSatisfiedorDissatisfied)"  /></td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.NotAnInconvenience)"  /></td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.ALillteBitSatisfied)"  /></td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.SomeWhatSatisfied)"  /></td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.Satisfied)"  /></td>
                <td><input type="radio" name="Q3"  (change)="setQuestion3Answer(QuestionAnswer.VerySatisfied)"  /></td>
            </tr>
            <tr>
                <td>How would you rate your overall experience with Jutlandia Terminal A/S?</td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.Dissatisfied)" /></td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.SomeWhatDissatisfied)"  /></td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.ALillteBitDissatisfied)"  /></td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.MinorInconvenience)"  /></td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.NeitherSatisfiedorDissatisfied)"  /></td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.NotAnInconvenience)"  /></td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.ALillteBitSatisfied)"  /></td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.SomeWhatSatisfied)"  /></td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.Satisfied)"  /></td>
                <td><input type="radio" name="Q4"  (change)="setQuestion4Answer(QuestionAnswer.VerySatisfied)"  /></td>
            </tr>
        </tbody>

    </table>
    <div class="info-group m-info-group">
        <h4>Other comments or further explanation</h4>
        <textarea class="form-control" style="max-width:150ch;height:160px;" placeholder="Write your comments or further question explationtions here"[(ngModel)]="questionnaire.comment">

        </textarea>
    </div>
    <div style="height:45px;">

    </div>
    <button class="btn btn-primary" type="submit" (click)="createAnswerSet()">
        Submit
    </button>

    
    
</div>


<div class="main-content" *ngIf="isQuestionnaireAnswered()">
    <h3>
        Operational feedback & Vessel Report
    </h3>
    <p>
        Download vessel report below.
    </p>

    <div style="height:45px;">

    </div>
    
    <div *ngIf="isDownloading()">
        <fa-icon style="padding-left:20px" class="fa-6x" [icon]="faHourglass" [spin]="true"></fa-icon>
        <p>Preparing the report for download, this might take a couple of seconds</p>
    </div>
    <button class="btn btn-primary" (click)="downloadShipReport()">Download vessel report</button>
</div>
