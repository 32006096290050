import { Injectable } from '@angular/core';
import { UserClient, User_GetUserListResponse, User_UpsertUserRequest, User_DeleteUserRequest } from '../WebApiClient';
import { Observable, BehaviorSubject, merge, of, combineLatest } from 'rxjs';
import { switchMap, distinctUntilChanged, map, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class UserService {

  private trigger = new BehaviorSubject(true);

  public constructor(
    private readonly userClient: UserClient
  ) {

  }

    public getUsersObservable(pageObservable: Observable<number>): Observable<User_GetUserListResponse | undefined> {
    let o = combineLatest(pageObservable.pipe(distinctUntilChanged()), this.trigger).pipe(
      map(x => ({ shouldResolve: x[1], page: x[0] })),
      switchMap(x => x.shouldResolve ? merge(
        of(undefined), //return undefined while the list is refreshing
        this.userClient.getUserList(x.page)
      ) : of(undefined)),
      publishReplay(2),
      refCount(),
    );
    return o;
  }

    public upsertUser(user: User_UpsertUserRequest) {
    this.trigger.next(false);
    let observable = this.userClient.upsertUser(user).pipe(
        publishReplay(2),
        refCount()
      );
    observable.toPromise().then(_ => this.trigger.next(true));
    return observable;
  }

  public deleteUser(userId: string) {
    this.trigger.next(false);
      let o = this.userClient.deleteUser(new User_DeleteUserRequest({ id: userId })).pipe(
        publishReplay(2),
        refCount()
      );
    o.toPromise().then(_ => this.trigger.next(true));
    return o;
  }
}
