import { Injectable } from '@angular/core';
import { ProjectFileClient, ProjectFile_GetProjectFilesResponse, ProjectFile_GetProjectDocumentsResponse } from '../WebApiClient';
import { Observable, BehaviorSubject, merge, of } from 'rxjs';
import { switchMap, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class ProjectFileService {

    private readonly projects: {
        [projectId: string]: {
            trigger: BehaviorSubject<undefined> | undefined,
            projectFiles: Observable<ProjectFile_GetProjectFilesResponse | undefined>,
            projectDocuments: Observable<ProjectFile_GetProjectDocumentsResponse | undefined>,
        } | undefined
    } = {};

    constructor(
        private readonly fileClient: ProjectFileClient
    ) {

    }

    getDocumentList(projectId: string): Observable<ProjectFile_GetProjectDocumentsResponse | undefined> {
        let proj = this.projects[projectId];
        if (!proj) {
            let trigger = new BehaviorSubject<undefined>(undefined);
            let projectDocuments = trigger.pipe(
                switchMap(_ => merge(
                    of(undefined),
                    this.fileClient.getProjectDocuments(projectId)
                )),
                publishReplay(2),
                refCount(),
            );
            let projectFiles = trigger.pipe(
                switchMap(_ => merge(
                    of(undefined),
                    this.fileClient.getProjectFiles(projectId)
                )),
                publishReplay(2),
                refCount(),
            );
            proj = this.projects[projectId] = {
                trigger,
                projectFiles,
                projectDocuments
            };
        }

        return proj.projectDocuments;
    }

    getFileList(projectId: string): Observable<ProjectFile_GetProjectFilesResponse | undefined> {

        let proj = this.projects[projectId];
        if (!proj) {
            let trigger = new BehaviorSubject<undefined>(undefined);
            let projectFiles = trigger.pipe(
                switchMap(_ => merge(
                    of(undefined),
                    this.fileClient.getProjectFiles(projectId)
                )),
                publishReplay(2),
                refCount(),
            );
            let projectDocuments = trigger.pipe(
                switchMap(_ => merge(
                    of(undefined),
                    this.fileClient.getProjectDocuments(projectId)
                )),
                publishReplay(2),
                refCount(),
            );
            proj = this.projects[projectId] = {
                trigger,
                projectFiles,
                projectDocuments
            };
        }

        return proj.projectFiles;
    }

    async uploadFile(file: File, projectId: string): Promise<void> {
        console.debug("Uploading project file: ", file.name);
        await this.fileClient.uploadFile(
            projectId,
            file.name,
            {
                fileName: file.name,
                data: file
            }
        ).toPromise();
        console.debug("Done uploading project file", file.name);
        this.projects[projectId]?.trigger?.next(undefined);
    }

    async uploadDocument(file: File, projectId: string): Promise<void> {
        console.debug("uploading project Document: ", file.name);
        await this.fileClient.uploadDocument(
            projectId,
            file.name,
            {
                fileName: file.name,
                data: file
            }
        ).toPromise();
        console.debug("Done Uploading Project document: ", file.name)
        this.projects[projectId]?.trigger?.next(undefined);
    }

    async downloadFile(fileId: string, projectId: string) {


        let r = await this.fileClient.downloadProjectFile(projectId, fileId).toPromise();

        let url = window.URL.createObjectURL(r.data);
        let a = document.createElement("a");
        a.href = url;
        a.download = r.fileName ?? "Report.docx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    async downloadDocument(fileId: string, projectId: string) {
        let r = await this.fileClient.downloadProjectDocument(projectId, fileId).toPromise();

        let url = window.URL.createObjectURL(r.data);
        let a = document.createElement("a");
        a.href = url;
        a.download = r.fileName ?? "Report.docx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }


    async deleteFile(projectId: string, fileId: string) {
        await this.fileClient.deleteProjectFile(projectId, fileId).toPromise();
        this.projects[projectId]?.trigger?.next(undefined);
    }

    async sendDocuments(projectId:string) {
        await this.fileClient.sendmail(projectId).toPromise();
    }

    async updateDocumentShared(projectId: string, fileId: string) {
        await this.fileClient.updateDocumentShared(projectId, fileId).toPromise();
    }
}
