import { Component } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-lightbox-modal',
    templateUrl: './lightbox-modal.component.html',
    styleUrls: ['./lightbox-modal.component.scss']
})
export class LightboxModalComponent {

    image: LightboxModalComponent.Image = {
        url: "",
        text: ""
    };
    constructor(
        private readonly modal: NgbActiveModal
    ) { }

    private init(image: LightboxModalComponent.Image) {
        this.image = image;
    }

    close() {
        this.modal.close();
    }



    static async open(modalService: NgbModal, image: LightboxModalComponent.Image) {
        const modal = modalService.open(LightboxModalComponent, {
            size: "sm",
            centered: true,
            windowClass: "lightbox"
        });
        const component = <LightboxModalComponent>modal.componentInstance;
        component.init(image);

        try {
            await modal.result;
        }
        catch (e) {/*User cancelled - Ignore error*/ }
    }
}

export module LightboxModalComponent {
    export interface Image {
        url: string;
        text?: string;
    }
}
