<div class="modal-header">
    <h4>Edit components</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action"
                        *ngFor="let component of components"
                        (click)="editComponent(component)"
                        [ngClass]="{deleted: component.status === 'deleted', changed:component.status === 'changedOrNew', active: componentToChange===component, 'list-group-item-danger': componentToChange!==component && !component.isValid}">
                        {{component.name || 'Unnamed component'}}
                    </li>
                    <li class="list-group-item list-group-item-action"
                        (click)="newComponent()">Create New Component...</li>
                </ul>
            </div>
            <div class="col-6">
                <app-edit-component [component]="componentToChange"
                                    *ngIf="componentToChange"
                                    (delete)="editDelete()"></app-edit-component>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="btn-group">
        <button class="btn btn-primary"
                ngbAutofocus
                [ngClass]="{disabled: !isOkEnabled}"
                (click)="ok()">Update</button>
        <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
    </div>
</div>
