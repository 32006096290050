import { Injectable } from "@angular/core";
import { BehaviorSubject, merge, Observable, of } from "rxjs";
import { map, publishReplay, refCount, switchMap } from "rxjs/operators";
import { ProjectQuestionnaireClient, Questionnaire_UpsertProjectQuestionnaireRequest, Questionnaire_UpsertProjectQuestionnaireResponse, Questionnaire_UpsertQuestionAnswerSetRequest, Questionnaire_UpsertQuestionAnswerSetResponse } from "../WebApiClient";

@Injectable()
export class QuestionnaireService {

    private readonly allTrigger = new BehaviorSubject(true);
    private readonly projectTriggers: { [projectId: string]: BehaviorSubject<undefined> | undefined } = {};
    private readonly questionnaireTriggers: { [projectId: string]: BehaviorSubject<undefined> | undefined } = {};
    private readonly projectQuestionnaireById: { [qid: string]: Observable<Questionnaire_UpsertProjectQuestionnaireRequest | undefined> | undefined } = {};

    constructor(
        private readonly projectQuestionnaireClient: ProjectQuestionnaireClient,
    ) {
    }

    getProjectQuestionnaire(qid: string): Observable<Questionnaire_UpsertProjectQuestionnaireRequest | undefined> {
        let questionnaireObservable = this.projectQuestionnaireById[qid];
        if (questionnaireObservable)
            return questionnaireObservable;

        let questionnaireTrigger = this.getQuestionnaireTrigger(qid);

        questionnaireObservable = questionnaireTrigger.pipe(
            switchMap(_ => merge(
                of(undefined),
                this.projectQuestionnaireClient.getQuestionnaire(qid).pipe(map(response => response))
            )),
            publishReplay(2),
            refCount(),
        );

        this.projectQuestionnaireById[qid] = questionnaireObservable;

        return questionnaireObservable;
    }

    async upsertProjectQuestionnaire(request: Questionnaire_UpsertProjectQuestionnaireRequest): Promise<Questionnaire_UpsertProjectQuestionnaireResponse> {
        this.allTrigger.next(false);
        if (!this.isQuestionnaireValid(request))
            throw new Error("Invalid update request");

        var response = await this.projectQuestionnaireClient.upsertProjectQuestionnaire(request).toPromise();
        this.reloadProject(request.projectId);
        return response;

    }

    async upsertQnaSets(request: Questionnaire_UpsertQuestionAnswerSetRequest): Promise<Questionnaire_UpsertQuestionAnswerSetResponse> {
        this.allTrigger.next(false);

        var response = await this.projectQuestionnaireClient.upsertQnaSet(request).toPromise();
        this.reloadProject(request.projectId);
        return response;
    }

    upsertQnASet(QnaSets: Questionnaire_UpsertQuestionAnswerSetRequest): Promise<any> {
        let updatePromises =  this.projectQuestionnaireClient.upsertQnaSet(QnaSets).toPromise();
        return updatePromises;
    }

    reloadProject(...projectIds: string[]) {
        this.allTrigger.next(true);
        for (let projectId of projectIds) {
            let pTrigger = this.projectTriggers[projectId];
            if (pTrigger) {
                pTrigger.next(undefined);
            }
        }
    }

    isQuestionnaireValid(req: Questionnaire_UpsertProjectQuestionnaireRequest): boolean {
        if (!req) {
            return false;
        }
        if (!req.email) {
            return false;
        }
        return true;
    }

    private getQuestionnaireTrigger(qid: string): BehaviorSubject<undefined> {
        let questionnaireTrigger = this.questionnaireTriggers[qid];
        if (!questionnaireTrigger) {
            questionnaireTrigger = new BehaviorSubject(undefined);
            this.questionnaireTriggers[qid] = questionnaireTrigger;
        }
        return questionnaireTrigger;
    }
}
