import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../services/toast.service';
import { MailingListClient } from '../../../WebApiClient';

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss']
})
export class DailyComponent implements OnInit {

    private id?: string;

    isUnsubscribed = false;


    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly mlClient: MailingListClient,
        private readonly toastService: ToastService,
    ) { }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get("id");
        if (!id) {
            this.router.navigate(["/"]);
            return;
        }
        this.id = id;
    }


    async confirm() {
        if (!this.id)
            return;

        try {
            await this.mlClient.unsubscribeDailyStatus(this.id).toPromise();
            this.isUnsubscribed = true;
        } catch (e) {
            this.toastService.reportFailure("Could not unsubscribe from daily status mails", e);
        }
    }
}
