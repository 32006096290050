<div class="modal-header">
    <h4 class="image-text">{{image.text}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <img [src]="image.url" />
</div>
