<div class="card">
    <div class="card-header">
        <h4>Edit Component</h4>
    </div>
    <div class="card-body">
        <div class="form-group">
            <label for="description">Description</label>

            <ng-template #rt let-r="result" let-t="term">
                <div style="width:100%; display:flex">
                    <ngb-highlight [result]="r" [term]="t" style="flex-grow:1"></ngb-highlight>
                    <button type="button"
                            class="btn btn-sm btn-outline-danger pull-right"
                            (click)="deleteComponentName(r)"
                            style="margin-left:15px"
                            >
                        Delete
                    </button>
                </div>
            </ng-template>

            <input class="form-control"
                   *ngIf="allowNewDescription"
                   type="text"
                   [(ngModel)]="component.description"
                   placeholder="description"
                   title="description"
                   name="description"
                   id="description"
                   [ngbTypeahead]="getDescriptionSuggestions"
                   [resultTemplate]="rt"
                   autofocus
                   required />

            <select *ngIf="!allowNewDescription"
                    class="form-control"
                    [(ngModel)]="component.description">
                <option *ngFor="let sug of allDescriptionSuggestions"
                        [value]="sug">
                    {{sug}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label for="serialNumber">Serial number</label>
            <input class="form-control"
                   type="text" [(ngModel)]="component.serialNumber" placeholder="serialNumber" title="serial number" name="serialNumber" id="serialNumber" />
        </div>
        <div class="form-group">
            <label for="setNumber">Set number</label>
            <input class="form-control"
                   type="text" [(ngModel)]="component.setNumber" placeholder="setNumber" title="set number" name="setNumber" id="setNumber" />
        </div>
        <div class="form-group">
            <label for="weightKg">Weight (kg)</label>
            <input class="form-control"
                   type="number" [(ngModel)]="component.weightKg" placeholder="weightKg" title="weight" name="weightKg" id="weightKg" />
        </div>
        <div class="form-group">
            <label for="volumeM3">Volume (m&#179;)</label>
            <input class="form-control"
                   type="number" [(ngModel)]="component.volumeM3" placeholder="volumeM3" title="volume" name="volumeM3" id="volumeM3" />
        </div>
        <div class="form-group">
            <label for="batchSize">Batch size</label>
            <input class="form-control"
                   type="number" [(ngModel)]="component.batchSize" placeholder="batchSize" title="batchSize" name="batchSize" id="batchSize" />
        </div>
        <div class="form-group">
            <label for="comment">Comment</label>
            <input class="form-control"
                   type="text" [(ngModel)]="component.comment" placeholder="comment" title="comment" name="comment" id="comment" />
        </div>

    </div>
    <div class="card-footer">
        <div class="btn-group float-right">
            <button class="btn btn-danger" type="button"
                    (click)="delete.emit()">
                Delete
            </button>
        </div>
    </div>
</div>
