import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Web from './WebApiClient';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProjectsComponent } from './pages/projects/projects.component';
import { AuthInterceptor } from './services/authInterceptor.service';
import { LoginService } from './services/login.service';
import { TopbarComponent } from './components/topbar/topbar.component';
import { UsersComponent } from './pages/users/users.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { CustomerService } from './services/customer.service';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
import { EditCustomerComponent } from './components/edit-customer/edit-customer.component';
import { ProjectService } from './services/project.service';
import { UserService } from './services/user.service';
import { ProjectReportService } from './services/project-report.service';
import { CreateProjectComponent } from './pages/create-project/create-project.component';
import { EditComponentModalComponent } from './components/edit-component-modal/edit-component-modal.component';
import { TechnicalHelpModalComponent } from './components/technical-help-modal/technical-help-modal.component';
import { EditComponentComponent } from './components/edit-component/edit-component.component';
import { EditProjectComponent } from './pages/edit-project/edit-project.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { ToastListComponent } from './components/toast-list/toast-list.component';
import { ToastService } from './services/toast.service';
import { FileService } from './services/file.service';
import { LogoutOn401Interceptor } from './services/LogoutOn401.Interceptor'
import { TimelineComponent } from './pages/project-details/timeline/timeline.component';
import { ComponentsComponent } from './pages/project-details/components/components.component';
import localeDa from '@angular/common/locales/da';
import { registerLocaleData } from '@angular/common';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { LightboxModalComponent } from './components/lightbox-modal/lightbox-modal.component';
import { ReportsComponent } from './pages/project-details/reports/reports.component';
import { EditProjectInnerComponent } from './components/edit-project-inner/edit-project-inner.component';
import { EditUserInnerComponent } from './components/edit-user-inner/edit-user-inner.component';
import { EditCustomerInnerComponent } from './components/edit-customer-inner/edit-customer-inner.component';
import { GangsComponent } from './pages/project-details/gangs/gangs.component';
import { DateTimeComponent } from './components/date-time/date-time.component';
import { EditProjectEventTypesModalComponent } from './components/edit-project-event-types-modal/edit-project-event-types-modal.component';
import { EventTypeService } from './services/event-type.service';
import { ProjectFileService } from './services/project-file.service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FilesComponent } from './pages/project-details/files/files.component';
import { ProjectOverviewComponent } from './pages/project-details/project-overview/project-overview.component';
import { SuggestionService } from './services/suggestion.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LinkComponent } from './pages/link/link.component';
import { LinksComponent } from './pages/project-details/links/links.component';
import { EditMailingListComponent } from './components/edit-mailing-list/edit-mailing-list.component';
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';
import { TrainingComponent } from './pages/training/training.component';
import { TrainingVideoService } from './services/trainingVideo.service';
import { TbaDatePipe } from './services/tba-date.pipe';
import { TbaDateTimeComponent } from './components/tba-date-time/tba-date-time.component';
import { CommentsModalComponent } from './components/comments-modal/comments-modal.component';
import { ProjectMemberService } from './services/project-member.service';
import { ProjectMemberSelectorComponent } from './components/project-member-selector/project-member-selector.component';
import { MailRecipientComponent } from './pages/project-details/mail-recipient/mail-recipient.component';
import { EditPostalAddressInnerComponent } from './components/edit-postal-address-inner/edit-postal-address-inner.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { EditTemplateProjectsModalComponent } from './components/edit-template-projects-modal/edit-template-projects-modal.component';
import { ProjectDocumentsComponent } from './pages/project-details/project-documents/project-document.component';
import { DonwloadProjectDocumentsComponent } from './pages/download-project-documents/download-project-documents';
import { CompletedProjectsComponent } from './pages/completed-projects/completed-projects.component';
import { CompletedProjectQuestionnaireComponent } from './pages/completed-project-questionnaire/completed-project-questionnaire.component';
import { ProjectReportAndStastiticsComponent } from './pages/project-details/project-report/project-report.component';
import { MailLogComponent } from './pages/project-details/mail-log/mail-log.component';
import { ShipReportService } from './services/ship-report.service';
import { QuestionnaireService } from './services/questionnaire-service';
import { DailyComponent } from './pages/unsub/daily/daily.component';
registerLocaleData(localeDa);



@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        PageNotFoundComponent,
        ProjectsComponent,
        TopbarComponent,
        UsersComponent,
        CreateUserComponent,
        EditUserComponent,
        CreateCustomerComponent,
        EditCustomerComponent,
        CreateProjectComponent,
        EditComponentModalComponent,
        EditComponentComponent,
        EditProjectComponent,
        ProjectDetailsComponent,
        ToastListComponent,
        TimelineComponent,
        ComponentsComponent,
        AttachmentComponent,
        LightboxModalComponent,
        ReportsComponent,
        EditProjectInnerComponent,
        EditUserInnerComponent,
        EditCustomerInnerComponent,
        GangsComponent,
        DateTimeComponent,
        EditProjectEventTypesModalComponent,
        FilesComponent,
        ProjectOverviewComponent,
        LinkComponent,
        LinksComponent,
        EditMailingListComponent,
        UnsubscribeComponent,
        TrainingComponent,
        TbaDatePipe,
        TbaDateTimeComponent,
        CommentsModalComponent,
        ProjectMemberSelectorComponent,
        MailRecipientComponent,
        EditPostalAddressInnerComponent,
        SidebarComponent,
        EditTemplateProjectsModalComponent,
        ProjectDocumentsComponent,
        DonwloadProjectDocumentsComponent,
        CompletedProjectsComponent,
        CompletedProjectQuestionnaireComponent,
        ProjectReportAndStastiticsComponent,
        MailLogComponent,
        DailyComponent,
        TechnicalHelpModalComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        HttpClientModule,
        NgxFileDropModule,
        FontAwesomeModule,
    ],
    providers: [
        Web.LoginClient,
        Web.UserClient,
        Web.CustomerClient,
        Web.ProjectClient,
        Web.ComponentClient,
        Web.EventClient,
        Web.ProjectCommentClient,
        Web.ProjectReportClient,
        Web.ProjectEventTypeClient,
        Web.EventTypeClient,
        Web.ProjectFileClient,
        Web.MailingListClient,
        Web.ProjectMemberClient,
        Web.ShipReportClient,
        Web.ProjectQuestionnaireClient,
        Web.MailLogClient,
        LoginService,
        CustomerService,
        ProjectService,
        UserService,
        ToastService,
        FileService,
        ProjectReportService,
        EventTypeService,
        ProjectFileService,
        SuggestionService,
        TrainingVideoService,
        { provide: HTTP_INTERCEPTORS, useClass: LogoutOn401Interceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: "da" },
        ProjectMemberService,
        ShipReportService,
        QuestionnaireService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
