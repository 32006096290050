<div class="modal-header">
    <h4>Load Template Project</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container">

        <table class="table table-striped">
            <colgroup>
                <col style="width:auto" />
                <col style="width:60px" />
                <col style="width:60px" />
            </colgroup>
            <thead>
                <tr>
                    <th scope="col">Template Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let project of projects">
                    <td>
                        {{project.name}}
                    </td>
                    <td>
                        <button class="btn btn-primary btn-sm"
                                (click)="load(project.id)">
                            Load
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-outline-danger btn-sm" (click)="delete(project.id)">
                            Delete
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal-footer">
    <div class="btn-group">
        <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
    </div>
</div>
