import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Project_DateTimeTba } from '../../WebApiClient';

@Component({
    selector: 'app-tba-date-time',
    templateUrl: './tba-date-time.component.html',
    styleUrls: ['./tba-date-time.component.scss']
})
export class TbaDateTimeComponent implements OnInit, OnChanges {

    private lastInvalidVal?: string;
    private _lastDate: NgbDateStruct | undefined;
    private _lastTime: string = "";

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["date"]) {
            this.lastInvalidVal = undefined;
            this._lastDate = undefined;
            this._lastTime = "";
        }
    }


    @Input()
    id?: string;

    @Input()
    date: Project_DateTimeTba | undefined = undefined;

    @Output()
    dateChange = new EventEmitter<Project_DateTimeTba | undefined>();


    get dateId() { return this.id ? `${this.id}-date` : undefined }
    get timeId() { return this.id ? `${this.id}-time` : undefined }

    get dateStruct(): NgbDateStruct | string | undefined {
        let d = this.date?.dateTime;
        if (!d)
            return this.lastInvalidVal;
        let result = {
            day: d.getDate(),
            month: d.getMonth() + 1,
            year: d.getFullYear()
        };
        if (this._lastDate
            && this._lastDate.day === result.day
            && this._lastDate.month === result.month
            && this._lastDate.year === result.year)
            return this._lastDate;
        this._lastDate = result;
        return result;
    }

    set dateStruct(val: NgbDateStruct | string | undefined) {
        if (!val || typeof val == "string") {
            this.lastInvalidVal = val;
            this.date = undefined;
            this.dateChange.emit(undefined);
            return;
        }
        let d = this.date?.dateTime ?? new Date();
        d.setFullYear(val.year);
        d.setMonth(val.month - 1);
        d.setDate(val.day);
        if (!this.date) {
            this.date = new Project_DateTimeTba({
                dateTime: d,
                timeIsTba: false,
            });
        }
        this.date.dateTime = d;
        this.dateChange.emit(this.date);
    }

    get time(): string {
        let d = this.date?.dateTime;
        if (!d)
            return this._lastTime;
        if (this.date?.timeIsTba)
            return "";

        let hour = "" + d.getHours();
        while (hour.length < 2)
            hour = "0" + hour;

        let min = "" + d.getMinutes();
        while (min.length < 2)
            min = "0" + min;

        let t = `${hour}:${min}`;
        if (t === this._lastTime)
            return this._lastTime;
        this._lastTime = t;
        return t;
    }

    set time(val: string) {
        let match = /\s*(\d\d)(?:[\:\.])?(\d\d)\s*/.exec(val); //Does the input match 12:34 ?
        if (!match) {
            if (this.date) {
                if (!val || val.trim() === "") {
                    this.date.timeIsTba = true;
                    this.dateChange.emit(this.date);
                }
                else {
                    //this.date.timeIsTba = false;
                    //this.dateChange.emit(this.date);
                    return;
                }
            }
            return;
        }
        let d = this.date?.dateTime ?? new Date();
        if (!this.date) {
            this.date = new Project_DateTimeTba({
                dateTime: new Date(),
                timeIsTba: false,
            });
        }
        this.date.timeIsTba = false;

        let hours = match[1];
        let mins = match[2];
        d.setHours(Number(hours));
        d.setMinutes(Number(mins));
        d.setSeconds(0);
        this.date.dateTime = d;
        this.dateChange.emit(this.date);
    }

    faCalendar = faCalendar;
}
