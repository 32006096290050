import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { QuestionnaireService } from '../../services/questionnaire-service';
import { ComponentBase } from '../../models/ComponentBase';
import { map, switchMap } from 'rxjs/operators';
import { ProjectStatus, Question, QuetionnaireAnswer,  Questionnaire_UpsertProjectQuestionnaireRequest, Questionnaire_UpsertQuestionAnswerSetRequest} from '../../WebApiClient';
import { ShipReportService } from '../../services/ship-report.service';
import { ToastService } from '../../services/toast.service';
import { Guid } from '../../models/Guid';
import { faHourglass } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'app-completed-project-questionnaire',
    templateUrl: './completed-project-questionnaire.component.html',
    styleUrls: ['./completed-project-questionnaire.component.scss', '../../style/sidebarLayout.scss']
})
export class CompletedProjectQuestionnaireComponent extends ComponentBase {
    private readonly currentDownloads: string[] = [];
    private projectId: string | undefined;
    readonly faHourglass = faHourglass;

    QuestionnaireID: string | undefined;
    readonly QuestionAnswer = QuetionnaireAnswer;
    readonly ProjectStatus = ProjectStatus;
    q1AnswerSet = new Questionnaire_UpsertQuestionAnswerSetRequest();
    q2AnswerSet = new Questionnaire_UpsertQuestionAnswerSetRequest();
    q3AnswerSet = new Questionnaire_UpsertQuestionAnswerSetRequest();
    q4AnswerSet = new Questionnaire_UpsertQuestionAnswerSetRequest();
    readonly Question = Question;
    constructor(
        route: ActivatedRoute,
        private readonly router: Router,
        private readonly projectService: ProjectService,
        private readonly shipReportService: ShipReportService,
        private readonly questionnaireService: QuestionnaireService,
        private readonly toastService: ToastService,
    ) {
        super();

        const questionnaireIdObservable = route.params.pipe(map(p => p["qid"] as string | undefined));

        const routeSub = questionnaireIdObservable.subscribe(Qid => {
            this.QuestionnaireID = Qid;
        })
        this.subscriptions.push(routeSub);

        if (this.QuestionnaireID != undefined) {
            let questionnaireSub = this.questionnaireService.getProjectQuestionnaire(this.QuestionnaireID).subscribe(questionnairesub => this.setQuestionnaire(questionnairesub));
            this.subscriptions.push(questionnaireSub);
        }

    }


    setQuestion1Answer(value: any) {
        this.q1AnswerSet.questionnaireAnswer = value;
    }

    setQuestion2Answer(value: any) {
        this.q2AnswerSet.questionnaireAnswer = value;
    }

    setQuestion3Answer(value: any) {
        this.q3AnswerSet.questionnaireAnswer = value;
    }

    setQuestion4Answer(value: any) {
        this.q4AnswerSet.questionnaireAnswer = value;
    }

    public testSubmit() {
        console.log(this.q1AnswerSet.questionnaireAnswer);
        console.log(this.q2AnswerSet.questionnaireAnswer);
        console.log(this.q3AnswerSet.questionnaireAnswer);
        console.log(this.q4AnswerSet.questionnaireAnswer);
        this.router.navigate(["../projects/" + this.projectId?.toString() + "/projectreport"]);
    }

    public async createAnswerSet() {
        try {
            if(this.projectId){
                this.q1AnswerSet.projectId = this.projectId;
                this.q2AnswerSet.projectId = this.projectId;
                this.q3AnswerSet.projectId = this.projectId;
                this.q4AnswerSet.projectId = this.projectId;
            }
            this.q1AnswerSet.question = Question.HowWasYourExperience;
            this.q2AnswerSet.question = Question.HowDidYouExperienceTheQuality;
            this.q3AnswerSet.question = Question.HowDidYouExperienceTheCommunication;
            this.q4AnswerSet.question = Question.HowWouldYouRateYourOverallExperience;
            this.q1AnswerSet.id = Guid.newGuid()
            this.q2AnswerSet.id = Guid.newGuid();
            this.q3AnswerSet.id = Guid.newGuid();
            this.q4AnswerSet.id = Guid.newGuid();
            if (this.QuestionnaireID) {
                this.q1AnswerSet.projectQuestionnaireId = this.QuestionnaireID;
                this.q2AnswerSet.projectQuestionnaireId = this.QuestionnaireID;
                this.q3AnswerSet.projectQuestionnaireId = this.QuestionnaireID;
                this.q4AnswerSet.projectQuestionnaireId = this.QuestionnaireID;
            }
            //This is a hack, Questionnaire_UpsertProjectQuestionnaireRequest requires answerDate to be set. The actual answerDate is set in the ProjectQuestionnaireController.
            this.questionnaire.answerDate = new Date();
            if(this.projectId)
                this.questionnaire.projectId = this.projectId;
            await this.questionnaireService.upsertProjectQuestionnaire(this.questionnaire);
            await this.questionnaireService.upsertQnaSets(this.q1AnswerSet);
            await this.questionnaireService.upsertQnaSets(this.q2AnswerSet);
            await this.questionnaireService.upsertQnaSets(this.q3AnswerSet);
            await this.questionnaireService.upsertQnaSets(this.q4AnswerSet);

            this.toastService.add("Questionnaire sent", "Questionnare answered", "success");
        } catch (e) {
            this.toastService.reportFailure("Failed to send questionnaire", e);
        }

    }

    isDownloading() {
        if (this.projectId)
            return this.currentDownloads.indexOf(this.projectId) >= 0;
    }
    
    async downloadShipReport() {
        if (!this.projectId) {
            throw new Error("Could not download shipreport - missing projectId")
        }
        this.currentDownloads.push(this.projectId)

        try {
            await this.shipReportService.downloadAnon(this.projectId, this.QuestionnaireID!);
        }
        finally {
            let i = this.currentDownloads.indexOf(this.projectId);
            if (i >= 0) {
                this.currentDownloads.splice(i, 1);
            }
        }
    }

    private setQuestionnaire(val?: Questionnaire_UpsertProjectQuestionnaireRequest): Questionnaire_UpsertProjectQuestionnaireRequest | undefined {
        if (!val)
            return;

        this.projectId = val.projectId;
        let result = new Questionnaire_UpsertProjectQuestionnaireRequest({
            ...val,
            projectId: val.projectId,
            answerDate: val.answerDate,
            comment: val.comment,
            email: val.email,
            id: val.id,
            //qnASet: val.qnASet
        });
        this.questionnaire = result;
    }


    isQuestionnaireAnswered() {
        if (this.questionnaire.answerDate && this.questionnaire.answerDate.valueOf() > 0) {
            return true;
        }
        return false;
    }

    @Input()
    questionnaire: Questionnaire_UpsertProjectQuestionnaireRequest = new Questionnaire_UpsertProjectQuestionnaireRequest();
}
