import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Project_GetProjectDetailsResponse, Project_GetProjectDetailsResponse_Component as ProjectComponent } from '../../../WebApiClient';
import { Enumerable } from '../../../../tools/linq/linq';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styleUrls: ['./components.component.scss', '../../../style/sidebarLayout.scss']
})
export class ComponentsComponent implements OnDestroy {

    private readonly subscriptions: Subscription[] = [];

    public components: ProjectComponent[] | undefined;

    constructor(
        route: ActivatedRoute,
        projectService: ProjectService,
    ) {
        let detailsSub = route.params.pipe(
            map(params => params["id"]),
            switchMap(id => projectService.getProjectDetails(id))
        )
            .subscribe(pd => this.updateDetails(pd));
        this.subscriptions.push(detailsSub);
    }


    ngOnDestroy(): void {
        for (let sub of this.subscriptions)
            sub.unsubscribe();
    }

    get loadStatus(): 'loading' | 'noElements' | 'loaded' {
        if (this.components === undefined)
            return 'loading';
        if (this.components.length === 0)
            return 'noElements';
        return 'loaded';
    }

    private updateDetails(response: Project_GetProjectDetailsResponse | undefined): void {
        if (!response)
            return;

        this.components = Enumerable.from(response.components)
            .orderBy(c => c.description)
            .thenBy(c => c.id)
            .toArray();

    }

}
