<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">
    <h3>Uploaded Files</h3>

    <div class="list-group" style="max-width:850px">
        <div *ngIf="isLoading(projectFiles)" class="list-group-item">
            Loading...
        </div>

        <div *ngIf="isEmpty(projectFiles)" class="list-group-item">
            No uploaded reports
        </div>

        <div class="list-group-item split-list-item report-item"
             *ngFor="let file of projectFiles"
             [ngClass]="{'downloading': isDownloadingProjectFile(file.id)}">
            <div class="split-list-group-item-big">
                {{file.name}}
            </div>
            <div class="list-group-item-action download-button"
                 (click)="downloadProjectFile(file.fileId)">
                <span class="download-text">Download</span>
                <div class="download-spinner">
                    <div class="spinner-border text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="list-group-item-action delete-button "
                 (click)="deleteFile(file)"
                 *ngIf="showDeleteButton">
                <span>Delete</span>
            </div>
        </div>

        <div class="list-group-item" *ngIf="!isLoading(projectFiles) && showUploadBox">

            <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropProjectFile($event)">

            </ngx-file-drop>
        </div>
    </div>
</div>
