import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MailingListClient } from '../../WebApiClient';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-unsubscribe',
    templateUrl: './unsubscribe.component.html',
    styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

    private id?: string;

    isUnsubscribed = false;


    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly mlClient: MailingListClient,
        private readonly toastService: ToastService,
    ) { }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get("id");
        if (!id) {
            this.router.navigate(["/"]);
            return;
        }
        this.id = id;
    }


    async confirm() {
        if (!this.id)
            return;

        try {
            await this.mlClient.unsubscribe(this.id).toPromise();
            this.isUnsubscribed = true;
        } catch (e) {
            this.toastService.reportFailure("Could not unsubscribe", e);
        }
    }

}
