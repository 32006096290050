import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStatus } from '../../models/ComponentStatus';
import { TypedModalRef } from '../../models/TypedModalRef';
import { ProjectService } from '../../services/project.service';

@Component({
    selector: 'app-edit-component-modal',
    templateUrl: './edit-component-modal.component.html',
    styleUrls: ['./edit-component-modal.component.scss']
})
export class EditComponentModalComponent {

    public components: ComponentStatus[] = [];

    public componentToChange: ComponentStatus | undefined = undefined;

    private projectId: string = "";

    constructor(
        private readonly activeModal: NgbActiveModal,
        private readonly projectService: ProjectService
    ) {
    }

    private init(components: ComponentStatus[], projectId: string): void {
        this.components = components.map(c => c.copy());
        this.projectId = projectId;
    }



    ok() {
        if (!this.isOkEnabled)
            return;
        this.activeModal.close(this.components);
    }

    cancel() {
        this.activeModal.dismiss("user cancelled");
    }

    newComponent() {
        let newComponent = this.projectService.newComponent(this.projectId);
        this.componentToChange = new ComponentStatus(newComponent, 'changedOrNew');
        this.components.push(this.componentToChange);
    }

    editComponent(component: ComponentStatus) {
        this.componentToChange = component;
        component.status = "changedOrNew";
    }

    get isOkEnabled() {
        return this.components.every(c => c.isValid);
    }

    editDelete() {
        if (!this.componentToChange) {
            return;
        }
        if (this.componentToChange.status === 'deleted') {
            this.componentToChange.status = 'changedOrNew'
        }
        else {
            this.componentToChange.status = 'deleted';
        }

        this.componentToChange = undefined;
    }
    editCancel() {
        this.componentToChange = undefined;
    }

    static open(components: ComponentStatus[], projectId: string, modalService: NgbModal): TypedModalRef<EditComponentModalComponent, ComponentStatus[]> {
        let modal = modalService.open(EditComponentModalComponent, { size: 'lg', windowClass: 'full-height-modal' });
        let modalComponent = <EditComponentModalComponent>modal.componentInstance;
        modalComponent.init(components, projectId);
        return new TypedModalRef(modal);
    }

}
