<div class="info-group s-info-group"
     *ngIf="addr">
    <h4>{{displayName}}</h4>
    <table>
        <tr>
            <td class="col-label">Name</td>
            <td class="col-value">
                <input class="form-control"
                       type="text"
                       [(ngModel)]="addr.name"
                       title="Name"
                       placeholder="Name"
                       name="addrName" />
            </td>
        </tr>
        <tr>
            <td class="col-label">Address</td>
            <td class="col-value">
                <input class="form-control"
                       type="text"
                       [(ngModel)]="addr.address"
                       title="Address"
                       placeholder="Address"
                       name="address" />
            </td>
        </tr>
        <tr>
            <td class="col-label">Postal code</td>
            <td class="col-value">
                <input class="form-control"
                       type="text"
                       [(ngModel)]="addr.postalCode"
                       title="Postal code"
                       placeholder="Postal code"
                       name="postalCode" />
            </td>
        </tr>
        <tr>
            <td class="col-label">City</td>
            <td class="col-value">
                <input class="form-control"
                       type="text"
                       [(ngModel)]="addr.city"
                       title="City"
                       placeholder="City"
                       name="city" />
            </td>
        </tr>
        <tr>
            <td class="col-label">Country</td>
            <td class="col-value">
                <input class="form-control"
                       type="text"
                       [(ngModel)]="addr.country"
                       title="Country"
                       placeholder="Country"
                       name="country" />
            </td>
        </tr>
    </table>
</div>
