import { Injectable } from "@angular/core";
import { FileResponse, ProjectReportClient, ShipReportClient } from "../WebApiClient";

@Injectable()
export class ShipReportService {

    constructor(
        private readonly shipReportClient: ShipReportClient, 
    ) {
       
    }


    async downloadAnon(projectId: string, questionaireId: string) {


        let pdf = await this.shipReportClient.getShipReportPdfAnon(projectId, questionaireId).toPromise();
        this.download2(pdf);
    }
    async download(projectId: string) {


        let pdf = await this.shipReportClient.getShipReportPdf(projectId).toPromise();
        this.download2(pdf);
    }

    private download2(file: FileResponse) {

        let url = window.URL.createObjectURL(file.data);
        let a = document.createElement("a");

        a.href = url;
        a.download = file.fileName ?? "Vessel-report.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }
}
