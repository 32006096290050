import { Component } from '@angular/core';
import { Project_UpsertProjectRequest, Customer_Customer,  Project_GetProjectDetailsResponse, Project_DateTimeTba, ProjectStatus, Component_UpsertComponentRequest } from '../../WebApiClient';
import { Guid } from '../../models/Guid';
import { CustomerService } from '../../services/customer.service';
import { ProjectService } from '../../services/project.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditComponentModalComponent } from '../../components/edit-component-modal/edit-component-modal.component';
import { ComponentStatus } from '../../models/ComponentStatus';
import { ToastService } from '../../services/toast.service';
import { EditProjectEventTypesModalComponent } from '../../components/edit-project-event-types-modal/edit-project-event-types-modal.component';
import { EventTypeService } from '../../services/event-type.service';
import { EditMailingListComponent } from '../../components/edit-mailing-list/edit-mailing-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentBase } from '../../models/ComponentBase';
import { EditTemplateProjectsModalComponent } from '../../components/edit-template-projects-modal/edit-template-projects-modal.component';

@Component({
    selector: 'app-create-project',
    templateUrl: './create-project.component.html',
    styleUrls: ['./create-project.component.scss', '../../style/sidebarLayout.scss']
})
export class CreateProjectComponent extends ComponentBase {


    public project: Project_UpsertProjectRequest;
    private components: ComponentStatus[] = [];
    private eventTypeIds: string[] = [];

    public customers: Customer_Customer[] | undefined;

    public constructor(
        private readonly customerService: CustomerService,
        private readonly projectService: ProjectService,
        private readonly modalService: NgbModal,
        private readonly toastService: ToastService,
        private readonly eventTypeService: EventTypeService,
        private readonly router: Router,
        route: ActivatedRoute,
    ) {
        super();
        this.project = this.newProject();
        this.customers = this.customerService.customers;
        let cusSub = this.customerService.customersObservable.subscribe(cs => {
            this.customers = cs;
        });
        this.subscriptions.push(cusSub);

        let eventTypesLoaded = false;
        let eventTypeSub = this.eventTypeService.eventTypes
            .subscribe(response => {
                if (eventTypesLoaded)
                    return;
                if (response.loading)
                    return;
                this.eventTypeIds = response.eventTypes.map(et => et.id);
                eventTypesLoaded = true;

            });
        this.subscriptions.push(eventTypeSub);

  
    }

    public ngOnInit(): void {
    }

    private newProject(): Project_UpsertProjectRequest {
        return this.projectService.getNewProject();
    }

    public toDate(dateStr: string): Date {
        return new Date(dateStr);
    }

    public async create() {
        if (!this.inputValid) {
            this.toastService.add("Invalid project", "Project is not valid", "warning");
            return;
        }
        try {
            const projectId = this.project.projectId;
            for (let i = 0; i < this.components.length; i++) {
                if (this.project.weight === undefined)
                    this.project.weight = 0;
                if (this.project.quantity === undefined)
                    this.project.quantity = 0;
                
                
                this.project.weight += this.components[i].request.batchSize * this.components[i].request.weightKg;
                this.project.quantity += this.components[i].request.batchSize;
            }

            if (this.project.projectStatus == ProjectStatus.Completed) {
                this.toastService.add("Invalid project status", "Project status cannot be completed when creating a new project", "warning");
                this.project.projectStatus = ProjectStatus.Initial;
            }
            
            await this.projectService.upsertProject(this.project);
            let componentTask = this.projectService.upsertComponents(this.components);
            let eventTypeTask = this.eventTypeService.setProjectEventTypeIds(this.project.projectId, this.eventTypeIds);

            await Promise.all([componentTask, eventTypeTask]);
            this.toastService.add("Project created", "Project was created", "success");
            this.project = this.newProject();
            this.router.navigate(["projects", projectId]);
        }
        catch (e) {
            this.toastService.reportFailure("Failed to create project", e);
        }
    }

    public async createTemplate() {
        if (!this.templateInputValid) {
            this.toastService.add("Invalid project", "Project is not valid", "warning");
            return;
        }
        try {
            for (let i = 0; i < this.components.length; i++) {
                if (this.project.weight === undefined)
                    this.project.weight = 0;
                if (this.project.quantity === undefined)
                    this.project.quantity = 0;

                

                this.project.weight += this.components[i].request.batchSize * this.components[i].request.weightKg;
                this.project.quantity += this.components[i].request.batchSize;
            }

            if (this.project.name == "") {
                this.project.name = "Template";
            }
            if (this.project.shipName =="")
                this.project.shipName = "Template";
            if(this.project.caseNumber =="")
                this.project.caseNumber = "Template";
            if(this.project.voyageNumber =="")
                this.project.voyageNumber = "Template";
            if(this.project.from =="")
                this.project.from = "Template";

            let dateTimeTba = new Project_DateTimeTba();
            dateTimeTba.dateTime = new Date("1970-01-01");
            dateTimeTba.timeIsTba = true;
            this.project.expectedArrival = dateTimeTba;
            this.project.expectedDeparture = dateTimeTba;
            this.project.isProjectTemplate = true;

            await this.projectService.upsertTemplate(this.project);
            let componentTask = this.projectService.upsertComponents(this.components);
            let eventTypeTask = this.eventTypeService.setProjectEventTypeIds(this.project.projectId, this.eventTypeIds);

            await Promise.all([componentTask, eventTypeTask]);
            this.toastService.add("Template created", "Template was created", "success");
            this.project = this.newProject();
        }
        catch (e) {
            this.toastService.reportFailure("Failed to create template", e);
        }
    }

    public get inputValid(): boolean {
        return this.projectService.isValid(this.project);
    }

    public get templateInputValid(): boolean {
        return this.projectService.isTemplateValid(this.project);
    }

    public get numComponents() { return this.components.filter(c => c.status !== 'deleted').length; }

    public async manageComponents() {
        let modal = EditComponentModalComponent.open(this.components, this.project.projectId, this.modalService);

        try {
            let newComponents: ComponentStatus[] = await modal.result;
            this.components = newComponents;
        }
        catch (e) {
            //user cancelled
        }
    }

    public async manageEventTypes() {
        let modal = EditProjectEventTypesModalComponent.open(this.eventTypeIds, this.modalService);

        try {
            let newEventTypeIds = await modal.result;
            this.eventTypeIds = newEventTypeIds;
        }
        catch (e) {
            //User cancelled
        }
    }

    public async manageMailingList() {
        let mlid = this.project.mailingListId;
        if (!mlid) {
            mlid = Guid.newGuid();
        }
        let modal = EditMailingListComponent.open(this.modalService, mlid);

        try {
            await modal.result;
            this.project.mailingListId = mlid;
        }
        catch (e) {
            //User cancelled
            //mailingList was not created, do not set mailing list id
        }
    }

    public setProjectDetails(projectDetails: Project_GetProjectDetailsResponse): Project_UpsertProjectRequest {
        this.project.isProjectTemplate = false;
        this.project.actualDeparture = projectDetails.actualDeparture;
        this.project.allFast = projectDetails.allFast;
        this.project.berth = projectDetails.berth;
        this.project.cargoDescription = projectDetails.cargoDescription;
        if (projectDetails.caseNumber == "Template") {
            this.project.caseNumber = "";
        } else {
            this.project.caseNumber = projectDetails.caseNumber;
        }
        this.project.projectStatus = projectDetails.projectStatus;
        this.project.consignee = projectDetails.consignee;
        this.project.craneType = projectDetails.craneType;
        this.project.customerRep = projectDetails.customerRep;
        this.project.dischargeIn = projectDetails.dischargeIn;
        this.project.esop = projectDetails.esop;
        let dateTimeTba = new Project_DateTimeTba();
        dateTimeTba.dateTime = new Date("1970-01-01");
        dateTimeTba.timeIsTba = true;
        if (projectDetails.expectedArrival.dateTime.toISOString() == dateTimeTba.dateTime.toISOString()) {
            let tbatime = new Project_DateTimeTba();
            tbatime.dateTime = new Date();
            tbatime.timeIsTba = false;
            this.project.expectedArrival = tbatime;
        } else {
            this.project.expectedArrival = projectDetails.expectedArrival;
        }
        this.project.expectedArrivalNextPort = projectDetails.expectedArrivalNextPort;
        if (projectDetails.expectedDeparture.dateTime.toISOString() == dateTimeTba.dateTime.toISOString()) {
            let tbatime = new Project_DateTimeTba();
            tbatime.dateTime = new Date();
            tbatime.timeIsTba = false;
            this.project.expectedDeparture = tbatime;
        } else {
            this.project.expectedDeparture = projectDetails.expectedDeparture;
        }
        this.project.firstLine = projectDetails.firstLine;
        this.project.flag = projectDetails.flag;
        if (projectDetails.from == "Template") {
            this.project.from = "";
        } else{
            this.project.from = projectDetails.from;
        }
        this.project.imoNumber = projectDetails.imoNumber;
        this.project.importantInformation = projectDetails.importantInformation;
        this.project.isPilotBooked = projectDetails.isPilotBooked;
        this.project.isTugBooked = projectDetails.isTugBooked;
        this.project.liftingSupervisor = projectDetails.liftingSupervisor;
        this.project.loadingIn = projectDetails.loadingIn;
        this.project.mailingListId = projectDetails.mailingListId;
        if (projectDetails.name == "Template") {
            this.project.name = "";
        } else {
            this.project.name = projectDetails.name;
        }
        this.project.norReceived = projectDetails.norReceived;
        this.project.norTendered = projectDetails.norTendered;
        this.project.notifyAddress = projectDetails.notifyAddress;
        this.project.opcAgentId = projectDetails.opcAgentId;
        this.project.owners = projectDetails.owners;
        this.project.pilotBooked = projectDetails.pilotBooked;
        this.project.port = projectDetails.port;
        this.project.quantity = projectDetails.quantity;
        this.project.receivedType = projectDetails.receivedType;
        this.project.reference = projectDetails.reference;
        if (projectDetails.shipName == "Template") {
            this.project.shipName = "";
        } else {
            this.project.shipName = projectDetails.shipName;
        }
        this.project.shipper = projectDetails.shipper;
        this.project.shoreCraneBooked = projectDetails.shoreCraneBooked;
        this.project.stevedoreForemanId = projectDetails.stevedoreForemanId;
        this.project.surveyor = projectDetails.surveyor;
        this.project.tenderedType = projectDetails.tenderedType;
        this.project.to = projectDetails.to;
        this.project.tugBooked = projectDetails.tugBooked;
        if (projectDetails.voyageNumber == "Template") {
            this.project.voyageNumber = "";
        } else {
            this.project.voyageNumber = projectDetails.voyageNumber;
        }
        this.project.weight = projectDetails.weight;
        return this.project;
    }

    public async manageTemplateProjects() {
        let modal = EditTemplateProjectsModalComponent.open(this.project.projectId, this.modalService);
        let templateProject;
        try {
            templateProject = await modal.result;
        }
        catch (e) {
            //user cancelled
            return;
        }
        this.project = this.setProjectDetails(templateProject);
        this.eventTypeIds = await this.eventTypeService.getProjectEventTypeIds(templateProject.id);
        const templateComponents = await this.projectService.getComponents(templateProject.id);
        this.components = templateComponents.map(tc => new ComponentStatus(
            new Component_UpsertComponentRequest({
                ...tc.request,
                componentId: Guid.newGuid(),
                projectId: this.project.projectId
            }),
            "changedOrNew"

        ));
        let templateCustomerIds = templateProject.projectCustomers
            .map(tc => tc.customerId!)
            .filter(customerId => customerId !== undefined)
            ;

        //this.project.customerIds = templateCustomerIds;
        this.project = new Project_UpsertProjectRequest({
            ...this.project,
            customerIds: templateCustomerIds
        })
    }
}
