<app-topbar></app-topbar>

<nav class="sidebar navbar-dark bg-dark">
    <div class="sidebar-header">
        <h5>Users</h5>
    </div>

    <ul class="nav flex-column navbar-nav">
        <!--<li><a class="nav-link" routerLinkActive="active" routerLink="timeline">Timeline</a></li>
        <li><a class="nav-link" routerLinkActive="active" routerLink="components">Components</a></li>
        <li><a class="nav-link" routerLinkActive="active" routerLink="comments">Comments</a></li>-->
    </ul>
</nav>

<div class="main-content">
    <h3 id="users-header">Users</h3>

    <div id="users-table">

        <div *ngIf="isLoading(response?.users)">Loading...</div>
        <div *ngIf="isEmpty(response?.users)">No users yet.</div>

        <div *ngIf="isLoaded(response?.users)">
            <table class="table table-striped">
                <colgroup>
                    <col style="width:auto;" />
                    <col style="width:auto;" />
                    <col style="width:auto;" />
                    <col style="width:auto;" />
                    <col style="width:80px;" *ngIf="showEditButtons" />
                </colgroup>

                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Customer</th>
                        <th scope="col" *ngIf="showEditButtons"><!--Edit--></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let user of response?.users">
                        <td>{{user.name}}</td>
                        <td>{{user.email}}</td>
                        <td>{{roleName(user)}}</td>
                        <td>{{user.customerName}}</td>
                        <td *ngIf="showEditButtons">
                            <button class="btn btn-sm btn-secondary float-right"
                                    [ngClass]="{'active': user === selectedUser}"
                                    (click)="selectedUser = user">
                                Edit
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="display:flex;">

            <div class="btn-group btn-group-sm">
                <button class="btn btn-light" [disabled]="!isPreviousPagePossible" (click)="previousPage()">&laquo; Prev</button>
                <button class="btn btn-light" [disabled]="!isNextPagePossible" (click)="nextPage()">Next &raquo;</button>
            </div>
            <button class="btn btn-primary btn-sm"
                    (click)="startNewUser()"
                    [ngClass]="{active: showNewUser}"
                    *ngIf="showEditButtons">
                Create new User...
            </button>
        </div>
    </div>
    <div id="user-editor">
        <div *ngIf="selectedUser" class="user-details">
            <app-edit-user [user]="selectedUser"></app-edit-user>
        </div>
        <div *ngIf="showNewUser" class="user-details">
            <app-create-user></app-create-user>

        </div>
    </div>
</div>
