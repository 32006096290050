import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Project_UpsertProjectRequest, Customer_Customer, CraneType, ProjectMemberRole, TenderedType, ReceivedType, ProjectStatus } from '../../WebApiClient';
import { ComponentBase } from '../../models/ComponentBase';
import { CustomerService } from '../../services/customer.service';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ProjectService } from '../../services/project.service';
import { faTrashAlt, faEnvelope } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'app-edit-project-inner',
    templateUrl: './edit-project-inner.component.html',
    styleUrls: ['./edit-project-inner.component.scss',  '../../style/project-details.scss', '../../style/project-details-editor.scss']
})
export class EditProjectInnerComponent extends ComponentBase implements OnInit, OnChanges {

    readonly craneTypes: readonly ({ value: CraneType, name: string })[];
    readonly tenderedTypes: readonly ({ value: TenderedType, name: string })[];
    readonly receivedTypes: readonly ({ value: ReceivedType, name: string })[];
    readonly opcAgentRole = ProjectMemberRole.OpcAgent;
    readonly stevedoreForemanRole = ProjectMemberRole.StevedoreForeman;
    readonly CraneType = CraneType;
    readonly faDelete = faTrashAlt;
    readonly faEnvelope = faEnvelope;
    readonly TenderedType = TenderedType;
    readonly ReceivedType = ReceivedType;
    readonly ProjectStatus = ProjectStatus;

    constructor(
        customerService: CustomerService,
        projectService: ProjectService,
    ) {
        super();
        let cusSub = customerService.customersObservable.subscribe(cs => {
            this.customers = cs;
            this.customersById = {};
            if (cs) {
                for (let c of cs) {
                    this.customersById[c.id] = c;
                }
                this.refreshSelectedCustomers();
            }
        });
        this.subscriptions.push(cusSub);
        this.craneTypes = projectService.craneTypes;
        this.tenderedTypes = projectService.tenderedTypes;
        this.receivedTypes = projectService.receivedTypes;
    }

    ngOnInit(): void {
        this.refreshSelectedCustomers();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.refreshSelectedCustomers();
    }

    //refactor: move customer stuff into seperate component
    customers: Customer_Customer[] | undefined;
    selectableCustomers: Customer_Customer[] | undefined;
    selectedCustomers: Customer_Customer[] = [];
    customersById: { [id: string]: Customer_Customer | undefined } = {};

    selectedCustomer: Customer_Customer | undefined = undefined;
    //get selectedCustomer(): Customer | undefined { return this._selectedCustomer; }
    //set selectedCustomer(val: Customer | undefined ) {
    //    this._selectedCustomer = val;
    //    if (val)
    //        this.addCustomer();
    //}

    searchCustomer = (text: Observable<string>) => {
        return text.pipe(
            distinctUntilChanged(),
            map(t => t.toLowerCase().split(/\s+/)),
            map(t => this.customers
                ? this.customers.filter(c => EditProjectInnerComponent.customerFilter(t, c)).slice(0, 10)
                : [])
        )
    }

    addCustomer() {
        let cids = this.project?.customerIds;
        if (!cids)
            return;
        if (!this.selectedCustomer)
            return;
        cids.push(this.selectedCustomer.id)
        this.refreshSelectedCustomers();
    }

    removeCustomer(c: Customer_Customer) {
        let cids = this.project?.customerIds;
        if (!cids)
            return;
        cids = cids.filter(cid => c.id !== cid);
        this.project.customerIds = cids;
        this.refreshSelectedCustomers();
    }

    customerFormatter = (cust?: Customer_Customer) => {
        if (!cust)
            return "";
        return cust.address
            ? `${cust.name} (${cust.address})`
            : cust.name;
    }

    onCustomerSelect($event: Event & { item?: Customer_Customer }, customerSearch: HTMLInputElement) {
        this.selectedCustomer = $event.item;
        $event.preventDefault();
        console.log("event", $event, customerSearch);
        this.addCustomer();
        customerSearch.value = "";
    }

    private refreshSelectedCustomers() {
        this.selectableCustomers = [];
        this.selectedCustomers = [];
        this.selectedCustomer = undefined;
        if (this.project && this.project.customerIds) {
            let cids = this.project.customerIds;
            for (let cid of cids) {
                let c = this.customersById[cid];
                if (c) {
                    this.selectedCustomers.push(c);
                }
            }
            this.selectableCustomers = this.customers?.filter(c => cids.indexOf(c.id) < 0);
        }
    }



    private static customerFilter(lcTexts: string[], customer: Customer_Customer): boolean {
        return lcTexts.every(lcText =>
            customer.name.toLowerCase().indexOf(lcText) >= 0
            || customer.address.toLowerCase().indexOf(lcText) >= 0
        );
    }



    @Input()
    project: Project_UpsertProjectRequest = new Project_UpsertProjectRequest();

}
