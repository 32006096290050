import { Component } from '@angular/core';
import { ComponentBase } from '../../../models/ComponentBase';
import { ProjectFileService } from '../../../services/project-file.service';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { ProjectFile_GetProjectFilesResponse_File, UserRight } from '../../../WebApiClient';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { of } from 'rxjs';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
    styleUrls: ['./files.component.scss', '../../../style/sidebarLayout.scss']
})
export class FilesComponent extends ComponentBase {

    private readonly currentProjectFileDownloads: string[] = [];
    private projectId: string | undefined;
    projectFiles: ProjectFile_GetProjectFilesResponse_File[] | undefined;
    readonly showUploadBox: boolean;
    readonly showDeleteButton: boolean;

    constructor(
        private readonly projectFileService: ProjectFileService,
        private readonly loginService: LoginService,
        route: ActivatedRoute
    ) {
        super();

        this.showUploadBox = this.loginService.hasRights(UserRight.UploadProjectFilesOnAnyProject);
        this.showDeleteButton = this.loginService.hasRights(UserRight.DeleteProjectFilesOnAnyProject);

        const projectIdObservable = route.params.pipe(map(p => p["id"] as string|undefined));

        const routeSub = projectIdObservable.subscribe(projectId => {
            this.projectId = projectId;
        })
        this.subscriptions.push(routeSub);

        const fileListSub = projectIdObservable.pipe(
            switchMap(projectId => projectId ? this.projectFileService.getFileList(projectId): of(undefined))
        ).subscribe(response => {
            if (response) {
                this.projectFiles = response.projectFiles;
            }
        });
        this.subscriptions.push(fileListSub);
    }


    isDownloadingProjectFile(templateId: string) {
        return this.currentProjectFileDownloads.indexOf(templateId) >= 0;
    }

    async downloadProjectFile(fileId: string) {
        if (!fileId)
            throw new Error("No fileId");
        if (!this.projectId)
            throw new Error("No projectId set");

        if (this.isDownloadingProjectFile(fileId)) {
            //already downloading -- ignore
            return;
        }

        this.currentProjectFileDownloads.push(fileId);

        try {
            await this.projectFileService.downloadFile(fileId, this.projectId);
        }
        finally {
            let i = this.currentProjectFileDownloads.indexOf(fileId);
            if (i >= 0) {
                this.currentProjectFileDownloads.splice(i, 1);
            }
        }
    }


    async dropProjectFile(droppedFiles: NgxFileDropEntry[]) {
        console.log("Dropped files:", droppedFiles);
        if (!this.projectId)
            return;
        const projectId = this.projectId;
        for (let f of droppedFiles) {
            if (!f.fileEntry.isFile) {
                continue;
            }
            let f2 = (f.fileEntry as FileSystemFileEntry);
            let p1 = f2.file(file => this.projectFileService.uploadFile(file, projectId));
        }
    }

    async deleteFile(file: ProjectFile_GetProjectFilesResponse_File) {
        if (!file)
            throw new Error("No file");
        if (!this.projectId)
            throw new Error("No projectId set");

        let isUserSure = window.confirm(`Are you sure you want to delete the file "${file.name}"?`);
        if (!isUserSure)
            return;

        await this.projectFileService.deleteFile(this.projectId, file.fileId);

    }


}
