
<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">

    <h3>Components</h3>


    <div *ngIf="loadStatus === 'loading'">
        Loading...
    </div>
    <div *ngIf="loadStatus === 'noElements'">
        No components on project.
    </div>

    <div *ngIf="loadStatus === 'loaded'">
        <table class="table table-striped">
            <colgroup>
                <col style="width: auto" />
                <col style="width: 160px" />
                <col style="width: 160px" />
                <col style="width: 160px" />
                <col style="width: auto" />
                <col style="width: 120px" />
                <col style="width: 120px" />
            </colgroup>
            <thead>
                <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Loaded</th>
                    <th scope="col">Set number</th>
                    <th scope="col">Serial number</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Volume</th>
                    <th scope="col">Weight</th>
                </tr>
            </thead>


            <tbody>
                <tr *ngFor="let component of components" [id]="component.id" class="component-row">

                    <td>{{component.description}}</td>
                    <td>
                        <div class="progress" style="position:relative" title="{{component.batchLoaded }} / {{component.batchSize}}">
                            <div style="position:absolute;width:100%;text-align:center;padding-top:7px;font-weight:bold;">{{component.batchLoaded }} / {{component.batchSize}}</div>
                            <div class="progress-bar"
                                 role="progressbar"
                                 [style.width.%]="component.batchLoaded/component.batchSize*100"></div>
                        </div>
                    </td>
                    <td> {{component.setNumber}} </td>
                    <td> {{component.serialNumber}} </td>
                    <td> {{component.comment}} </td>
                    <td>
                        <span *ngIf="component.volumeM3">
                            {{component.volumeM3 | number}} m&#179;
                        </span>
                    </td>
                    <td> <span *ngIf="component.weightKg ">{{component.weightKg | number}} kg</span> </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
