import { Component } from '@angular/core';
import { ComponentBase } from '../../../models/ComponentBase';
import { ProjectFileService } from '../../../services/project-file.service';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { ProjectFile_GetProjectDocumentsResponse_File, MailingListClient, User_User, UserRight } from '../../../WebApiClient';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { of } from 'rxjs';
import { LoginService } from '../../../services/login.service';
import { ToastService } from '../../../services/toast.service';

@Component({
    selector: 'app-project-documents',
    templateUrl: './project-documents.component.html',
    styleUrls: ['./project-document.component.scss', '../../../style/project-details.scss', '../../../style/sidebarLayout.scss']
})
export class ProjectDocumentsComponent extends ComponentBase {
    private projectId: string | undefined;
    projectFiles: ProjectFile_GetProjectDocumentsResponse_File[] | undefined;
    readonly showDeleteButton: boolean;
    readonly showUploadButton: boolean;
    chekcedFiles: ProjectFile_GetProjectDocumentsResponse_File[] =[];
    selectedItemsList: ProjectFile_GetProjectDocumentsResponse_File[]= [];

    constructor(
        private readonly projectFileService: ProjectFileService,
        private readonly toastService: ToastService,
        route: ActivatedRoute,
        private readonly loginService: LoginService
    ) {
        super();

        this.showUploadButton = this.loginService.hasRights(UserRight.UploadProjectFilesOnAnyProject);
        this.showDeleteButton = this.loginService.hasRights(UserRight.DeleteProjectFilesOnAnyProject);

        const projectIdObservable = route.params.pipe(map(p => p["id"] as string | undefined));

        const routeSub = projectIdObservable.subscribe(projectId => {
            this.projectId = projectId;
        })
        this.subscriptions.push(routeSub);

        const documentListSub = projectIdObservable.pipe(
            switchMap(projectId => projectId ? this.projectFileService.getDocumentList(projectId) : of(undefined))
        ).subscribe(response => {
            if (response) {
                this.projectFiles = response.projectDocuments;
            }
        });
        this.subscriptions.push(documentListSub);
    }

    async dropProjectDocument(droppedDocuments: NgxFileDropEntry[]) {
        console.log("Dropped documents: ", droppedDocuments);
        if (!this.projectId) {
            return;
        }
        const projectId = this.projectId;
        for (let d of droppedDocuments) {
            if (!d.fileEntry.isFile) {
                continue;
            }
            let d2 = (d.fileEntry as FileSystemFileEntry);
            let p1 = d2.file(file => this.projectFileService.uploadDocument(file, projectId))
        }
    }

    changeSelection() {
        this.fetchSelectedItems()
    }

    fetchSelectedItems() {
        if (this.projectFiles != undefined) {
            this.selectedItemsList = this.projectFiles?.filter((value) => {
                console.log(value.fileId);
                return value.isChecked;
            });
        }
        
    }

    fetchCheckedIds() {
        this.chekcedFiles = [];
        this.projectFiles?.forEach((value) => {
            if (value.isChecked) {
                this.chekcedFiles?.push(value);
            }
        });
    }
    
    async Send() {
        var checkedFiles = this.projectFiles?.filter(pf => pf.isChecked == true);
        if(checkedFiles && this.projectId)
            for (let i = 0; i < checkedFiles.length; i++) {
                console.log("Checked files " + checkedFiles[i].fileId);
                try {
                    await this.projectFileService.updateDocumentShared(this.projectId, checkedFiles[i].fileId);
                } catch (ex) {console.log(ex)}
            }
        
        
        if (this.projectId != undefined && this.projectFiles) {
            await this.projectFileService.sendDocuments(this.projectId);
            this.toastService.add("Project Docuemnts sent", "Project documents mail have been sent to all Main Actors on the project", "success");
        }
        else {
            this.toastService.add("Could not send documents", "could not send doucments, make sure you have selected atleast one document", "warning");
        }
    }

}
