import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, merge, of, Subscription } from 'rxjs';
import { switchMap, map, publishReplay, refCount } from 'rxjs/operators';
import { Guid } from '../models/Guid';
import { ProjectMemberClient, ProjectMember_ProjectMember as ProjectMember, IProjectMember_ProjectMember as IProjectMember, ProjectMemberRole } from '../WebApiClient';

@Injectable()
export class ProjectMemberService {

    private readonly trigger = new BehaviorSubject(true);
    private readonly ownSubscription: Subscription;
    private _projectMembers: ProjectMember[] = [];
    public readonly projectMembers: Observable<ProjectMember[] | undefined>;

    constructor(
        private readonly projectMemberClient: ProjectMemberClient
    ) {
        this.projectMembers = this.trigger.pipe(

            switchMap(shouldResolve => {
                let result = shouldResolve
                    ? merge(
                        of(undefined), //while querying for a new list, return undefined
                        projectMemberClient.getProjectMemberList(undefined, 9000))
                    : of(undefined);
                return result;
            }),
            map(response => {
                if (response)
                    this._projectMembers = response.projectMembers;
                return response?.projectMembers;
            }),
            publishReplay(2),
            refCount(),
        );
        this.ownSubscription = this.projectMembers.subscribe(c => { });
    }

    async upsert(member: IProjectMember): Promise<void> {
        if (!this.isValid(member))
            throw new Error("Invalid project member update request");
        this.trigger.next(false);
        try {
            await this.projectMemberClient.upsertProjectmember(new ProjectMember(member)).toPromise()
        }
        finally {
            this.trigger.next(true);
        }
    }

    async delete(memberId: string) {
        this.trigger.next(false);
        try {
            await this.projectMemberClient.deleteProjectMember(memberId)
                .toPromise();
        }
        finally {
            this.trigger.next(true);
        }
    }

    isValid(member: IProjectMember): boolean {
        if (!member)
            return false;
        if (!member.id)
            return false;
        if (member.id === Guid.empty)
            return false;
        if (!member.name)
            return false;
        if (member.role == ProjectMemberRole.NotUsed)
            return false;
        return true;
    }

    update() {
        this.trigger.next(true);
    }

}
