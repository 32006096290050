import { Injectable } from '@angular/core';
import { CustomerClient, Customer_Customer as Customer, Customer_UpsertCustomerRequest, Customer_DeleteCustomerRequest, Customer_UpsertCustomerResponse, Customer_Customer } from '../WebApiClient';
import { Observable, of, merge, BehaviorSubject, Subscription } from 'rxjs';
import { map, switchMap, publishReplay, refCount, share } from 'rxjs/operators';
import { Guid } from '../models/Guid';

@Injectable()
export class CustomerService {

  private readonly trigger = new BehaviorSubject(true);
  private readonly ownSubscription: Subscription;
  private _customers: Customer[] = [];

  public readonly customersObservable: Observable<Customer[] | undefined>;

  public constructor(
    private readonly customerClient: CustomerClient
  ) {

    this.customersObservable = this.trigger.pipe(
      switchMap(shouldResolve => shouldResolve ? merge(
        of(undefined), //while querying for a new customer list, return undefined
        customerClient.getCustomerList(undefined),
      ) : of(undefined)),
      map(response => {
        if (response)
          this._customers = response.customers;
        return response?.customers;
      }),
      publishReplay(2),
      refCount(),
    );
    this.ownSubscription = this.customersObservable.subscribe(c => { });
  }

  public get customers() { return this._customers; }

    public upsert(customer: Customer_UpsertCustomerRequest): Observable<Customer_UpsertCustomerResponse> {
    if (!this.isValid(customer))
      throw new Error("Invalid update request");
    this.trigger.next(false);
    let o = this.customerClient.upsertCustomer(customer)
      .pipe(share());
    o.toPromise().then(_ => this.trigger.next(true));
    return o;
  }

    public isValid(customer: Customer_UpsertCustomerRequest | undefined): boolean {
    if (!customer)
      return false;
    if (!customer.id)
      return false;
    if (customer.id == Guid.empty)
      return false;
    if (!customer.name)
      return false;

    return true;
  }

  public delete(customerId: string) {
      let o = this.customerClient.deleteCustomer(new Customer_DeleteCustomerRequest({ customerId: customerId }));
    let sharedO = o.pipe(share());
    this.trigger.next(false);
    sharedO.toPromise().then(response => this.trigger.next(true));
    return sharedO;
  }

    public async getCustomerList(customerIds: string[]): Promise<Customer_Customer[]> {
        let customerholding = await this.customerClient.getCustomerListByListofIds(customerIds).toPromise();
        return customerholding.customers
        //return result;
    }
}
