import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../models/ComponentBase';
import { Customer_Customer, User_UpsertUserRequest, UserRole, UserRight } from '../../WebApiClient';
import { CustomerService } from '../../services/customer.service';
import { UserRoles } from '../../Constants';
import { Guid } from '../../models/Guid';
import { LoginService } from '../../services/login.service';

@Component({
    selector: 'app-edit-user-inner',
    templateUrl: './edit-user-inner.component.html',
    styleUrls: ['./edit-user-inner.component.scss', "../../style/form-table.scss"]
})
export class EditUserInnerComponent extends ComponentBase {

    public rnd: string;

    constructor(
        customerService: CustomerService,
        loginService: LoginService,
    ) {
        super();
        this.rnd = Guid.newGuid();
        let cusSub = customerService.customersObservable.subscribe(cs => {
            this.customers = cs;
        });
        this.subscriptions.push(cusSub);

        if (loginService.hasRights(UserRight.EditProjectManager)) {
            this.roles = UserRoles;
        }
        else {
            this.roles = UserRoles.filter(ur => ur.key !== UserRole.ProjectManager);
        }
    }

    customers: Customer_Customer[] | undefined;

    readonly roles: { key: UserRole, displayName: string }[];

    @Input()
    user: User_UpsertUserRequest = new User_UpsertUserRequest();

    get userRole(): UserRole { return this.user.userRole; }
    set userRole(val: UserRole) {
        this.user.userRole = val;
        if (val != UserRole.Customer) {
            this.user.customerId = undefined;
        }
    }

    get isCustomerSelectionValid() {
        return this.user?.userRole === UserRole.Customer;
    }
}
