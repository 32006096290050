<div class="input-group">
    <div class="input-group-prepend">
        <button type="button"
                class="btn btn-outline-secondary"
                (click)="datePicker.toggle()">
            <fa-icon [icon]="faCalendar"></fa-icon>
        </button>
    </div>
    <input class="form-control"
           type="text"
           [(ngModel)]="dateStruct"
           #datePicker="ngbDatepicker"
           ngbDatepicker
           container="body"
           style="width:60px;"
           id="{{dateId}}"
           name="{{dateId}}" />
    <input class="form-control"
           type="text"
           [(ngModel)]="time"
           id="{{timeId}}"
           name="{{timeId}}" />
</div>

