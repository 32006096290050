import { Injectable } from '@angular/core';
import { Routes, RouterModule, Route, Router } from '@angular/router';
import {
    ProjectClient, Project_GetProjectListResponse,
    Project_UpsertProjectRequest, Project_UpsertProjectResponse, Component_UpsertComponentRequest,
    ComponentClient, Project_GetProjectDetailsResponse, ProjectComment_GetProjectCommentsResponse_ProjectComment,
    ProjectCommentClient,

    MailingListClient,
    MailingList_UpsertMailingListRequest,
    CraneType,
    Project_PostalAddress as PostalAddress,
    Project_DateTimeTba as DateTimeTba,
    TenderedType,
    ReceivedType,
    MailingList_MailReceiverResponse,
    MailingList_MailReceiver,
} from '../WebApiClient';
import { Observable, combineLatest, BehaviorSubject, merge, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, share, publishReplay, refCount } from 'rxjs/operators';
import { Guid } from '../models/Guid';
import { ComponentStatus } from '../models/ComponentStatus';
import { ProjectSearchOptions } from '../models/ProjectSearchOptions';

export type CraneName = { value: CraneType, name: string };
export type TenderedName = { value: TenderedType, name: string };
export type ReceivedName = { value: ReceivedType, name: string };

@Injectable()
export class ProjectService {
    

    private readonly allTrigger = new BehaviorSubject(true);
    private readonly projectTriggers: { [projectId: string]: BehaviorSubject<undefined> | undefined } = {};
    private readonly projectsDetailsByProjectId: { [projectId: string]: Observable<Project_GetProjectDetailsResponse | undefined> | undefined } = {};
    private readonly commentsByProjectId: { [projectId: string]: Observable<ProjectComment_GetProjectCommentsResponse_ProjectComment[] | undefined> | undefined } = {};

    public readonly craneTypes: Readonly<readonly CraneName[]> = Object.freeze([
        { value: CraneType.None, name: "None" },
        { value: CraneType.VesselGear, name: "Vessel gear" },
        { value: CraneType.PortCrane, name: "Port cranes" },
        { value: CraneType.MobileCrane, name: "Mobile cranes" },
        { value: CraneType.RoRo, name: "Ro/Ro" }
    ]);

    public readonly tenderedTypes: Readonly<readonly TenderedName[]> = Object.freeze([
        { value: TenderedType.Unknown, name: "Unknown" },
        { value: TenderedType.None, name: "None" },
        { value: TenderedType.Date, name: "Date" }
    ]);

    public readonly receivedTypes: Readonly<readonly ReceivedName[]> = Object.freeze([
        { value: ReceivedType.Unknown, name: "Unknown" },
        { value: ReceivedType.None, name: "None" },
        { value: ReceivedType.Date, name: "Date" }
    ]);

    constructor(
        private readonly projectClient: ProjectClient,
        private readonly componentClient: ComponentClient,
        private readonly commentClient: ProjectCommentClient,
        private readonly mailingListClient: MailingListClient,
        private router: Router,
    ) { }


    getNewProject() {
        const result = new Project_UpsertProjectRequest({
            projectId: Guid.newGuid(),
            name: "",
            customerIds: [],
            shipName: "",
            caseNumber: "",
            voyageNumber: "",
            from: "",
            to: "",
            expectedArrival: new DateTimeTba({
                dateTime: new Date(),
                timeIsTba: false,
            }),
            expectedDeparture: new DateTimeTba({
                dateTime: new Date(),
                timeIsTba: false,
            }),
            shipper: new PostalAddress({
                name: "",
                address: "",
                postalCode: "",
                city: "",
                country: ""
            }),
            consignee: new PostalAddress({
                name: "",
                address: "",
                postalCode: "",
                city: "",
                country: ""
            }),
            notifyAddress: new PostalAddress({
                name: "",
                address: "",
                postalCode: "",
                city: "",
                country: ""
            }),
            mailingListId: undefined,
            craneType: CraneType.Unknown,
            shoreCraneBooked: undefined,
            importantInformation: "",
            actualDeparture: undefined,
            isPilotBooked: undefined,
            isTugBooked: undefined,
            tenderedType: TenderedType.Unknown,
            receivedType: ReceivedType.Unknown
        });
        return result;
    }

    /**
     * Gets a list of projects. Will return undefined when the list is updating.
     * 
     * @param request
     */
    getProjectsObservable(request: Observable<ProjectSearchOptions>): Observable<Project_GetProjectListResponse | undefined> {
        let maxResultsPerPage = 25;
        let o = combineLatest(request.pipe(distinctUntilChanged()), this.allTrigger).pipe(
            switchMap(req => req[1] ? merge(
                of(undefined),
                this.projectClient.getProjects(
                    req[0].before,
                    req[0].after,
                    req[0].customerId,
                    req[0].searchTerm,
                    (req[0].page - 1) * maxResultsPerPage,
                    maxResultsPerPage,
                    undefined,
                    req[0].descending)
            ) : of(undefined)),
            publishReplay(2),
            refCount(),
        );

        return o;
    }

    getTemplatesObservable(request: Observable<ProjectSearchOptions>): Observable<Project_GetProjectListResponse | undefined> {
        let maxResultsPerPage = 25;
        let o = combineLatest(request.pipe(distinctUntilChanged()), this.allTrigger).pipe(
            switchMap(req => req[1] ? merge(
                of(undefined),
                this.projectClient.getTemplateProjects(
                    req[0].before,
                    req[0].after,
                    req[0].customerId,
                    req[0].searchTerm,
                    (req[0].page - 1) * maxResultsPerPage,
                    maxResultsPerPage,
                    undefined,
                    req[0].descending)
            ) : of(undefined)),
            publishReplay(2),
            refCount(),
        );

        return o;
    }

    isValid(req: Project_UpsertProjectRequest): boolean {
        if (!req)
            return false;
        if (!req.name)
            return false;
        if (!req.projectId)
            return false;
        if (!req.caseNumber)
            return false;
        if (!req.customerIds)
            return false;
        if (req.customerIds.length === 0)
            return false;
        if (!req.expectedArrival)
            return false;
        if (!req.expectedDeparture)
            return false;
        if (!req.from)
            return false;
        if (!req.to)
            return false;
        if (!req.shipName)
            return false;
        if (!req.voyageNumber)
            return false;
        if (req.craneType === CraneType.Unused || req.craneType === CraneType.Unknown)
            return false;
        if (req.mailingListId == undefined)
            return false;
        if (!req.customerRep)
            return false;
        if (!req.stevedoreForemanId)
            return false;
        if (!req.opcAgentId)
            return false;
        if (!req.cargoDescription)
            return false;
        if (req.shoreCraneBooked== undefined || req.shoreCraneBooked == null)
            return false;
        if (req.tenderedType === TenderedType.Unsued )
            return false;
        if (req.receivedType === ReceivedType.Unsued )
            return false;
        return true;
    }

    isTemplateValid(req: Project_UpsertProjectRequest): boolean {
        if (!req)
            return false;
        if (!req.projectId)
            return false;
        if (req.tenderedType === TenderedType.Unsued)
            return false;
        if (req.receivedType === ReceivedType.Unsued)
            return false;
        return true;
    }



    async upsertProject(request: Project_UpsertProjectRequest): Promise<Project_UpsertProjectResponse> {
        this.allTrigger.next(false);
        if (!this.isValid(request))
            throw new Error("Invalid update request");

        if (!request.mailingListId) {
            let emptyMailingList = new MailingList_UpsertMailingListRequest({
                mailingListId: Guid.newGuid(),
                mailReceivers: []
            });
            await this.mailingListClient.upsertMailingList(emptyMailingList).toPromise();
            request.mailingListId = emptyMailingList.mailingListId;
        }
        
        var response = await this.projectClient.upsertProject(request).toPromise();
        this.reloadProject(request.projectId);
        return response;
        
    }

    async upsertTemplate(request: Project_UpsertProjectRequest): Promise<Project_UpsertProjectResponse> {
        this.allTrigger.next(false);
        if (!this.isTemplateValid(request))
            throw new Error("Invalid update request");

        if (!request.mailingListId) {
            let emptyMailingList = new MailingList_UpsertMailingListRequest({
                mailingListId: Guid.newGuid(),
                mailReceivers: []
            });
            await this.mailingListClient.upsertMailingList(emptyMailingList).toPromise();
            request.mailingListId = emptyMailingList.mailingListId;
        }

        var response = await this.projectClient.upsertTemplate(request).toPromise();
        this.reloadProject(request.projectId);
        return response;

    }

    getProjectDetails(projectId: string): Observable<Project_GetProjectDetailsResponse | undefined> {
        let projectDetailsObservable = this.projectsDetailsByProjectId[projectId];
        if (projectDetailsObservable)
            return projectDetailsObservable;

        let projectTrigger = this.getProjectTrigger(projectId);

        projectDetailsObservable = projectTrigger.pipe(
            switchMap(_ => merge(
                of(undefined),
                this.projectClient.getProjectDetails(projectId)
            )),
            publishReplay(2),
            refCount(),
        );

        this.projectsDetailsByProjectId[projectId] = projectDetailsObservable;

        return projectDetailsObservable;
    }

    private getProjectTrigger(projectId: string): BehaviorSubject<undefined> {
        let projectTrigger = this.projectTriggers[projectId];
        if (!projectTrigger) {
            projectTrigger = new BehaviorSubject(undefined);
            this.projectTriggers[projectId] = projectTrigger;
        }
        return projectTrigger;
    }

    newComponent(projectId: string): Component_UpsertComponentRequest {
        return new Component_UpsertComponentRequest({
            projectId: projectId,
            componentId: Guid.newGuid(),
            batchSize: 1,
            comment: "",
            description: "",
            serialNumber: "",
            setNumber: "",
            volumeM3: 0,
            weightKg: 0
        });
    }

    upsertComponents(components: ComponentStatus[]): Promise<any> {
        let updatePromises: Promise<any>[] = components
            .map(component => {
                if (component.status === 'deleted') {
                    return this.componentClient.deleteComponent(component.request.componentId).toPromise();
                }
                else if (component.status === 'changedOrNew') {
                    return this.componentClient.upsertComponent(component.request).toPromise();
                }
                else if (component.status === 'unchanged') {
                    return Promise.resolve();
                }
                else {
                    throw new Error("Unknown Component status: " + component.status);
                }
            });
        let projectIds: { [projectId: string]: true } = {};
        for (let c of components)
            projectIds[c.request.projectId] = true;

        this.reloadProject(...Object.keys(projectIds));

        return Promise.all(updatePromises);
    }

    async getComponents(projectId: string): Promise<ComponentStatus[]> {
        let projectDetails = await this.projectClient.getProjectDetails(projectId).toPromise();
        let result = projectDetails.components.map(comp => {
            let request = new Component_UpsertComponentRequest({
                ...comp,
                componentId: comp.id,
                projectId: projectDetails.id
            });
            let status = new ComponentStatus(request, 'unchanged');
            return status;
        });
        return result;
    }


    reloadProject(...projectIds: string[]) {
        this.allTrigger.next(true);
        for (let projectId of projectIds) {
            let pTrigger = this.projectTriggers[projectId];
            if (pTrigger) {
                pTrigger.next(undefined);
            }
        }
    }

    getComments(projectId: string): Observable<ProjectComment_GetProjectCommentsResponse_ProjectComment[] | undefined> {
        let commentsObservable = this.commentsByProjectId[projectId];
        if (commentsObservable) {
            return commentsObservable;
        }
        let projTrigger = this.getProjectTrigger(projectId);

        commentsObservable = projTrigger.pipe(
            switchMap(_ => merge(
                of(undefined),
                this.commentClient.getProjectComments(projectId).pipe(map(response => response.projectComments))
            )),
            publishReplay(2),
            refCount(),
        );

        this.commentsByProjectId[projectId] = commentsObservable;
        return commentsObservable;
    }

    async deleteProject(projectId: string) {
        await this.projectClient.deleteProject(projectId).toPromise();
        this.reloadProject("");
        this.router.navigate(['../projects']);
        this.reloadProject("");
    }



}
