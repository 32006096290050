import { UserRight } from '../WebApiClient';

export class YoutubeVideo {
    public readonly linkUrl: string;

    constructor(
        public readonly title: string,
        public readonly id: string,
        public readonly requiredRight: UserRight,
    ) {
        this.linkUrl = `https://youtu.be/${id}`;
    }
}
