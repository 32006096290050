import { Injectable } from '@angular/core';
import { Login } from '../models/Login';
import { LoginClient, ILogin_LoginResponse, Login_LoginRequest, UserRight, ILogin_LinkLoginResponse } from '../WebApiClient';
import { BehaviorSubject, Observable } from 'rxjs';
import { LinkLogin } from '../models/LinkLogin';



@Injectable()
export class LoginService {
    private static readonly userTokenKey = "userToken";

    private readonly currentUserSubject = new BehaviorSubject<Login | LinkLogin | undefined>(undefined);


    constructor(
        private readonly webLogin: LoginClient
    ) {
        let tokenJson = sessionStorage.getItem(LoginService.userTokenKey);
        if (tokenJson) {
            try {
                this._loginResponse = JSON.parse(tokenJson);
            }
            catch (e) {
                this._loginResponse = undefined;
            }
        }
        else {
            this._loginResponse = undefined;
        }
    }

    private _loginResponse: ILogin_LoginResponse | ILogin_LinkLoginResponse | undefined
    private get loginResponse(): ILogin_LoginResponse | ILogin_LinkLoginResponse | undefined {
        return this._loginResponse;
    }
    private set loginResponse(val: ILogin_LoginResponse | ILogin_LinkLoginResponse | undefined) {
        this._loginResponse = val;
        sessionStorage.setItem(LoginService.userTokenKey, JSON.stringify(val));
    }

    public get loginToken(): string | undefined {
        return this.loginResponse?.token;
    }

    public get currentUserObservable(): Observable<Login | LinkLogin | undefined> {
        return this.currentUserSubject;
    }

    public async login(username: string, password: string): Promise<boolean> {
        this.loginResponse = undefined;
        try {
            let token = await this.webLogin.login(new Login_LoginRequest({ username: username, password: password })).toPromise();
            this.loginResponse = token;
            this.currentUserSubject.next(this.currentUser);
            return !!token;
        }
        catch (e) {
            this.currentUserSubject.next(this.currentUser);
            return false;
        }
    }

    public logout() {
        sessionStorage.removeItem(LoginService.userTokenKey);
    }

    public async LoginWithLink(linkId: string): Promise<string | undefined> {
        this.loginResponse = undefined;
        try {
            const token = await this.webLogin.loginWithLink(linkId).toPromise();
            this.loginResponse = token;
            this.currentUserSubject.next(this.currentUser);
            return token?.projectId;
        }
        catch (e) {
            this.currentUserSubject.next(this.currentUser);
            return;
        }
    }

    public async createProjectLink(projectId: string): Promise<string> {
        var tok = await this.webLogin.createProjectLink(projectId).toPromise();
        return tok;
    }

    public get currentUser(): Login | LinkLogin | undefined {
        const lr = this.loginResponse;
        if (!lr)
            return;
        if (this.isLoginResponse(lr)) {
            let result = new Login(
                lr.userId,
                lr.username,
                lr.userRights
            );
            return result;
        }
        const result = new LinkLogin(lr.projectId, lr.userRights);
        return result;
    }

    /**
     * returns true if user has all the userRights asked for.
     */
    hasRights(...userRights: UserRight[]) {
        return userRights.every(r => (this.currentUser?.userRights.indexOf(r) ?? -1) >= 0);
    }

    private isLoginResponse(val: ILogin_LoginResponse | ILogin_LinkLoginResponse): val is ILogin_LoginResponse {
        return !!((val as ILogin_LoginResponse).userId);
    }

    public lastFailedNavigation?: string = undefined;
}
