import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from '../../../models/ComponentBase';
import { map } from 'rxjs/operators';
import { ShipReportService } from '../../../services/ship-report.service';
import { faHourglass } from '@fortawesome/free-regular-svg-icons';
import { ToastService } from '../../../services/toast.service';

@Component({
    selector: 'app-project-report',
    templateUrl: './project-report.component.html',
    styleUrls: ['./project-report.component.scss', '../../../style/project-details.scss', '../../../style/sidebarLayout.scss']
})
export class ProjectReportAndStastiticsComponent extends ComponentBase {
    private readonly currentDownloads: string[] = [];
    private projectId: string | undefined;
    readonly faHourglass = faHourglass;

    constructor(
        private readonly shipReportService: ShipReportService,
        route: ActivatedRoute,
        private readonly toastService: ToastService
    ) {
        super();

        const projectIdObservable = route.params.pipe(map(p => p["id"]));

        const routeSub = projectIdObservable.subscribe(projectId => {
            this.projectId = projectId;
        })
        this.subscriptions.push(routeSub);
    }

    isDownloading() {
        if(this.projectId)
            return this.currentDownloads.indexOf(this.projectId) >= 0;
    }
    
    async downloadShipReport() {
        if (!this.projectId)
            throw new Error("Could not download shipreport - missing projectId");

        this.currentDownloads.push(this.projectId);

        try {
            await this.shipReportService.download(this.projectId);
        }
        catch (e) {
            this.toastService.reportFailure("Could not generate ship report", e);
        }
        finally {
            let i = this.currentDownloads.indexOf(this.projectId);
            if (i >= 0) {
                this.currentDownloads.splice(i, 1);
            }
        }
    }


}
