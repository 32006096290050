import { Component, OnInit, Input } from '@angular/core';
import { ProjectComment_GetProjectCommentsResponse_CommentAttachment } from '../../WebApiClient';
import { FileService } from '../../services/file.service';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

    constructor(private readonly fileService: FileService) { }

    ngOnInit(): void {
    }

    @Input()
    attachment: ProjectComment_GetProjectCommentsResponse_CommentAttachment = new ProjectComment_GetProjectCommentsResponse_CommentAttachment();

    isImage(): boolean {
        if (!this.attachment)
            return false;
        return !!/^image\//.exec(this.attachment.mimeType);
    }

    download(evt: Event) {
        if (!this.attachment)
            return;
        let link = document.createElement("a");
        link.href = this.fileService.getUrl(this.attachment.fileId);
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        link.remove();
        evt.stopPropagation();
    }

    get fileUrl(): string | undefined {
        if (!this.attachment)
            return;
        return this.fileService.getUrl(this.attachment.fileId);
    }

    faDownload = faDownload;
}
