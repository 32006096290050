<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">

    <h3>Gangs</h3>

    <div *ngIf="isLoading(gangs)">
        <h4>Loading...</h4>
    </div>
    <div *ngIf="isEmpty(gangs)">
        <h4>No gangs defined on project.</h4>
    </div>

    <div *ngIf="isLoaded(gangs)">

        <div *ngFor="let gang of gangs">
            <div class="info-group">
                <h4>{{gang.name}}</h4>

                <table>
                    <tr>
                        <td class="col-label">Started</td>
                        <td class="col-value" colspan="2">{{gang.workStart | date : 'dd-MM-yyyy HH:mm' }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Finished</td>
                        <td class="col-value" colspan="2">{{gang.workEnd  | date : 'dd-MM-yyyy HH:mm'}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Comments</td>
                        <td class="col-value" colspan="2" style="white-space:pre-line;">{{gang.comment}}</td>
                    </tr>
                    <tr *ngFor="let gangMember of gang.gangMembers; let firstMember = first;">
                        <td class="col-label"><span *ngIf="firstMember">Members</span></td>
                        <td class="col-value">{{gangMember.workerName}}</td>
                        <td class="col-value">{{gangMember.workerExternalId}}</td>
                    </tr>
                </table>
            </div>

        </div>

    </div>
</div>
