import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { ComponentStatus } from '../../models/ComponentStatus';
import { ProjectSearchOptions } from '../../models/ProjectSearchOptions';
import { TypedModalRef } from '../../models/TypedModalRef';
import { ProjectService } from '../../services/project.service';
import { ProjectClient, Project_GetProjectDetailsResponse, Project_GetProjectListResponse,  Project_ProjectOverview } from '../../WebApiClient';
import { ComponentBase } from '../../models/ComponentBase';
import { EditComponentComponent } from '../edit-component/edit-component.component';
import { Guid } from '../../models/Guid';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-edit-template-projects-mondal',
    templateUrl: './edit-template-projects-modal.component.html',
    styleUrls: ['./edit-template-projects-modal.component.scss']
})
export class EditTemplateProjectsModalComponent extends ComponentBase {

    public selectedProject: Project_ProjectOverview | undefined;
    public newProjectActive: boolean = false;

    private static readonly relativeDayKey = "projects_relativeDaySelection";

    project: Project_GetProjectDetailsResponse | undefined;
    private response: Project_GetProjectListResponse | undefined = undefined;
    private page = 1;
    //public projectos: ProjectOverview[] | undefined = undefined;
    private _relativeDay: number | null = 7; //TODO

    public relativeDaySelections: {
        days: number | null,
        text: string
    }[] = [
            { days: 0, text: "Future" },
            { days: 7, text: "Last 7 days" },
            { days: 14, text: "Last 14 days" },
            { days: 30, text: "Last month" },
            { days: 90, text: "Last 3 months" },
            { days: 365, text: "Last year" },
            { days: null, text: "All" },
        ];

    private readonly searchOptionsSubject: BehaviorSubject<ProjectSearchOptions>;

    constructor(
        private readonly activeModal: NgbActiveModal,
        private readonly projectService: ProjectService,
        private readonly toastService: ToastService,
        private readonly projectClient: ProjectClient
    ) {
        super();
        this.searchOptionsSubject = new BehaviorSubject(this.getSearchOptions());
        this.initRelativeDay();
        let subscription = projectService.getTemplatesObservable(this.searchOptionsSubject.pipe(debounceTime(200)))
            .subscribe(response => {
                this.response = response;
            });
        this.subscriptions.push(subscription);

        
    }
    
    public get projects() {
        return this.response?.projects;
    }


    cancel() {
        this.activeModal.dismiss("user cancelled");
    }


    public selectProject(project: Project_ProjectOverview) {
        this.selectedProject = project;
    }

    static open(projectId: string, modalService: NgbModal): TypedModalRef<EditTemplateProjectsModalComponent, Project_GetProjectDetailsResponse> {
        let modal = modalService.open(EditTemplateProjectsModalComponent, { size: 'lg', windowClass: 'full-height-modal' });
        let modalComponent = <EditTemplateProjectsModalComponent>modal.componentInstance;
        return new TypedModalRef(modal);
    }

    private _searchTerm: string = "";
    public get searchTerm() { return this._searchTerm; }
    public set searchTerm(val: string) {
        this._searchTerm = val;
        this.triggerSearch();
    }

    private triggerSearch() {
        this.searchOptionsSubject.next(this.getSearchOptions());
    }

    public get relativeDay() { return this._relativeDay; }
    public set relativeDay(val: number | null) {
        this._relativeDay = val;
        this.triggerSearch();
        localStorage.setItem(EditTemplateProjectsModalComponent.relativeDayKey, (typeof val === "number") ? val.toString() : "null");
    }

    private initRelativeDay() {
        let relativeDayStr = localStorage.getItem(EditTemplateProjectsModalComponent.relativeDayKey);
        let isSet = false;
        if (relativeDayStr) {
            if (relativeDayStr === "null") {
                this.relativeDay = null;
                isSet = true;
            }
            else {
                try {
                    let relday = parseInt(relativeDayStr);
                    this.relativeDay = relday;
                    isSet = true;
                }
                catch (e) {
                    /* do nothing */
                }
            }
        }
        if (!isSet) {
            this.relativeDay = 7;
        }
    }

    public async load(ProjectId: string) {
        let project: Project_GetProjectDetailsResponse;
        project = await this.projectClient.getProjectDetails(ProjectId).toPromise();
        this.activeModal.close(project);
    }

    async delete(ProjectId: string) {
        try {
            await this.projectService.deleteProject(ProjectId);
            this.toastService.add("Project deleteed", `The project ${ProjectId} was deleted`, "success");
        } catch (e) {
            this.toastService.reportFailure("Could not delete project", e);
        }
    }

    private getSearchOptions(): ProjectSearchOptions {
        if (typeof this.relativeDay === "number") {
            let d = new Date();
            d.setDate(d.getDate() - this.relativeDay)
            return new ProjectSearchOptions({
                searchTerm: this.searchTerm,
                after: d,
                page: this.page,
                descending: true,
            });
        }
        else {
            return new ProjectSearchOptions({
                searchTerm: this.searchTerm,
                page: this.page,
                descending: true,
            })
        }
    }

    private sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
