import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { UserRight } from '../WebApiClient';


/** Checks if user has the required rights to navigate to a route. You must set data.rights to make it work.
 * see https://stackoverflow.com/questions/42719445/pass-parameter-into-route-guard
 * */
@Injectable({
    providedIn: 'root'
})
export class HasRightsGuard implements CanActivate {

    constructor(
        private readonly userService: LoginService,
        private readonly router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        let rights = this.userService.currentUser?.userRights;

        let requiredRights = next.data.rights as UserRight[];

        for (let reqR of requiredRights) {
            if (!rights || rights.indexOf(reqR) < 0) {
                this.userService.lastFailedNavigation = state.url;
                this.router.navigate(["/login"]);
                return false;
            }
        }
        return true;
    }

}
