import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../models/ComponentBase';
import { Project_PostalAddress } from '../../WebApiClient';

@Component({
    selector: 'app-edit-postal-address-inner',
    templateUrl: './edit-postal-address-inner.component.html',
    styleUrls: ['./edit-postal-address-inner.component.scss', '../../style/project-details.scss', '../../style/project-details-editor.scss']
})
export class EditPostalAddressInnerComponent extends ComponentBase {

    constructor() {
        super()
    }

    @Input("postalAddress")
    addr?: Project_PostalAddress;

    @Input()
    displayName?: string;
}
