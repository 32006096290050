import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { Login } from '../../models/Login';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    private lastToast: ToastService.Toast | undefined;

    constructor(
        private readonly userService: LoginService,
        private readonly router: Router,
        private readonly toastService: ToastService
    ) {
    }

    ngOnInit(): void {
    }

    private _username: string = "";
    public get username(): string {
        return this._username;
    }

    public set username(val: string) {
        this._username = val;
    }

    private _password: string = "";
    public get password() {
        return this._password;
    }
    public set password(val: string) {
        this._password = val;
    }

    public get areCredentialsUsable() {
        return this.username && this.username !== ""
            && this.password && this.password !== ""
            && true;
    }

    public get isLoggedIn() {
        return !!this.userService.currentUser;
    }

    public get currentUsername() {
        const currentUser = this.userService.currentUser;
        if (currentUser instanceof Login)
            return currentUser.username;
        return "";
    }

    public async login() {
        if (!this.areCredentialsUsable)
            return;
        if (this.lastToast) {
            this.toastService.remove(this.lastToast);
            this.lastToast = undefined;
        }
        let success = await this.userService.login(this.username, this.password);
        if (success) {
            this.lastToast = this.toastService.add("Logged in", "You were logged in", "success")
            if (this.userService.lastFailedNavigation) {
                let newUrl = this.userService.lastFailedNavigation;
                this.userService.lastFailedNavigation = undefined;
                this.router.navigateByUrl(newUrl)
            }
            else {
                this.router.navigate(["/"]);
            }
        }
        else {
            this.lastToast = this.toastService.add("Login failed", "Could not log you in", "warning");
        }
    }

    public logout() {
        this.userService.logout();
    }
}
