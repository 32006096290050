import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ComponentBase } from "../../../models/ComponentBase";
import { ProjectService } from "../../../services/project.service";
import { Project_GetProjectDetailsResponse, IMailingList_MailReceiver, MailingListClient, MailingList_MailReceiverResponse, MailLogClient, MailLog_GetMailLogResponse, MailLog_MailLogResponse} from "../../../WebApiClient";

@Component({
    selector: 'app-mail-log',
    templateUrl: './mail-log.component.html',
    styleUrls: ['./mail-log.component.scss', '../../../style/project-details.scss', '../../../style/sidebarLayout.scss']
})
export class MailLogComponent extends ComponentBase {
    project: Project_GetProjectDetailsResponse | undefined;
    mailingList: IMailingList_MailReceiver[] = [];
    public mailReceivers: MailingList_MailReceiverResponse[] | undefined;
    public mailLogs: MailLog_MailLogResponse[] | undefined;

    constructor(
        private readonly projectService: ProjectService,
        private readonly mailClient: MailingListClient,
        private readonly mailLogClient: MailLogClient,
        route: ActivatedRoute
    ) {
        super();
        let projSub = route.params.pipe(
            map(params => params["id"]),
            switchMap(id => projectService.getProjectDetails(id))
        ).subscribe(proj => this.project = proj);

        this.subscriptions.push(projSub);

        const mailLogObservable = route.params.pipe(
            map(p => p["id"] as string | undefined),
            switchMap(projectId => projectId
                ? projectService.getProjectDetails(projectId)
                : of(undefined)),
            switchMap(projectDetails => projectDetails?.id
                ? mailLogClient.getMailLog(projectDetails.id)
                : of(undefined)),
        );


        const sub = mailLogObservable.subscribe(ml => this.mailLogs = ml?.mailLogs);
        this.subscriptions.push(sub);


        if (this.project?.id) {
            let mLogs = this.mailLogClient.getMailLog(this.project.id).subscribe(list => this.mailLogs = list.mailLogs);
            this.subscriptions.push(mLogs);
        }

    }

}
