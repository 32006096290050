<app-topbar></app-topbar>

<nav class="sidebar navbar-dark bg-dark">
    <div class="sidebar-header">
        <h5>Training videos</h5>
    </div>

    <ul class="nav flex-column navbar-nav">
    </ul>
</nav>

<div class="main-content">

    <div *ngFor="let gr of videoGroups">
        <h4>{{gr.name}}</h4>
        <ul>
            <li *ngFor="let video of gr.videos">
                <a [href]="video.linkUrl" target="_blank" href="">{{video.title}}</a>
            </li>
        </ul>
    </div>

    <div *ngIf="videoGroups.length === 0">
        <h4>No training videos available</h4>
    </div>

</div>
