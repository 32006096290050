import { UserRight } from '../WebApiClient';

export class LinkLogin {
    constructor(
        public readonly projectId: string,
        public readonly userRights: UserRight[],
    ) {

    }

}
