<div class="input-group">

    <ng-template #rt let-r="result" let-t="term">
        <div style="display:flex;">
            <div style="flex-grow:1; margin-right:10px;">
                <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            </div>
            <button *ngIf="showDeleteButton"
                    class="btn btn-outline-danger btn-sm"
                    (click)="deleteMember(r)">
                Delete
            </button>
        </div>
    </ng-template>


    <input class="form-control"
           [(ngModel)]="selectedMember"
           [ngbTypeahead]="search"
           [inputFormatter]="formatter"
           [resultTemplate]="rt"
           [editable]="false"
           autocomplete="off"
           focusFirst="true"
           container="body"
           placeholder="Search..." />
    <div class="input-group-append" *ngIf="showNewButton">
        <button class="btn btn-outline-secondary"
                type="button"
                style="margin-bottom:0"
                (click)="newMember()">
            New
        </button>
    </div>
</div>
