<div class="modal-header">
    <h4>
        {{title}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>



<div class="modal-body">
    <ul class="list-group list-group-flush">
        <li class="list-group-item comment"
            *ngFor="let comment of comments">
            <div class="comment-header">
                <div class="comment-time">
                    {{comment.timestamp | date: 'shortTime'}}
                </div>
                <div class="comment-text">
                    {{comment.comment}}
                </div>
            </div>
            <div class="attachments">
                <div class="attachment"
                        *ngFor="let attachment of comment.attachments"
                        (click)="show(attachment)">

                    <app-attachment [attachment]="attachment"></app-attachment>
                </div>
            </div>
        </li>
    </ul>
</div>
