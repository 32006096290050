import { Component } from '@angular/core';
import { ComponentBase } from '../../models/ComponentBase';
import { MailingListClient, IMailingList_MailReceiver, MailingList_UpsertMailingListRequest } from '../../WebApiClient';
import { TypedModalRef } from '../../models/TypedModalRef';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-edit-mailing-list',
    templateUrl: './edit-mailing-list.component.html',
    styleUrls: ['./edit-mailing-list.component.scss']
})
export class EditMailingListComponent extends ComponentBase {

    singleAddress: string = "";
    singleName: string = "";
    multipleReceivers = "";
    private mailingListId?: string;
    addressesArray: IMailingList_MailReceiver[] = [];
    testOfAddressesText: string = "";
    testOfNamesText: string = "";
    holdingAddressText: string = "";
    MainActorMap = new Map<string,boolean>();
    constructor(
        private readonly activeModal: NgbActiveModal,
        private readonly mlClient: MailingListClient,
        private readonly toastService: ToastService
    ) {
        super();

    }

    async init(mailingListId: string) {
        this.mailingListId = mailingListId;
        try {
            var mailingList = await this.mlClient.getMailingList(mailingListId).toPromise();
            
            this.testOfAddressesText = mailingList.mailReceivers
                .map(mr => `${mr.name} <${mr.emailAddress}>`)
                .join(";\n");
            if (this.testOfAddressesText) {
                this.testOfAddressesText = this.testOfAddressesText + ";\n";
                
            }
            this.addressesArray = mailingList.mailReceivers;

            this.testOfNamesText = mailingList.mailReceivers
                .map(mr => `${mr.name}`)
                .join(";\n");
            /*for (var i = 0; i < this.addressesArray.length; i++) {
                this.MainActorMap.set(this.addressesArray[i].emailAddress, false);
            }*/
        } catch (e) {
            //mailingList probably doesn't exist yet
            this.singleAddress = "";
        }
    }



    async addSingleReceiver() {

        this.addressesArray.push({
            emailAddress: this.singleAddress,
            name: this.singleName,
            isMainActor: false,
            lastChanged: new Date()
        });

        this.singleAddress = "";
        this.singleName = "";
    }

    async addMultipleReceivers() {
        this.addressesArray.push(...this.getReceivers());
        this.multipleReceivers = "";
    }

    cancel() {
        this.activeModal.dismiss("user cancelled");
    }

    changeMainActor(emailAddress: string) {
        if (this.MainActorMap.get(emailAddress) == false) {
            this.MainActorMap.set(emailAddress, true);
        }
        if (this.MainActorMap.get(emailAddress) == true) {
            this.MainActorMap.set(emailAddress, false);
        }
        
    }

    isMainActor(emailAddress: string): boolean {
        if (this.MainActorMap.get(emailAddress)) {
            return true;
        }
        else
            return false;
    }

    removeFromList(emailAddress: string) {
       
        for (var i = 0; i < this.addressesArray.length; i++) {
            if (this.addressesArray[i].emailAddress == emailAddress) {
                this.addressesArray.splice(i, 1);
            }
            this.holdingAddressText = this.addressesArray.map(mr => `${mr.name} <${mr.emailAddress}>`).join(";\n");
        }
        
    }


   async saveChanges() {
        if (!this.mailingListId)
            return;
        try {
            await this.mlClient.upsertMailingList(new MailingList_UpsertMailingListRequest({
                mailingListId: this.mailingListId,
                mailReceivers: this.addressesArray
            })).toPromise();
            this.activeModal.close();
        } catch (e) {
            this.toastService.reportFailure("Could not update mailing list", e);
        }
    }

    private getReceivers(): IMailingList_MailReceiver[] {
        let splitText = this.multipleReceivers.split(/[;\n]\s*/);
        let outlookRegex = /^\s*(.*?)\s*\<(?:'?)(\S+@\S+?)(?:'?)\>\s*$/;  //matches one "outlook-style" address, eg: Kasper Guldmann Nielsen <kgn@example.com>
        let normalRegex = /^\s*(\S+@\S+)\s*$/;          //matches one normal email address, with optional whitespace before and after
        let emptyRegex = /^\s*$/        //matches whitespace only
        let receivers = splitText
            .filter(t => !t || !emptyRegex.test(t)) //ignore empty-ish lines
            .map<IMailingList_MailReceiver>(t => {
                let outlookMatch = outlookRegex.exec(t);
                if (outlookMatch) {
                    return {
                        name: outlookMatch[1],
                        emailAddress: outlookMatch[2],
                    };
                }
                let normalMatch = normalRegex.exec(t);
                if (normalMatch) {
                    return {
                        name: " ",
                        emailAddress: normalMatch[1],
                        isMainActor: false,
                        lastChanged: new Date()
                    }
                }
                throw new Error(`Unknown email address: "${t}"`);
            });
        return receivers;
    }


    private getNames(): string[] {
        let recieversNamesText;
        if (this.testOfNamesText != "") {
            recieversNamesText = this.testOfNamesText + ";\n" + this.singleName;
        } else {
            recieversNamesText = ";\n" + /*this.testOfNamesText +*/ this.singleName;
        }
        let splitText = recieversNamesText.split(/[;\n]\s*/);
        let emptyRegex = /^\s*$/;
        //this.testOfAddressesText = this.testOfAddressesText + this.namesText;
        this.testOfNamesText = this.testOfNamesText + ";\n" + this.singleName;
        return splitText.filter(t => !t || !emptyRegex.test(t));
    }

    static open(modalService: NgbModal, mailingListId: string): TypedModalRef<EditMailingListComponent, void> {
        let modal = modalService.open(EditMailingListComponent, {size: 'lg', windowClass:'full-height-modal'});

        let modalComponent = <EditMailingListComponent>modal.componentInstance;
        modalComponent.init(mailingListId);
        return new TypedModalRef(modal);

    }

}
