import { Injectable } from '@angular/core';
import { ProjectReportClient, ProjectReport_GetProjectTemplatesResponse_ProjectTemplate } from '../WebApiClient';
import { Observable, merge, BehaviorSubject, of, Subscription } from 'rxjs';
import { switchMap, publishReplay, refCount, map } from 'rxjs/operators';

@Injectable()
export class ProjectReportService {

    readonly templates: Observable<ProjectReport_GetProjectTemplatesResponse_ProjectTemplate[] | undefined>;
    private trigger = new BehaviorSubject<undefined>(undefined);
    private ownSubscription: Subscription;

    constructor(
        private readonly reportClient: ProjectReportClient,
    ) {
        this.templates = this.trigger.pipe(
            switchMap(_ => merge(
                of(undefined),
                reportClient.getTemplates().pipe(map(response => response.projectTemplates))
            )),
            publishReplay(2),
            refCount()
        );
        this.ownSubscription = this.templates.subscribe(_ => { });
    }


    async download(templateId: string, projectId: string) {
        let r = await this.reportClient.getReport(projectId, templateId).toPromise();

        let url = window.URL.createObjectURL(r.data);
        let a = document.createElement("a");
        a.href = url;
        a.download = r.fileName ?? "Report.docx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }


}
