import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UserRoles } from '../../Constants';
import { User_UpsertUserRequest, User_User, UserRole, Customer_Customer } from '../../WebApiClient';
import { Guid } from '../../models/Guid';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';
import { CustomerService } from '../../services/customer.service';
import { Subscription } from 'rxjs';
import { Login } from '../../models/Login';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss', '../../style/crudFloat.scss']
})
export class EditUserComponent implements OnChanges, OnInit, OnDestroy {


    public readonly roles = UserRoles;
    public user: User_UpsertUserRequest;
    public customers: Customer_Customer[];
    customersSubscription: Subscription;

    constructor(
        customerService: CustomerService,
        private readonly userService: UserService,
        private readonly loginService: LoginService,
    ) {
        this.customers = customerService.customers;
        this.customersSubscription = customerService.customersObservable.subscribe(c => {
            if (c)
                this.customers = c;
        });
        this.user = this.newUser();
    }
    ngOnDestroy(): void {
        this.customersSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("userToChange" in changes) {
            this.user = this.newUser();
        }
    }

    ngOnInit(): void {
        this.user = this.newUser();
    }

    @Input('user')
    userToChange?: User_User;

    get isInputValid(): boolean {
        if (!this.user)
            return false;
        if (!this.user.id)
            return false;
        if (this.user.id === Guid.empty)
            return false;
        if (!this.user.email)
            return false;
        if (!this.user.name)
            return false;
        if (!this.user.username)
            return false;
        if (!this.user.userRole)
            return false;
        if (this.user.userRole == UserRole.Customer && !this.user.customerId)
            return false;


        return true;
    }

    get isUserDeletable(): boolean {
        if (!this.user)
            return false;
        if (!this.user.id)
            return false;
        if (this.user.id === Guid.empty)
            return false;

        const currentUser = this.loginService.currentUser;
        if (currentUser instanceof Login && this.user.id === currentUser.id)
            return false;

        return true;
    }

    update() {
        if (!this.isInputValid)
            return;
        if (!this.isCustomerSelectionValid)
            this.user.customerId = undefined;
        return this.userService.upsertUser(this.user);
    }

    delete() {
        if (!this.isUserDeletable)
            return;
        var isSure = confirm("Are you sure you want to delete the user?")
        if (!isSure)
            return;
        this.userService.deleteUser(this.user!.id!);
    }

    private newUser(): User_UpsertUserRequest {

        const result = new User_UpsertUserRequest();
        result.id = this.userToChange?.id ?? Guid.newGuid();
        result.email = this.userToChange?.email ?? "";
        result.name = this.userToChange?.name ?? "";
        result.password = "";
        result.username = this.userToChange?.username ?? "";
        (<any>result.userRole) = this.userToChange?.userRole ?? "";
        result.customerId = this.userToChange?.customerId ?? "";
        return result;
    }
    get isCustomerSelectionValid() {
        return this.user?.userRole === UserRole.Customer;
    }
}
