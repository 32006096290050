import { Pipe, PipeTransform } from '@angular/core';
import { IProject_DateTimeTba as IDateTimeTba } from '../WebApiClient';

@Pipe({
    name: 'tbaDate'
})
export class TbaDatePipe implements PipeTransform {

    private static dateTimeFormat = new Intl.DateTimeFormat(["pt-BR"], {
        timeZone: "Europe/Copenhagen",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });

    private static dateFormat = new Intl.DateTimeFormat(["pt-BR"], {
        timeZone: "Europe/Copenhagen",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });

    transform(value: unknown, ...args: unknown[]): string {
        let d: Date;
        let isTba = false;
        if (!value) {
            return " -- ";
        }
        else if (value instanceof Date) {
            d = value;
        }
        else if (this.isDateTimeTba(value)) {
            d = value.dateTime;
            isTba = value.timeIsTba;
        }
        else {
            throw new Error(`Unable to convert ${value} in TbaDatePipe`);
        }

        if (isTba) {
            return TbaDatePipe.dateFormat.format(d);
        }
        else {
            return TbaDatePipe.dateTimeFormat.format(d);
        }
    }

    private isDateTimeTba(val: any): val is IDateTimeTba {
        if (!val)
            return false;
        if (typeof val !== 'object')
            return false;
        if (!(val.dateTime instanceof Date))
            return false;
        if (typeof val.timeIsTba !== 'boolean')
            return false;
        return true;
    }

}
