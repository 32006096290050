<div class="modal-header">
    <h4>Edit mail recipients</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <span style="width:100%; display:inline-block">Insert mail addresses and names here, separated by enter or semicolon:</span>
    <form id="add-address-form">
        <input [(ngModel)]="singleName" placeholder="Name" class="form-control" name="receiverName" />
        <input [(ngModel)]="singleAddress"
               placeholder="Email address"
               type="email"
               required
               class="form-control" name="receiverEmail" />
        <button class="btn btn-primary" type="submit" (click)="addSingleReceiver()">Add</button>
    </form>

    <form id="add-address-form">
        <textarea [(ngModel)]="multipleReceivers" placeholder="Name" class="form-control" name="receivers"></textarea>
        <button class="btn btn-primary" type="submit" (click)="addMultipleReceivers()">Add</button>
    </form>

    <div class="container main-table">

        <table class="table table-striped">
            <colgroup>
                <col style="width:35%" />
                <col style="width:35%" />
                <col style="width:15%" />
                <col style="width:15%" />
            </colgroup>
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">MainActor</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let receiver of addressesArray">
                    <td style="padding:0.25rem">
                        {{receiver.name}}
                    </td>
                    <td style="padding:0.25rem">
                        {{receiver.emailAddress}}
                    </td>
                    <td style="padding:0.25rem">
                        <input type="checkbox" [checked]="receiver.isMainActor" (change)="receiver.isMainActor = !receiver.isMainActor" />
                    </td>
                    <td style="padding:0.25rem">
                        <button type="button" class="btn btn-outline-primary btn-block" (click)="removeFromList(receiver.emailAddress)">Remove</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>



<div class="modal-footer">
    <div class="btn-group">
        <button class="btn btn-primary"
                type="button"
                (click)="saveChanges()"
                >Update</button>
        <button class="btn btn-secondary"
                type="button"
                (click)="cancel()"
                >Cancel</button>
    </div>
</div>
