import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-date-time',
    templateUrl: './date-time.component.html',
    styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent implements OnInit {

    private lastInvalidVal?: string;
    private _lastDate: NgbDateStruct | undefined;
    private _lastTime: string = "";

    constructor() { }

    ngOnInit(): void {
    }

    @Input()
    id?: string;

    @Input()
    date: Date | undefined = undefined;

    @Output()
    dateChange = new EventEmitter<Date | undefined>();


    get dateId() { return this.id ? `${this.id}-date` : undefined }
    get timeId() { return this.id ? `${this.id}-time` : undefined }

    get dateStruct(): NgbDateStruct | string | undefined {
        let d = this.date;
        if (!d)
            return this.lastInvalidVal;
        let result = {
            day: d.getDate(),
            month: d.getMonth() + 1,
            year: d.getFullYear()
        };
        if (this._lastDate
            && this._lastDate.day === result.day
            && this._lastDate.month === result.month
            && this._lastDate.year === result.year)
            return this._lastDate;
        this._lastDate = result;
        return result;
    }

    set dateStruct(val: NgbDateStruct | string | undefined) {
        if (!val || typeof val == "string") {
            this.lastInvalidVal = val;
            this.date = undefined;
            this.dateChange.emit(undefined);
            return;
        }
        let d = this.date ?? new Date();
        d.setFullYear(val.year);
        d.setMonth(val.month - 1);
        d.setDate(val.day);
        this.date = d;
        this.dateChange.emit(d);
    }

    get time(): string {
        let d = this.date;
        if (!d)
            return this._lastTime;

        let hour = "" + d.getHours();
        while (hour.length < 2)
            hour = "0" + hour;

        let min = "" + d.getMinutes();
        while (min.length < 2)
            min = "0" + min;

        let t = `${hour}:${min}`;
        if (t === this._lastTime)
            return this._lastTime;
        this._lastTime = t;
        return t;
    }
    set time(val: string) {
        let match = /\s*(\d\d)(?:[\:\.])?(\d\d)\s*/.exec(val);
        if (!match) {
            this._lastTime = val;
            return;
        }
        let d = this.date ?? new Date();

        let hours = match[1];
        let mins = match[2];
        d.setHours(Number(hours));
        d.setMinutes(Number(mins));
        d.setSeconds(0);
        this.date = d;
        this.dateChange.emit(d);
    }

    faCalendar = faCalendar;
}
