<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">

    <h3>Mail Recipients</h3>


    <h5 *ngIf="isLoading(mailReceivers)">Loading</h5>


    <div *ngIf="isEmpty(mailReceivers)">No mail recipients</div>

    <div style="height:20px;"></div>

    <div *ngIf="isLoaded(mainActors)">
        <h4>Main Actors</h4>
        <table class="table table-striped" style="max-width: 150ch;">
            <colgroup>
                <col style="width:40%" />
                <col style="width:40%" />
                <col style="width:20%" />
            </colgroup>
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Subscribed</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of mainActors">
                    <td>{{r.name}}</td>
                    <td>{{r.email}}</td>
                    <td>{{r.isActive ? 'Yes':'No'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style="height:20px"></div>
    <div *ngIf="isLoaded(otherReceivers)">
        <h4>Other mail recipients</h4>
        <table class="table table-striped" style="max-width: 150ch;">
            <colgroup>
                <col style="width:40%" />
                <col style="width:40%" />
                <col style="width:20%" />
            </colgroup>
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Subscribed</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of otherReceivers">
                    <td>{{r.name}}</td>
                    <td>{{r.email}}</td>
                    <td>{{r.isActive ? 'Yes':'No'}}</td>
                </tr>
            </tbody>
        </table>
    </div>


</div>
