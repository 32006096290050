<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">
    <h3>Project Documents</h3>

    <table class="table table-striped" style="max-width:150ch;">
        <thead>
            <tr>
                <th scope="col">Send file</th>
                <th scope="col">Document Name</th>
                <th scope="col">Updated</th>
                <th scope="col">Sent</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let pf of projectFiles">
                <td><input type="checkbox" name="" id="" [(ngModel)]="pf.isChecked" (change)="!pf.isChecked" value="pf.fileId"/></td>
                <td>{{pf.name}}</td>
                <td>{{pf.updated | date:'medium'}}</td>
                <td>{{pf.sent | date:'medium'}}</td>
            </tr>
        </tbody>
        <tfoot>
            <ngx-file-drop style="display:inline-block; width:8000%; max-width:150ch" dropZoneLabel="Drop files here" (onFileDrop)="dropProjectDocument($event)" *ngIf="showUploadButton">

            </ngx-file-drop>
        </tfoot>

    </table>
    <button class="btn btn-primary" style="width:100px;" (click)="Send()" *ngIf="showUploadButton">
        Send Files
    </button>

</div>
