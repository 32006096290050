import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import {LoginService} from "./login.service";
import { ToastService } from "./toast.service";
import { Router } from '@angular/router';

@Injectable()
export class LogoutOn401Interceptor implements HttpInterceptor {

constructor(
    private readonly loginService: LoginService,
  private readonly toastService: ToastService,
  private readonly router: Router,
    ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = req.url;
    let response = next.handle(req);

    return response.pipe(catchError(e => {
      if (e instanceof HttpErrorResponse) {
        if (!e.url?.endsWith("api/v1/login")) {
          if (e.status === 401 || e.status === 403) {
            this.loginService.logout();
            this.toastService.add("Logged out", "You were logged out - please log in again", "warning");
            this.router.navigate(["/login"]);

          }
        }
      }
      throw e;
    }));
        //return response.pipe(map(evt => {
        //  if (evt instanceof HttpResponse) {
        //    if (evt.status == 401 || evt.status == 403) {
        //      this.toastService.add("Logged out", "You were logged out - please log in again", "warning");
        //      this.loginService.logout();
        //    }
        //  }
        //  else {
        //    console.log("response", url, evt);
        //  }
        //    return evt;
        //}))
    }

}
