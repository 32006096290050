import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ProjectMemberService } from '../../services/project-member.service';
import { ProjectMemberRole, UserRight, IProjectMember_ProjectMember } from '../../WebApiClient';
import { LoginService } from '../../services/login.service';
import { ComponentBase } from '../../models/ComponentBase';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Guid } from '../../models/Guid';

@Component({
    selector: 'app-project-member-selector',
    templateUrl: './project-member-selector.component.html',
    styleUrls: ['./project-member-selector.component.scss']
})
export class ProjectMemberSelectorComponent extends ComponentBase implements OnChanges {

    constructor(
        private readonly projectMemberService: ProjectMemberService,
        loginService: LoginService
    ) {
        super();
        this.showNewButton = loginService.hasRights(UserRight.EditProjectMembers);
        this.showDeleteButton = loginService.hasRights(UserRight.EditProjectMembers);
        let foremenSub = projectMemberService.projectMembers.subscribe(members => {
            this.members = members;
            this.membersById = {};
            if (members) {
                for (let foreman of members) {
                    this.membersById[foreman.id] = foreman;
                }
                this.refresh();
            }
        });
        this.subscriptions.push(foremenSub);
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.refresh();
    }


    @Input()
    memberId: string | undefined;

    @Output()
    memberIdChange = new EventEmitter<string | undefined>();

    @Input()
    memberRole: ProjectMemberRole = ProjectMemberRole.NotUsed;



    members: IProjectMember_ProjectMember[] | undefined;
    membersById: { [id: string]: IProjectMember_ProjectMember } = {};
    showNewButton = false;
    showDeleteButton = false;



    private _selectedMember: IProjectMember_ProjectMember | undefined;
    get selectedMember(): IProjectMember_ProjectMember | undefined {
        return this._selectedMember;
    }
    set selectedMember(val: IProjectMember_ProjectMember | undefined) {
        this._selectedMember = val;
        this.memberIdChange.emit(val?.id);
    }

    search = (text: Observable<string>) => {
        return text.pipe(
            distinctUntilChanged(),
            map(t => t.toLowerCase().split(/\s+/)),
            map(t => {
                let result = this.members
                    ? this.members.filter(f => this.memberFilter(t, f)).slice(0, 10)
                    : []
                return result;
            })
        );
    }

    formatter = (member: IProjectMember_ProjectMember) => member.name;

    async newMember() {
        let name = window.prompt("Name", this.memberRole);
        if (!name)
            return;
        let newMember: IProjectMember_ProjectMember = {
            id: Guid.newGuid(),
            name: name,
            role: this.memberRole,
        };
        await this.projectMemberService.upsert(newMember);
        this.selectedMember = newMember;
    }

    async deleteMember(member: IProjectMember_ProjectMember) {
        let isSure = window.confirm(`Are you sure you want to delete the ${this.memberRole} "${member.name}"?`);
        if (!isSure)
            return;
        let deletePromise = this.projectMemberService.delete(member.id);
        this.selectedMember = undefined;
        await deletePromise;
    }

    private refresh() {
        this._selectedMember = this.memberId
            ? this.membersById[this.memberId]
            : undefined;
    }

    private memberFilter(lcTexts: string[], foreman: IProjectMember_ProjectMember): boolean {
        if (foreman.role !== this.memberRole)
            return false;
        let lcName = foreman.name.toLowerCase();
        for (let lcText of lcTexts) {
            if (lcName.indexOf(lcText) < 0)
                return false;
        }
        return true;
    }

}
