<app-topbar></app-topbar>

<nav class="sidebar navbar-dark bg-dark">
    <div class="sidebar-header">
        <h5>New Project</h5>
    </div>
    <div style="height:30px"></div>

    <button class="btn btn-outline-primary btn-block"
            type="button"
            (click)="manageComponents()">
        Components
    </button>
    <button class="btn btn-outline-primary btn-block"
            type="button"
            (click)="manageEventTypes()">
        Event Types
    </button>
    <button class="btn btn-outline-primary btn-block required"
            type="button"
            (click)="manageMailingList()">
        Mail Recipients
    </button>
    <div style="height:30px"></div>
    <form (ngSubmit)="create()">
        <button class="btn btn-primary btn-block"
                type="submit"
                [disabled]="!inputValid">
            Create Project
        </button>
    </form>
    <button class="btn btn-primary btn-block" type="button" (click)="manageTemplateProjects()">
        Load Template
    </button>
    <form (ngSubmit)="createTemplate()">
        <button class="btn btn-primary btn-block" typeof="submit" [disabled]="!templateInputValid"> 
            Save as Template
        </button>
    </form>

</nav>


<div class="main-content">
    <app-edit-project-inner [project]="project"></app-edit-project-inner>

</div>
