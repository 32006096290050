import { Component } from '@angular/core';
import { ComponentBase } from '../../../models/ComponentBase';
import { ProjectService } from '../../../services/project.service';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Project_GetProjectDetailsResponse, CraneType, UserRight, TenderedType, ReceivedType, ProjectStatus } from '../../../WebApiClient';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { LoginService } from '../../../services/login.service';

@Component({
    selector: 'app-project-overview',
    templateUrl: './project-overview.component.html',
    styleUrls: ['./project-overview.component.scss', '../../../style/project-details.scss', '../../../style/sidebarLayout.scss']
})
export class ProjectOverviewComponent extends ComponentBase {
    project: Project_GetProjectDetailsResponse | undefined;
    readonly ProjectStatus = ProjectStatus;
    readonly TenderedType = TenderedType;
    readonly ReceivedType = ReceivedType;
    readonly CraneType = CraneType;
    readonly checkIcon = faCheckCircle;
    readonly notIcon = faTimesCircle;


    constructor(
        route: ActivatedRoute,
        private readonly loginService: LoginService,
        private readonly projectService: ProjectService
    ) {
        super();

        let projectSubscription = route.params.pipe(
            map(params => params["id"]),
            switchMap(id => projectService.getProjectDetails(id))
        ).subscribe(proj => this.project = proj);

        this.subscriptions.push(projectSubscription);
    }

    get shouldDisplayInternalNotes(): boolean {
        return this.loginService.hasRights(UserRight.ReadInternalNotes);
    }

}
