import { OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export class ComponentBase implements OnDestroy {

    protected readonly subscriptions: Subscription[] = [];

    ngOnDestroy() {
        for (let sub of this.subscriptions)
            sub.unsubscribe();
    }

    loadStatus(list?: any[]): "loading" | "empty" | "loaded" {
        if (!list)
            return "loading";
        if (list.length === 0)
            return "empty";
        return "loaded";
    }

    isLoading(list?: any[]): list is undefined { return !list; }
    isEmpty(list?: any[]): boolean { return list?.length === 0; }
    isLoaded(list?: any[]): boolean { return (list?.length ?? 0) > 0; }
}
