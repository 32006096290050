<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div *ngIf="isLoading(mailLogs)">Loading...</div>
<div *ngIf="!isLoading(mailLogs)" class="main-content">
    <h3>Mail Logs</h3>
    <table class="table table-striped">
        <colgroup>
            <col style="width:auto" />
            <col style="width:auto" />
            <col style="width:auto" />
            <col style="width:auto" />
            <col style="width:auto" />
        </colgroup>
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Mail Address</th>
                <th scope="col">Mail type</th>
                <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let MailLog of mailLogs">
                <td>{{MailLog.created | date:'medium'}}</td>
                <td>{{MailLog.email}}</td>
                <td>{{MailLog.mailType}}</td>
                <td>{{MailLog.mailStatus}}</td>
            </tr>
        </tbody>
    </table>

    <h5 *ngIf="isEmpty(mailLogs)">
        No mails logged for this project.
    </h5>
</div>
