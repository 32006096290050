<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">

    <div *ngIf="!project">Loading...</div>
    <div *ngIf="project" class="all">

        <div class="page-col">
            <div class="info-group main-info-group s-info-group">
                <h4>General</h4>
                <table>
                    <tr>
                        <td class="col-label">Project name</td>
                        <td class="col-value">{{project.name}}</td>
                    </tr>

                    <tr *ngFor="let cust of project.projectCustomers; first as isFirst ">
                        <td class="col-label">
                            <div *ngIf="isFirst">Customers</div>
                        </td>
                        <td class="col-value">
                            {{cust.name}}
                        </td>
                    </tr>

                    <tr>
                        <td class="col-label">Case number</td>
                        <td class="col-value">{{project.caseNumber}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Status</td>
                        <td class="col-value" *ngIf="project.projectStatus == ProjectStatus.Initial">Not Started</td>
                        <td class="col-value" *ngIf="project.projectStatus == ProjectStatus.Started">In Progress</td>
                        <td class="col-value" *ngIf="project.projectStatus == ProjectStatus.Completed">Completed</td>
                    </tr>

                </table>
            </div>

            <div class="info-group  s-info-group"
                 *ngIf="project.shipper">
                <h4>Shipper</h4>
                <table>
                    <tr>
                        <td class="col-label">Name</td>
                        <td class="col-value">{{project.shipper.name}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Address</td>
                        <td class="col-value">{{project.shipper.address}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Postal code</td>
                        <td class="col-value">{{project.shipper.postalCode}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">City</td>
                        <td class="col-value">{{project.shipper.city}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Country</td>
                        <td class="col-value">{{project.shipper.country}}</td>
                    </tr>
                </table>
            </div>

            <div class="info-group s-info-group"
                 *ngIf="project.consignee">
                <h4>Consignee</h4>
                <table>
                    <tr>
                        <td class="col-label">Name</td>
                        <td class="col-value">{{project.consignee.name}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Address</td>
                        <td class="col-value">{{project.consignee.address}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Postal code</td>
                        <td class="col-value">{{project.consignee.postalCode}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">City</td>
                        <td class="col-value">{{project.consignee.city}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Country</td>
                        <td class="col-value">{{project.consignee.country}}</td>
                    </tr>
                </table>
            </div>

            <div class="info-group s-info-group"
                 *ngIf="project.notifyAddress">
                <h4>Notify Address</h4>
                <table>
                    <tr>
                        <td class="col-label">Name</td>
                        <td class="col-value">{{project.notifyAddress.name}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Address</td>
                        <td class="col-value">{{project.notifyAddress.address}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Postal code</td>
                        <td class="col-value">{{project.notifyAddress.postalCode}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">City</td>
                        <td class="col-value">{{project.notifyAddress.city}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Country</td>
                        <td class="col-value">{{project.notifyAddress.country}}</td>
                    </tr>
                </table>
            </div>



            <div class="info-group s-info-group" *ngIf="shouldDisplayInternalNotes">
                <h4>Internal Note</h4>
                <table>
                    <tr>
                        <td class="col-value multiline-value">{{project.internalNote}}</td>
                    </tr>
                </table>
            </div>
        </div>


        <div class="page-col">

            <div class="info-group l-info-group">
                <h4>Vessel</h4>
                <table>
                    <tr>
                        <td class="col-label">Vessel name</td>
                        <td class="col-value">{{project.shipName}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Voyage number</td>
                        <td class="col-value">{{project.voyageNumber}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">IMO Number</td>
                        <td class="col-value">{{project.imoNumber}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Flag</td>
                        <td class="col-value">{{project.flag}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Owners</td>
                        <td class="col-value">{{project.owners}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Port</td>
                        <td class="col-value">{{project.port}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Reference</td>
                        <td class="col-value">{{project.reference}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Coming from</td>
                        <td class="col-value">{{project.from}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Loading/discharging in</td>
                        <td class="col-value">{{project.loadingIn}} {{project.dischargeIn}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Going to</td>
                        <td class="col-value">{{project.to}}</td>
                    </tr>
                    <tr>
                        <td class="col-label"> Has a tug been booked?</td>
                        <td class="col-value" *ngIf="project.isTugBooked"> Yes </td>
                        <td class="col-value" *ngIf="!project.isTugBooked"> No </td>
                    </tr>
                    <tr *ngIf="project.isTugBooked">
                        <td class="col-label">Tug(s) booked</td>
                        <td class="col-value">{{project.tugBooked | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label"> Has a pilot been booked?</td>
                        <td class="col-value" *ngIf="project.isPilotBooked"> Yes</td>
                        <td class="col-value" *ngIf="!project.isPilotBooked"> No</td>
                    </tr>
                    <tr *ngIf="project.isPilotBooked">
                        <td class="col-label">Pilot booked</td>
                        <td class="col-value">{{project.pilotBooked | tbaDate }}</td>
                        <td class="checkbox-row"></td>
                    </tr>
                    <tr>
                        <td class="col-label">Expected arrival</td>
                        <td class="col-value">{{project.expectedArrival | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Expected departure</td>
                        <td class="col-value">{{project.expectedDeparture | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Actual departure</td>
                        <td class="col-value">{{project.actualDeparture | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Expected arrival next port</td>
                        <td class="col-value">{{project.expectedArrivalNextPort | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Loading/discharge berth</td>
                        <td class="col-value">{{project.berth}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Eosp/pilot station</td>
                        <td class="col-value">{{project.esop | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">First line</td>
                        <td class="col-value">{{project.firstLine | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">All fast</td>
                        <td class="col-value">{{project.allFast | tbaDate }}</td>
                    </tr>                  
                    <tr>
                        <td class="col-label">
                            Nor Tendered
                        </td>
                        <td class="col-value">
                            <table>
                                <tr class="checkbox-row ">
                                   <td *ngIf="project.tenderedType === TenderedType.Unknown">Unknown </td>

                                </tr>
                                <tr class="checkbox-row ">
                                    <td *ngIf="project.tenderedType === TenderedType.None"> None</td>

                                </tr>
                                <tr class="checkbox-row ">
                                    <td *ngIf="project.tenderedType === TenderedType.Date">{{project.norTendered | tbaDate}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td class="col-label">
                            Nor Received
                        </td>
                        <td class="col-value">
                            <table>
                                <tr class="checkbox-row ">
                                    <td *ngIf="project.receivedType === ReceivedType.Unknown">Unknown </td>

                                </tr>
                                <tr class="checkbox-row ">
                                    <td *ngIf="project.receivedType === ReceivedType.None"> None</td>

                                </tr>
                                <tr class="checkbox-row ">
                                    <td *ngIf="project.receivedType === ReceivedType.Date">{{project.norReceived | tbaDate}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td class="col-label">Operation expected to commence</td>
                        <td class="col-value">{{project.opsCommenced | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Operation commenced</td>
                        <td class="col-value">{{project.operationsActuallyCommenced | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Operation expected to complete</td>
                        <td class="col-value">{{project.opsCompleted | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Operation completed</td>
                        <td class="col-value">{{project.operationsActuallyCompleted | tbaDate }}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Important Information</td>
                        <td class="col-value multiline-value">{{project.importantInformation}}</td>
                    </tr>
                </table>
            </div>


        </div>

        <div class="page-col">
            <div class="info-group m-info-group">
                <h4>Cargo</h4>
                <table>
                    <tr>
                        <td class="col-label">Cargo description</td>
                        <td class="col-value">{{project.cargoDescription}}</td>
                    </tr>
                    <tr>
                        <td class="col-label">Quantity</td>
                        <td class="col-value">{{project.quantity}} pcs</td>
                    </tr>
                    <tr>
                        <td class="col-label">Total weight</td>
                        <td class="col-value">{{project.weight}} kg</td>
                    </tr>

                </table>
            </div>

            <div class="info-group m-info-group">
                <h4>Project roles</h4>
                <table>
                    <tr>
                        <td class="col-label">Customer rep</td>
                        <td class="col-value">{{project.customerRep}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Stevedore foreman</td>
                        <td class="col-value">{{project.stevedoreForeman}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Agent (Opc)</td>
                        <td class="col-value">{{project.opcAgent}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Surveyor</td>
                        <td class="col-value">{{project.surveyor}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Lifting supervisor</td>
                        <td class="col-value">{{project.liftingSupervisor}}</td>
                    </tr>
                </table>
            </div>


            <div class="info-group m-info-group">
                <h4>Cranes</h4>
                <table>
                    <tr class="checkbox-row">
                        <td class="col-label">None</td>
                        <td class="col-value">
                            <fa-icon style="color:green" *ngIf="project.craneType === CraneType.None" [icon]="checkIcon"></fa-icon>
                        </td>
                    </tr>
                    <tr class="checkbox-row">
                        <td class="col-label">Vessel Gear</td>
                        <td class="col-value">
                            <fa-icon style="color:green" *ngIf="project.craneType === CraneType.VesselGear" [icon]="checkIcon"></fa-icon>
                        </td>
                    </tr>
                    <tr class="checkbox-row">
                        <td class="col-label">Port Crane(s)</td>
                        <td class="col-value">
                            <fa-icon style="color:green" *ngIf="project.craneType === CraneType.PortCrane" [icon]="checkIcon"></fa-icon>
                        </td>
                    </tr>
                    <tr class="checkbox-row">
                        <td class="col-label">Mobile Crane(s)</td>
                        <td class="col-value">
                            <fa-icon style="color:green" *ngIf="project.craneType === CraneType.MobileCrane" [icon]="checkIcon"></fa-icon>
                        </td>
                    </tr>
                    <tr class="checkbox-row">
                        <td class="col-label">Ro/Ro</td>
                        <td class="col-value">
                            <fa-icon style="color:green" *ngIf="project.craneType === CraneType.RoRo" [icon]="checkIcon"></fa-icon>
                        </td>
                    </tr>
                    <tr class="empty-row">
                        <td class="col-label"></td>
                        <td class="col-value"></td>
                    </tr>
                    <tr class="checkbox-row">
                        <td class="col-label">Crane(s) booked*</td>
                        <td class="col-value">
                            <fa-icon style="color:green" *ngIf="project.shoreCraneBooked" [icon]="checkIcon"></fa-icon>
                            <fa-icon style="color:red" *ngIf="!project.shoreCraneBooked" [icon]="notIcon"></fa-icon>
                        </td>
                    </tr>
                    <tr class="notice-row">
                        <td class="col-label" colspan="2">
                            *Shore crane(s) booked not guranteed. Pre-booked according to port regulations and availability.
                        </td>
                    </tr>
                </table>
            </div>

            <div class="info-group m-info-group">
                <h4>Kajmand bestilling</h4>
                <table>
                    <tr>
                        <td class="col-label">Dato</td>
                        <td class="col-value">{{project.informantOrderDate | tbaDate}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Skib</td>
                        <td class="col-value multiline-value">{{project.informantOrderShip}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Kaj</td>
                        <td class="col-value multiline-value">{{project.informantOrderPort}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Sag</td>
                        <td class="col-value multiline-value">{{project.informantOrderCase}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Job</td>
                        <td class="col-value multiline-value">{{project.informantOrderJob}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Maskine</td>
                        <td class="col-value multiline-value">{{project.informantOrderMachine}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Klar v. skib</td>
                        <td class="col-value multiline-value">{{project.informantOrderReady}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Notat</td>
                        <td class="col-value multiline-value">{{project.informantOrderNote}}</td>
                    </tr>
                </table>
            </div>

            <div class="info-group m-info-group">
                <h4>Terminal opgørelse</h4>
                <table>
                    <tr>
                        <td class="col-label">Dato</td>
                        <td class="col-value">{{project.terminalStatementDate | tbaDate}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Løn</td>
                        <td class="col-value multiline-value">{{project.terminalStatementSalery}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Forbrug v. flere maskiner</td>
                        <td class="col-value multiline-value">{{project.terminalStatementMultipleMachines}}</td>
                    </tr>

                    <tr>
                        <td class="col-label">Notat</td>
                        <td class="col-value multiline-value">{{project.terminalStatementNote}}</td>
                    </tr>
                </table>
            </div>
        </div>



    </div>

</div>
