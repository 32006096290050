<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">
    <h3>Project Report and Statistics</h3>
    <div style="height:45px"></div>
    <div id="hourglass" *ngIf="isDownloading()">
        <fa-icon style="padding-left:20px" class="fa-6x" [icon]="faHourglass" [spin]="true"></fa-icon>
        <p>Preparing the report for download, this might take a couple of seconds</p>
    </div>
    <button class="btn btn-primary" (click)="downloadShipReport()" [disabled]="isDownloading()">Download vessel report</button>
</div>
