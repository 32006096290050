
<table class="form-table">
    <tbody>
        <tr>
            <td><label for="name">Full name</label></td>
            <td><input class="form-control" type="text" [(ngModel)]="user.name" placeholder="Full Name" name="name" id="name" title="Full Name" /></td>
        </tr>
        <tr>
            <td><label for="email">Email</label></td>
            <td><input class="form-control" type="email" [(ngModel)]="user.email" placeholder="Email" name="email" id="email" title="Email" /></td>
        </tr>
        <tr>
            <td><label for="username">Username</label></td>
            <td>
                <input class="form-control"
                       type="text"
                       [(ngModel)]="user.username"
                       placeholder="Username"
                       name="username"
                       autocomplete="off"
                       id="username"
                       title="Username" />
            </td>
        </tr>
        <tr>
            <td><label for="password">Password</label></td>
            <td>
                <input class="form-control"
                       type="password"
                       [(ngModel)]="user.password"
                       placeholder="New password"
                       name="password"
                       id="password"
                       autocomplete="new-password"
                       title="Type a password here to change the password of the user. Leave this field blank to leave password unchanged" />
            </td>
        </tr>
        <tr>
            <td>
                <label for="userrole">User role</label>
            </td>
            <td>
                <select class="form-control"
                        [(ngModel)]="userRole"
                        name="userrole"
                        id="userrole"
                        title="User Role">
                    <option value="" disabled selected>User Type</option>
                    <option *ngFor="let role of roles" [value]="role.key">{{role.displayName}}</option>
                </select>
            </td>
        </tr>
        <tr>
            <td>
                <label for="customer">Customer</label>
            </td>
            <td>
                <select class="form-control"
                        [(ngModel)]="user.customerId"
                        name="customer"
                        id="customer"
                        title="Customer"
                        [disabled]="!isCustomerSelectionValid">
                    <option value="" disabled selected>Select Customer</option>
                    <option *ngFor="let customer of customers" [value]="customer.id">{{customer.name}}</option>
                </select>
            </td>
        </tr>
    </tbody>
</table>



<div class="form-group">


</div>

<div class="form-group">


</div>
