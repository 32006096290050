import { Component } from '@angular/core';
import { ComponentBase } from '../../../models/ComponentBase';
import { MailingListClient } from '../../../WebApiClient';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { ProjectService } from '../../../services/project.service';
import { of } from 'rxjs';

@Component({
    selector: 'app-mail-recipient',
    templateUrl: './mail-recipient.component.html',
    styleUrls: ['./mail-recipient.component.scss', '../../../style/sidebarLayout.scss']
})
export class MailRecipientComponent extends ComponentBase {

    public mailReceivers: {
        name: string | undefined,
        email: string,
        isActive: boolean,
        isMainActor: boolean | undefined,
    }[] | undefined;

    constructor(
        mlClient: MailingListClient,
        projectService: ProjectService,
        route: ActivatedRoute
    ) {
        super();

        const mailingListObservable = route.params.pipe(
            map(p => p["id"] as string | undefined),
            switchMap(projectId => projectId
                ? projectService.getProjectDetails(projectId)
                : of(undefined)),
            switchMap(projectDetails => projectDetails?.mailingListId
                ? mlClient.getMailingList(projectDetails.mailingListId)
                : of(undefined)),
        );

        const sub = mailingListObservable.subscribe(ml => {
            this.mailReceivers = ml?.mailReceivers.map(receiver => ({
                email: receiver.emailAddress,
                name: receiver.name,
                isActive: receiver.isActive ?? true,
                isMainActor: receiver.isMainActor,
            }));
        });
        this.subscriptions.push(sub);
    }

    get mainActors() {
        return this.mailReceivers?.filter(mr => mr.isMainActor == true);
    }

    get otherReceivers() {
        return this.mailReceivers?.filter(mr => mr.isMainActor == false);
    }
}
