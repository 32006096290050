import { Component } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TypedModalRef } from '../../models/TypedModalRef';
import { ProjectService } from '../../services/project.service';
import { ComponentBase } from '../../models/ComponentBase';
import { ProjectComment_GetProjectCommentsResponse_ProjectComment, Project_GetProjectDetailsResponse, IProject_GetProjectDetailsResponse_Event, ProjectComment_GetProjectCommentsResponse_CommentAttachment } from '../../WebApiClient';
import { Enumerable } from '../../../tools/linq/linq';
import { LightboxModalComponent } from '../lightbox-modal/lightbox-modal.component';
import { FileService } from '../../services/file.service';

@Component({
    selector: 'app-comments-modal',
    templateUrl: './comments-modal.component.html',
    styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent extends ComponentBase {

    public comments: ProjectComment_GetProjectCommentsResponse_ProjectComment[] = [];
    public isUpdating = true;
    public title = "";
    public event: IProject_GetProjectDetailsResponse_Event | undefined;
    private project: Project_GetProjectDetailsResponse | undefined;

    constructor(
        private readonly activeModal: NgbActiveModal,
        private readonly projectService: ProjectService,
        private readonly fileService: FileService,
        private readonly modalService: NgbModal
    ) {
        super();
    }

    private init(project: Project_GetProjectDetailsResponse, event: IProject_GetProjectDetailsResponse_Event) {
        this.event = event;
        this.project = project;
        const sub = this.projectService.getComments(project.id)
            .subscribe(comments => this.updateComments(comments))
        this.subscriptions.push(sub);

       
        this.title = this.getTitle(project, event);
    }
    getTitle(project: Project_GetProjectDetailsResponse, event: IProject_GetProjectDetailsResponse_Event): string {
        let startTimeFormat = new Intl.DateTimeFormat(["pt-BR"], {
            timeZone: "Europe/Copenhagen",
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        });
        let endTimeFormat = new Intl.DateTimeFormat(["pt-BR"], {
            timeZone: "Europe/Copenhagen",
            hour: "2-digit",
            minute: "2-digit",
        })
        let startTime = event.startTime
            ? startTimeFormat.format(event.startTime)
            : null;
        let endTime = event.endTime
            ? endTimeFormat.format(event.endTime)
            : null;

        let titleParts = [
            "Comments",
            project.name,
            event.name,
            event.componentName,
            startTime,
            endTime
        ];
        return titleParts.filter(t => !!t).join(" - ");

    }


    private updateComments(comments: ProjectComment_GetProjectCommentsResponse_ProjectComment[] | undefined) {
        if (!comments || !this.project || !this.event) {
            this.isUpdating = true;
            this.comments = [];
            return;
        }

        let eventId = this.event.id;
        this.isUpdating = false;
        this.comments = Enumerable.from(comments)
            .where(c => c.eventId === eventId)
            .orderBy(c => c.timestamp)
            .toArray();

    }
    async show(attachment: ProjectComment_GetProjectCommentsResponse_CommentAttachment) {
        let image: LightboxModalComponent.Image = {
            url: this.fileService.getUrl(attachment.fileId),
            text: attachment.name
        };
        await LightboxModalComponent.open(this.modalService, image);
    }


    public close() {
        this.activeModal.dismiss();
    }

    static open(project: Project_GetProjectDetailsResponse, event: IProject_GetProjectDetailsResponse_Event, modalService: NgbModal): TypedModalRef<CommentsModalComponent, void> {
        let modal = modalService.open(CommentsModalComponent, { size: 'lg', windowClass: 'full-height-modal', scrollable: true, });
        let modalComponent = <CommentsModalComponent>modal.componentInstance;
        modalComponent.init(project, event);
        return new TypedModalRef(modal);

    }
}
