import { Component } from '@angular/core';
import { ProjectReportService } from '../../../services/project-report.service';
import { ProjectReport_GetProjectTemplatesResponse_ProjectTemplate } from '../../../WebApiClient';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from '../../../models/ComponentBase';
import { map } from 'rxjs/operators';
import { ProjectFileService } from '../../../services/project-file.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss', '../../../style/sidebarLayout.scss']
})
export class ReportsComponent extends ComponentBase {

    private readonly currentTemplateDownloads: string[] = [];
    private projectId: string | undefined;

    templates: ProjectReport_GetProjectTemplatesResponse_ProjectTemplate[] | undefined;


    constructor(
        private readonly reportService: ProjectReportService,
        private readonly projectFileService: ProjectFileService,
        route: ActivatedRoute
    ) {
        super();
        const templateSub = reportService.templates.subscribe(ts => {
            this.templates = ts;
        })
        this.subscriptions.push(templateSub);

        const projectIdObservable = route.params.pipe(map(p => p["id"]));

        const routeSub = projectIdObservable.subscribe(projectId => {
            this.projectId = projectId;
        })
        this.subscriptions.push(routeSub);
    }

    isDownloadingTemplate(templateId: string) {
        return this.currentTemplateDownloads.indexOf(templateId) >= 0;
    }

    async downloadTemplate(templateId: string) {
        if (!this.projectId)
            throw new Error("Could not download report - missing projectId");
        if (this.isDownloadingTemplate(templateId)) {
            //already downloading, ignore
            return;
        }
        this.currentTemplateDownloads.push(templateId);


        try {
            await this.reportService.download(templateId, this.projectId)
        }
        finally {
            let i = this.currentTemplateDownloads.indexOf(templateId);
            if (i >= 0) {
                this.currentTemplateDownloads.splice(i, 1);
            }
        }
    }


}
