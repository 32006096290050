import { Component } from '@angular/core';
import { TrainingVideoService } from '../../services/trainingVideo.service';
import { ComponentBase } from '../../models/ComponentBase';
import { VideoGroup } from '../../models/VideoGroup';

@Component({
    selector: 'app-training',
    templateUrl: './training.component.html',
    styleUrls: ['./training.component.scss', '../../style/sidebarLayout.scss']
})
export class TrainingComponent extends ComponentBase {

    public videoGroups: readonly VideoGroup[] = [];
    constructor(
        trainingVideoService: TrainingVideoService
    ) {
        super();
        let sub = trainingVideoService.visibleVideoGroups.subscribe(vgs => this.videoGroups = vgs);
        this.subscriptions.push(sub);
    }


}
