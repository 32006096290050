<app-topbar></app-topbar>
<app-sidebar></app-sidebar>

<div class="main-content">

    <div class="alert alert-secondary">
        Creating a link allows you to share read-only access to a project without giving away your login info.
    </div>

    <div style="width:650px">
        <div *ngIf="status === 'initial'">
            <button class="btn btn-primary"
                    type="button"
                    style="width:100%;"
                    (click)="generate()">
                Generate link
            </button>
        </div>
        <div *ngIf="status === 'generating'">
            <div class="alert alert-secondary">Generating link</div>
        </div>
        <div *ngIf="status === 'generated'">
            <div class="input-group">
                <input [(ngModel)]="linkUrl"
                       #linkInput
                       class="form-control" />
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="copyToClipboard()">
                        <fa-icon [icon]="clipboardIcon"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
