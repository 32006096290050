<form (ngSubmit)="create()" autocomplete="off">

    <div class="card">
        <div class="card-header">

            <h3>Create User</h3>
        </div>
        <div class="card-body">
            <app-edit-user-inner [user]="user"></app-edit-user-inner>

        </div>
        <div class="card-footer">
            <button class="btn btn-block btn-primary"
                    [disabled]="!inputValid">
                Create User
            </button>

        </div>
    </div>

</form>
