import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, merge, of } from 'rxjs';
import { EventType_GetEventTypesResponse_EventType as EventType, EventTypeClient, EventType_UpsertEventTypeRequest, ProjectEventTypeClient, ProjectEventType_SetProjectEventTypesRequest } from '../WebApiClient';
import { switchMap, map, publishReplay, refCount } from 'rxjs/operators';
import { Guid } from '../models/Guid';

@Injectable()
export class EventTypeService {

    private readonly eventTypeTrigger = new BehaviorSubject<boolean>(true);

    readonly eventTypes: Observable<{ eventTypes: EventType[], loading: boolean }>;

    constructor(
        private readonly eventTypeClient: EventTypeClient,
        private readonly projectEventTypeClient: ProjectEventTypeClient,
    ) {

        let oldEventTypes: EventType[] = [];
        this.eventTypes = this.eventTypeTrigger.pipe(
            switchMap(shouldResolve => shouldResolve ? merge(
                of<undefined>(undefined),
                eventTypeClient.getEventTypes()
            ) : of<undefined>(undefined)),
            map(response => {
                if (response) {
                    oldEventTypes = response.eventTypes;
                }
                return { eventTypes: oldEventTypes, loading: !response };
            }),
            publishReplay(2),
            refCount(),
        );
    }


    async addEventType(name: string): Promise<void> {
        try {
            this.eventTypeTrigger.next(false);
            await this.eventTypeClient.upsertEventType(new EventType_UpsertEventTypeRequest({
                id: Guid.newGuid(),
                name: name
            })).toPromise();

        }
        finally {
            this.eventTypeTrigger.next(true);
        }

    }


    async getProjectEventTypeIds(projectId: string): Promise<string[]> {
        let response = await this.projectEventTypeClient.getProjectEventTypes(projectId).toPromise();
        return response.projectEventTypes.map(pet => pet.id);
    }

    setProjectEventTypeIds(projectId: string, eventTypeIds: string[]): Promise<void> {
        return this.projectEventTypeClient.setProjectEventTypes(new ProjectEventType_SetProjectEventTypesRequest({
            projectId: projectId,
            eventTypeIds: eventTypeIds
        })).toPromise();
    }

    async deleteEventType(eventTypeId: string) {
        try {
            this.eventTypeTrigger.next(false);
            await this.eventTypeClient.deleteEventType(eventTypeId).toPromise();
        }
        finally {
            this.eventTypeTrigger.next(true);
        }
    }

}
