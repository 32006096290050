export class ProjectSearchOptions {

    readonly searchTerm?: string;
    readonly customerId?: string;
    readonly after?: Date;
    readonly before?: Date;
    readonly descending: boolean;
    readonly page: number;

    constructor(o: {
        after?: Date,
        before?: Date,
        customerId?: string,
        searchTerm?: string,
        descending?: boolean,
        page?: number,
    } = {}) {
        this.customerId = o.customerId;
        this.searchTerm = o.searchTerm;
        this.after = o.after ? new Date(o.after.valueOf()) : undefined;
        this.before = o.before ? new Date(o.before.valueOf()) : undefined;
        this.descending = o.descending ?? true;
        this.page = o.page ?? 1;
    }
}
