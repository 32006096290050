import { LoginService } from './login.service';
import { Observable } from 'rxjs';
import { VideoGroup } from '../models/VideoGroup';
import { map } from 'rxjs/operators';
import { UserRight } from '../WebApiClient';
import { YoutubeVideo } from '../models/YoutubeVideo';
import { Injectable } from '@angular/core';

@Injectable()
export class TrainingVideoService {
    public readonly AllVideoGroups: VideoGroup[] = [
        new VideoGroup("Training Guides",
            [
                new YoutubeVideo("Video Guide", "FrwlP6qD7DU", UserRight.WatchCustomerVideos),
            ]
        ),
        new VideoGroup("In-depth Training Videos - Web",
            [
                new YoutubeVideo("Lighthouse 1 – Project Info", "tnEZdMzqOQs", UserRight.WatchWebManagerVideos),
                new YoutubeVideo("Lighthouse 2 – Extended Info", "nhk6vT94uzs", UserRight.WatchWebManagerVideos),
                new YoutubeVideo("Lighthouse 3 – Addresses", "Q23k8TQZKJ8", UserRight.WatchWebManagerVideos),
                new YoutubeVideo("Lighthouse 4 – Project Roles", "VigVBRAvuWQ", UserRight.WatchWebManagerVideos),
                new YoutubeVideo("Lighthouse 5 – Edit Component", "DBCojymvpaE", UserRight.WatchWebManagerVideos),
                new YoutubeVideo("Lighthouse 6 – Edit Event Types", "ITVc7COahuQ", UserRight.WatchWebManagerVideos),
                new YoutubeVideo("Lighthouse 7 – Edit Mail Recipients", "qUFVvioK9og", UserRight.WatchWebManagerVideos),
                new YoutubeVideo("Lighthouse 8 – Create User", "31ju37JA3KI", UserRight.WatchWebManagerVideos),
            ]
        )
    ]
    constructor(loginService: LoginService) {

        this.visibleVideoGroups = loginService.currentUserObservable.pipe(
            map(_ => this.AllVideoGroups
                .map(vg => new VideoGroup(vg.name, vg.videos.filter(v => loginService.hasRights(v.requiredRight))))
                .filter(vg => vg.videos.length > 0)
            )
        )
    }

    public readonly visibleVideoGroups: Observable<readonly VideoGroup[]>;
}
