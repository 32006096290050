import { Component } from '@angular/core';
import { Project_ProjectOverview, UserRight, ProjectStatus, Project_GetProjectListResponse } from '../../WebApiClient';
import { ProjectService } from '../../services/project.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoginService } from '../../services/login.service';
import { debounceTime } from 'rxjs/operators';
import { ComponentBase } from '../../models/ComponentBase';
import { ProjectSearchOptions } from '../../models/ProjectSearchOptions';
import { TechnicalHelpModalComponent } from '../../components/technical-help-modal/technical-help-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-completed-projects',
    templateUrl: './completed-projects.component.html',
    styleUrls: ['./completed-projects.component.scss', '../../style/sidebarLayout.scss']
})
export class CompletedProjectsComponent extends ComponentBase {

    private static readonly relativeDayKey = "projects_relativeDaySelection";


    private readonly searchOptionsSubject: BehaviorSubject<ProjectSearchOptions>;

    public selectedProject: Project_ProjectOverview | undefined = undefined;
    public newProjectActive: boolean = false;
    public showNewProjectButton = false;
    readonly ProjectStatus = ProjectStatus;
    readonly showReportAndStatistics: boolean;
    public relativeDaySelections: {
        days: number | null,
        text: string
    }[] = [
            { days: 0, text: "Future" },
            { days: 7, text: "Last 7 days" },
            { days: 14, text: "Last 14 days" },
            { days: 30, text: "Last month" },
            { days: 90, text: "Last 3 months" },
            { days: 365, text: "Last year" },
            { days: null, text: "All" },
        ];

    private response: Project_GetProjectListResponse | undefined = undefined;
    private page = 1;

    public constructor(
        projectService: ProjectService,
        private readonly loginService: LoginService,
        private readonly modalService: NgbModal,
    ) {
        super();
        this.searchOptionsSubject = new BehaviorSubject(this.getSearchOptions());
        this.initRelativeDay();
        let subscription = projectService.getProjectsObservable(this.searchOptionsSubject.pipe(debounceTime(200)))
            .subscribe(response => {
                this.response = response;
            });
        this.subscriptions.push(subscription);

        this.showEditButtons = loginService.hasRights(UserRight.EditProject);
        this.showNewProjectButton = loginService.hasRights(UserRight.EditProject);
        this.showReportAndStatistics = loginService.hasRights(UserRight.ListAllProjects);
    }

    private _relativeDay: number | null = 7; //TODO
    public get relativeDay() { return this._relativeDay; }
    public set relativeDay(val: number | null) {
        this._relativeDay = val;
        this.triggerSearch();
        localStorage.setItem(CompletedProjectsComponent.relativeDayKey, (typeof val === "number") ? val.toString() : "null");
    }

    private _searchTerm: string = "";
    public get searchTerm() { return this._searchTerm; }
    public set searchTerm(val: string) {
        this._searchTerm = val;
        this.triggerSearch();
    }

    public get projects() { return this.response?.projects; }

    public get completedProjects() {
        let completedProjects: Project_ProjectOverview[] | undefined;
        let returnProjects: Project_ProjectOverview[] = [];
        completedProjects = this.response?.projects;
        if (completedProjects != undefined) {
            for (let i = 0; i < completedProjects?.length; i++) {
                if (completedProjects[i].isProjectTemplate != true && completedProjects[i].projectStatus == ProjectStatus.Completed) {
                    returnProjects.push(completedProjects[i]);
                }
            }
        }
        return returnProjects;
    }

    private triggerSearch() {
        this.searchOptionsSubject.next(this.getSearchOptions());
    }

    public search() {
        this.triggerSearch();
    }

    public get noProjects(): boolean {
        return !!this.projects && this.projects.length === 0;
    }

    public showEditButtons: boolean;

    private initRelativeDay() {
        let relativeDayStr = localStorage.getItem(CompletedProjectsComponent.relativeDayKey);
        let isSet = false;
        if (relativeDayStr) {
            if (relativeDayStr === "null") {
                this.relativeDay = null;
                isSet = true;
            }
            else {
                try {
                    let relday = parseInt(relativeDayStr);
                    this.relativeDay = relday;
                    isSet = true;
                }
                catch (e) {
                    /* do nothing */
                }
            }
        }
        if (!isSet) {
            this.relativeDay = 7;
        }
    }

    public startNewProject() {
        this.selectedProject = undefined;
        this.newProjectActive = true;
    }

    public selectProject(project: Project_ProjectOverview) {
        this.newProjectActive = false;
        this.selectedProject = project;
    }

    public statusName(project: Project_ProjectOverview) {
        switch (project.projectStatus) {
            case ProjectStatus.Initial:
                return "Pending";
            case ProjectStatus.Started:
                return "Loading";
            case ProjectStatus.Completed:
                return "Completed";
            default:
        }
    }

    private getSearchOptions(): ProjectSearchOptions {

        if (typeof this.relativeDay === "number") {
            let d = new Date();
            d.setDate(d.getDate() - this.relativeDay)
            return new ProjectSearchOptions({
                searchTerm: this.searchTerm,
                after: d,
                page: this.page,
                descending: true,
            });
        }
        else {
            return new ProjectSearchOptions({
                searchTerm: this.searchTerm,
                page: this.page,
                descending: true,
            })
        }
    }

    public get isNextPagePossible(): boolean {
        return this.response?.isFullPage ?? false;
    }
    public nextPage() {
        if (!this.isNextPagePossible)
            return;
        this.selectedProject = undefined;
        this.page++;
        this.triggerSearch();
    }

    public get isPreviousPagePossible() {
        return this.page > 1;
    }
    public previousPage() {
        if (!this.isPreviousPagePossible)
            return;
        this.selectedProject = undefined;
        this.page--;
        this.triggerSearch();
    }

    public async openSupport() {
        let modal = TechnicalHelpModalComponent.open(this.modalService);

        try {

        }
        catch (e) {
            //user cancelled
        }
    }

}
